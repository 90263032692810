var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posters-editTwo" },
    [
      _c("header", [_c("edit-head", { attrs: { model: _vm.saveInfo } })], 1),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "menus" }, [
            _c(
              "ul",
              [
                _vm._l(_vm.sidebarMenus, function(item, index) {
                  return [
                    !_vm.admin || (index != 0 && index != 4)
                      ? _c(
                          "li",
                          {
                            key: index,
                            staticClass: "menus-item",
                            class: { active: item.name === _vm.activeSideBar },
                            on: {
                              click: function($event) {
                                return _vm.menusChange(item.name)
                              }
                            }
                          },
                          [
                            index == 0
                              ? _c("i", {
                                  staticClass:
                                    "iconfont icon-mobanguanli icon-size"
                                })
                              : _vm._e(),
                            index == 1
                              ? _c("i", {
                                  staticClass: "iconfont icon-wenben icon-size"
                                })
                              : _vm._e(),
                            index == 2
                              ? _c("i", {
                                  staticClass: "iconfont icon-tupian icon-size"
                                })
                              : _vm._e(),
                            index == 3
                              ? _c("i", {
                                  staticClass: "iconfont icon-beijing icon-size"
                                })
                              : _vm._e(),
                            index == 4
                              ? _c("i", {
                                  staticClass: "iconfont icon-erweima icon-size"
                                })
                              : _vm._e(),
                            _c("p", [_vm._v(_vm._s(item.label))])
                          ]
                        )
                      : _vm._e()
                  ]
                }),
                _c(
                  "li",
                  {
                    staticClass: "menus-item shortcut-key",
                    attrs: { slot: "reference" },
                    on: {
                      click: function($event) {
                        _vm.showShortcutkeyList = true
                      }
                    },
                    slot: "reference"
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-jianpan icon-size" }),
                    _c("p", [_vm._v("快捷键")])
                  ]
                ),
                _vm.showShortcutkeyList
                  ? _c("ShortcutkeyList", {
                      on: {
                        close: function($event) {
                          _vm.showShortcutkeyList = false
                        }
                      }
                    })
                  : _vm._e()
              ],
              2
            )
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEditItem,
                  expression: "showEditItem"
                }
              ],
              staticClass: "editor-show"
            },
            [
              _c(
                "div",
                {
                  staticClass: "hide-icon",
                  on: {
                    click: function($event) {
                      _vm.showEditItem = false
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-caret-left" })]
              ),
              !_vm.admin
                ? _c("templateList", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSideBar === "templateList",
                        expression: "activeSideBar === 'templateList'"
                      }
                    ],
                    ref: "templateList"
                  })
                : _vm._e(),
              _c("textList", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeSideBar === "textList",
                    expression: "activeSideBar === 'textList'"
                  }
                ],
                on: { pushText: _vm.pushText }
              }),
              _c("imagesList", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeSideBar === "imagesList",
                    expression: "activeSideBar === 'imagesList'"
                  }
                ],
                on: { pushImage: _vm.pushImage }
              }),
              _c("backgroundList", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeSideBar === "backgroundList",
                    expression: "activeSideBar === 'backgroundList'"
                  }
                ]
              }),
              !_vm.admin
                ? _c("codeList", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSideBar === "codeList",
                        expression: "activeSideBar === 'codeList'"
                      }
                    ],
                    on: { addImage: _vm.addImage }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "editorContent",
              staticClass: "editor-content",
              style: {
                left: _vm.editPosition.left + "px"
              }
            },
            [
              _c("div", { staticClass: "InputNumber" }, [
                _c(
                  "div",
                  { staticClass: "pointer", on: { click: _vm.narrow } },
                  [_c("i", { staticClass: "el-icon-minus" })]
                ),
                _c("div", [_vm._v(_vm._s(_vm.scale) + "%")]),
                _c(
                  "div",
                  { staticClass: "pointer", on: { click: _vm.enlarge } },
                  [_c("i", { staticClass: "el-icon-plus" })]
                )
              ]),
              _c(
                "div",
                {
                  ref: "canvasContent",
                  staticClass: "canvas-content",
                  on: {
                    click: function($event) {
                      return _vm.setEditModule(_vm.postInfo.background)
                    },
                    scroll: _vm.contentScroll
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "canvas-content-padding" },
                    [
                      _c("show-com", { attrs: { anime: _vm.editPanelAnime } }),
                      _c("edit-com", { attrs: { anime: _vm.editPanelAnime } }),
                      _c("receive", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.colorPicker,
                            expression: "colorPicker"
                          }
                        ],
                        attrs: { reload: _vm.colorPicker }
                      }),
                      _vm.dragEvent ? _c("empty-com") : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm.saveInfo.psdList && _vm.saveInfo.psdList.length != 1
            ? _c("post-list", { attrs: { max: _vm.saveInfo.psdList.length } })
            : _vm._e(),
          _c("navigation", { attrs: { scrollInfo: _vm.scrollInfo } }),
          _c(
            "div",
            { staticClass: "editor-preview" },
            [
              _vm.group && _vm.group.layerIds.length > 1
                ? _c("group-edit")
                : _vm._e(),
              _vm.editModule.lock
                ? _c("div", { staticClass: "lock-mask" })
                : _vm._e(),
              _vm.editModule.type == "background" ? _c("back-edit") : _vm._e(),
              _vm.editModule.type == "text" ? _c("text-edit") : _vm._e(),
              _vm.editModule.type == "image" ? _c("image-edit") : _vm._e(),
              _vm.editModule.type == "code" ? _c("qr-code-edit") : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "在线印刷",
            visible: _vm.printModelDialog,
            width: "1400px",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.printModelDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "print-content" },
            [
              _c(
                "div",
                [
                  _c(
                    "el-steps",
                    { attrs: { active: _vm.printActive } },
                    [
                      _c("el-step", { attrs: { title: "自助下单" } }),
                      _c("el-step", { attrs: { title: "确认订单" } }),
                      _c("el-step", { attrs: { title: "支付" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.printActive == 1 && _vm.printModelDialog
                ? _c("poduct-detail", {
                    attrs: {
                      printInfo: _vm.printInfo,
                      sourceWorkId: _vm.saveInfo.id
                    },
                    on: { getOrderItem: _vm.getOrderItem }
                  })
                : _vm._e(),
              _vm.printActive == 2 && _vm.printModelDialog
                ? _c("order-item", {
                    attrs: {
                      orderItem: _vm.orderItem,
                      sourceWorkId: _vm.saveInfo.id
                    },
                    on: {
                      toPayment: _vm.toPayment,
                      toPrintInfo: function($event) {
                        _vm.printActive = 1
                      }
                    }
                  })
                : _vm._e(),
              _vm.printActive == 3 && _vm.printModelDialog
                ? _c("payment", { attrs: { paymentInfo: _vm.paymentInfo } })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "origin-pic" }, [_c("origin-com")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }