<template>
  <div class="ImageCanvas">
    <!-- <post-item
      v-for="(item, index) in postList"
      :key="index"
      :postItemWidth="item.canvas.width"
      :postInfo="item"
      :watermark="watermark"
      :ref="'post'"
    ></post-item> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { drawBase64Cover } from '@/api/cubeclient'
import postToImage from '@/lib/postToImage'
export default {
  data () {
    return {
      watermark: true
    }
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  mounted () {
  },
  computed: {
    ...mapState('edit', ['postList'])
  },
  methods: {
    async createImage (watermark) {
      this.loading = this.$loading({
        lock: true,
        text: '资源保存中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.watermark = watermark
      let svgList = postToImage.getAllSvg()
      let imageList = svgList.map(item => {
        let image = new Image()
        image.src = `data:image/svg+xml;charset=utf-8,${item}`

        return image
      })
      let loadList = imageList.map(image => {
        return new Promise((res, rej) => {
          image.onload = () => {
            res()
          }
        })
      })
      await Promise.all(loadList)
      let imageBase64List = imageList.map(image => {
        let canvas = document.createElement("canvas")
        canvas.setAttribute('width', image.naturalWidth)
        canvas.setAttribute('height', image.naturalHeight)
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
        return {
          imageBase64Str: canvas.toDataURL('image/png'),
          imageType: 'png'
        }
      })
      await drawBase64Cover({
        id: this.id,
        imageBase64List
      })
      this.loading.close()
    }
  }

}
</script>

<style>
.ImageCanvas {
  width: 0;
  height: 0;
  overflow: hidden;
  display: inline-block;
}
</style>
