var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            width: "30%",
            showClose: false
          },
          on: {
            beforeClose: function($event) {
              return _vm.$emit("submit")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "120px",
                model: _vm.form,
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发票类型", prop: "ifVat" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.ifVat,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "ifVat", $$v)
                        },
                        expression: "form.ifVat"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("增值普通发票")
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("增值专用发票")
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("个人")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.ifVat == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "抬头名称", prop: "vatInvoiceTitle" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", "show-word-limit": "" },
                        model: {
                          value: _vm.form.vatInvoiceTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatInvoiceTitle", $$v)
                          },
                          expression: "form.vatInvoiceTitle"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "公司名称", prop: "vatInvoiceTitle" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", "show-word-limit": "" },
                        model: {
                          value: _vm.form.vatInvoiceTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatInvoiceTitle", $$v)
                          },
                          expression: "form.vatInvoiceTitle"
                        }
                      })
                    ],
                    1
                  ),
              _vm.form.ifVat != 3
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "纳税人识别号", prop: "vatTaxpayerNum" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.vatTaxpayerNum,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatTaxpayerNum", $$v)
                          },
                          expression: "form.vatTaxpayerNum"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ifVat == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "注册地址", prop: "vatCompanyAddress" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100", "show-word-limit": "" },
                        model: {
                          value: _vm.form.vatCompanyAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatCompanyAddress", $$v)
                          },
                          expression: "form.vatCompanyAddress"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ifVat == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "注册电话", prop: "vatTelphone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.vatTelphone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatTelphone", $$v)
                          },
                          expression: "form.vatTelphone"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ifVat == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "开户银行", prop: "vatBankName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", "show-word-limit": "" },
                        model: {
                          value: _vm.form.vatBankName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatBankName", $$v)
                          },
                          expression: "form.vatBankName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ifVat == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "银行账户", prop: "vatBankAccount" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.vatBankAccount,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "vatBankAccount", $$v)
                          },
                          expression: "form.vatBankAccount"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("立即提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }