var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "post",
      staticClass: "canvas-area",
      style: {
        width: _vm.postInfo.canvas.width + "px",
        height: _vm.postInfo.canvas.height + "px",
        borderWidth:
          (80 / _vm.scale) * 100 +
          "px " +
          (80 / _vm.scale) * 100 +
          "px " +
          (80 / _vm.scale) * 100 +
          "px " +
          (80 / _vm.scale) * 100 +
          "px",
        transform: "scale(" + _vm.scale / 100 + "," + _vm.scale / 100 + ") ",
        transition: (_vm.anime ? 0.5 : 0) + "s",
        left: _vm.canvasLeft + "px",
        top: _vm.canvasTop + "px"
      },
      on: {
        drop: _vm.drop,
        dragover: _vm.allowDrop,
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showbleedingline,
              expression: "showbleedingline"
            }
          ],
          staticClass: "bleedingline",
          style: {
            width: _vm.postInfo.canvas.width + "px",
            height: _vm.postInfo.canvas.height + "px"
          }
        },
        [
          _c("div", {
            staticClass: "bleed-line",
            style: {
              borderWidth: (2 / _vm.scale) * 100 + "px"
            }
          }),
          _c("div", { staticClass: "bleed-item one" }),
          _c("div", { staticClass: "bleed-item two" }),
          _c("div", { staticClass: "bleed-item three" }),
          _c("div", { staticClass: "bleed-item four" }),
          _c("div", { staticClass: "bleed-item five" }),
          _c("div", { staticClass: "bleed-item six" }),
          _c("div", { staticClass: "bleed-item seven" }),
          _c("div", { staticClass: "bleed-item eight" })
        ]
      ),
      _vm.water
        ? _c("div", {
            staticClass: "background",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.setBack($event)
              }
            }
          })
        : _vm._e(),
      _vm.group && _vm.group.layerIds.length > 1
        ? _c("d-group", { attrs: { module: _vm.group } })
        : _vm._e(),
      _vm._l(_vm.postInfo.images, function(item) {
        return _c("d-image", { key: item.imageId, attrs: { image: item } })
      }),
      _vm._l(_vm.postInfo.texts, function(item) {
        return _c("d-text", { key: item.textId, attrs: { textInfo: item } })
      }),
      _vm._l(_vm.postInfo.codes, function(item) {
        return _c("d-code", { key: item.codeId, attrs: { code: item } })
      }),
      _vm.cropImage ? _c("image-crop") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }