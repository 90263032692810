<template>
  <div class="codeList">
    <div class="item"><code-demo></code-demo></div>
    <div class="item">
      <code-demo colorDark="rgba(255, 0, 0,1)"></code-demo>
    </div>
    <div class="item">
      <code-demo colorDark="rgba(0, 98, 255,1)"></code-demo>
    </div>
    <div class="item">
      <code-demo colorDark="rgba(217, 0, 255,1)"></code-demo>
    </div>
    <div class="item">
      <code-demo colorDark="rgba(40, 202, 0,1)"></code-demo>
    </div>
    <div class="item">
      <code-demo colorDark="rgba(155, 47, 218,1)"></code-demo>
    </div>
    <div class="updataButton" v-if="isLogin">
      <upload-file :img="true" @addFile="addImgEvent">
        <i class="el-icon-plus" :img="true"></i>
      </upload-file>
    </div>
    <div class="updataButton" @click="$Login" v-if="!isLogin">
      <i class="el-icon-plus" :img="true"></i>
    </div>
  </div>
</template>

<script>
import CodeDemo from './CodeDemo.vue'
import model from '@/lib/model'
import uploadFile from '@/components/upload/uploadFile.vue'
import { materialadd } from '@/api/cubeclient'
export default {
  data () {
    return {
      isLogin: false
    }
  },
  created () {
    if (localStorage.getItem('token') == null) {
      this.isLogin = false
    } else {
      this.isLogin = true
    }
  },
  components: {
    CodeDemo,
    uploadFile
  },
  methods: {
    async addImgEvent (data) {
      const img = new Image()
      img.src = data.imgUrl
      img.onload = () => {
        const picParam = model.createImage({
          src: data.imgUrl,
          height: img.naturalHeight,
          width: img.naturalWidth
        })
        this.$emit('addImage', picParam)
      }
      const res = await materialadd({
        ...data,
        type: 'img'
      })
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '上传成功'
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.codeList {
  float: left;
  .item {
    margin: 10px;
    display: inline-block;
  }
  color: rgb(40, 202, 0);
}

.updataButton {
  cursor: pointer;
  width: 250px;
  height: 50px;
  background: rgb(0, 204, 255);
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 900;
  margin-left: 50px;
}
</style>
