<template>
  <i
    class="el-icon-refresh swing-button"
    @mousedown.stop="rotate"
    :style="{
      transform: `translateX(-50%) scale(${moveScale})`,
      bottom: `${-80 * moveScale}px`,
    }"
  ></i>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    module: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState('edit', ['scale']),
    moveScale () {
      return 100 / this.scale
    },
    rotatePositonX () {
      // return this.image.left+this.image.width/2 - Math.sin(this.image.rotate * (Math.PI / 180)) *  (this.image.height/2+40)
      return Math.sin(this.module.rotate * (Math.PI / 180)) * (this.module.height / 2 + 40 * this.moveScale)
    },
    rotatePositonY () {
      return -Math.cos(this.module.rotate * (Math.PI / 180)) * (this.module.height / 2 + 40 * this.moveScale)
      //  return this.image.top+this.image.height/2 - Math.cos(this.image.rotate * (Math.PI / 180)) * (this.image.height/2+40)
    }
  },
  methods: {
    ...mapMutations('edit', ['pushHistoryRecord']),
    /**
     * 旋转操作
     */
    rotate () {
      if (this.module.lock) {
        return
      }
      const oriX = event.clientX
      const oriY = event.clientY
      const orileft = this.rotatePositonX
      const oritop = this.rotatePositonY
      let pushHistory = false
      window.onmousemove = (event) => {
        const X = event.clientX
        const Y = event.clientY
        const width = (X - oriX) * this.moveScale - orileft
        const height = (Y - oriY) * this.moveScale - oritop
        let deg = 0
        if (width < 0 && height > 0) {
          deg = -Math.atan(width / height) / Math.PI * 180
        } else if (width < 0 && height < 0) {
          deg = 180 - Math.atan(width / height) / Math.PI * 180
        } else if (width > 0 && height < 0) {
          deg = 180 - Math.atan(width / height) / Math.PI * 180
        } else {
          deg = 360 - Math.atan(width / height) / Math.PI * 180
        }

        // if(height < 0){
        //   deg+=180
        // }

        this.module.rotate = deg
        this.$emit('change')
        pushHistory = true
      }
      window.onmouseup = (event) => {
        window.onmousemove = null
        if (pushHistory) {
          this.pushHistoryRecord()
          window.onmouseup = null
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.swing-button {
  font-size: 20px;
  color: rgb(0, 0, 0);
  background-color: white;
  position: absolute;

  left: 50%;
  border-radius: 50%;

  cursor: pointer;
}
</style>
