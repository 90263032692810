<template>
  <div
    class="post"
    :style="{
      height: `${
        (postInfo.canvas.height * postItemWidth) / postInfo.canvas.width
      }px`,
      width: `${postItemWidth}px`,
    }"
  >
    <div class="mask"></div>
    <div
      class="canvas"
      :style="{
        width: `${postInfo.canvas.width}px`,
        height: `${postInfo.canvas.height}px`,
        transform: `scale(${postItemWidth / postInfo.canvas.width},${
          postItemWidth / postInfo.canvas.width
        }) translateX(${
          -((1 * postInfo.canvas.width) / postItemWidth - 1) * 50
        }%) translateY(${
          -((1 * postInfo.canvas.width) / postItemWidth - 1) * 50
        }%)`,
      }"
    >
      <!-- background: `rgb(235, 235, 235)` -->
      <div
        class="background"
        :style="{
          background: postInfo.background.backgroundColor,
        }"
      >
        <img
          v-if="postInfo.background.backgroundImage"
          :src="postInfo.background.backgroundImage"
          :style="{
            width: `${postInfo.background.width}px`,
            height: `${postInfo.background.height}px`,
            top: `-${postInfo.background.imageInfo.backgroundPositionY}px`,
            left: `-${postInfo.background.imageInfo.backgroundPositionX}px`,
          }"
        />
      </div>
      <d-text
        v-for="item in postInfo.texts"
        :textInfo="item"
        :key="item.textId"
        :display="true"
      ></d-text>
      <d-image
        v-for="item in postInfo.images"
        :image="item"
        :key="item.imageId"
        :display="true"
      ></d-image>
      <d-code
        v-for="item in postInfo.codes"
        :code="item"
        :key="item.codeId"
        :display="true"
      ></d-code>
    </div>
    <div class="watermark" v-if="watermark"></div>
  </div>
</template>

<script>
import DText from '../operation/DText.vue'
import DImage from '../operation/DImage.vue'
import DCode from '../operation/DCode.vue'

export default {
  props: {
    postInfo: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    },
    postItemWidth: {
      type: Number,
      default: 0
    },
    watermark: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  components: {
    DText,
    DCode,
    DImage
  },
  methods: {

  }

}
</script>

<style lang="scss" scoped>
.post {
  position: relative;
  display: inline-block;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .canvas {
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    .background {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        position: absolute;
      }
    }
  }
}
.watermark {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-image: url("~@/assets/watermark.svg");
}
</style>
