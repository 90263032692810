var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "code",
      on: { dragstart: _vm.drag, dragend: _vm.dragEnd, click: _vm.selectCode }
    },
    [_c("div", { ref: "qrcode" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }