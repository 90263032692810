<template>
  <div
    @mouseenter="showTips = true"
    @mouseout="showTips = false"
    @mousedown="mousedown"
    v-show="!cropImage || image.id != editSelectedId"
    :class="[
      'img-content',
      {
        selected: image.id == editSelectedId && !image.lock && !display,
        lock: image.lock && image.id == editSelectedId,
      },
    ]"
    :style="{
      width: image.width + 'px',
      height: image.height + 'px',
      left: image.left + 'px',
      top: image.top + 'px',
      zIndex: image.ZIndex + editZindex,
      transform: `rotate(${image.rotate ? image.rotate : 0}deg)`,
      borderWidth: `${moveScale * 0.5}px`,
    }"
  >
    <div
      class="tips"
      :style="{
        transform: `translateY(-${
          40 + 20 * (moveScale - 1)
        }px) translateX(-50%) scale(${
          showTips && image.id != editSelectedId ? moveScale : 0
        })`,
      }"
    >
      单击操作图片
    </div>
    <div
      class="content-limit"
      v-if="display && !refresh"
      :style="{
        filter: image.boxShadow
          ? `drop-shadow(${image.shadow.hShadow}px ${image.shadow.vShadow}px ${image.shadow.blur}px ${image.shadow.color})`
          : 'none',
        opacity: image.opacity / 100,
        borderRadius: (image.borderRadius * shortBorder) / 100 + 'px',
      }"
    >
      <div
        class="image"
        :style="{
          width: `${image.showInfo.showWidth}px`,
          height: `${image.showInfo.showHeight}px`,
          background: `url(${imageSrc}) no-repeat`,
          backgroundPosition: `-${image.showInfo.backgroundPositionX}px -${image.showInfo.backgroundPositionY}px`,
          backgroundSize: `${image.showInfo.backImageWidth}px ${image.showInfo.backImageHeight}px`,
        }"
      >
        <!-- boxShadow:image.boxShadow?`${image.shadow.hShadow}px ${image.shadow.vShadow}px ${image.shadow.blur}px ${image.shadow.color}`:'none' -->
      </div>
    </div>

    <regulator
      :module="image"
      v-show="
        image.id == editSelectedId &&
        !image.lock &&
        !display &&
        !image.groupId &&
        !group
      "
    ></regulator>
    <rotate
      v-if="
        image.id == editSelectedId &&
        !display &&
        !image.lock &&
        !image.groupId &&
        !group
      "
      :module="image"
    ></rotate>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Rotate from './Rotate.vue'
import Regulator from './Regulator.vue'
function getFormatImageSrc (src) {
  src = src.replace('https://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-test-1257418739.file.myqcloud.com')
  src = src.replace('http://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-test-1257418739.file.myqcloud.com')
  src = src.replace('https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-prod-1257418739.file.myqcloud.com')
  src = src.replace('http://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-prod-1257418739.file.myqcloud.com')
  return src
}
export default {
  props: {
    image: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    },
    display: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      picRate: 1,
      refresh: false,
      showTips: false
    }
  },
  mounted () {
    this.image.picRate = this.image.showInfo.showWidth / this.image.showInfo.showHeight
  },
  components: {
    Rotate,
    Regulator
  },
  methods: {

    ...mapMutations('edit', ['setEditSelectedId', 'setEditModule', 'pushHistoryRecord', 'setEditModuleList', 'setGroupModule', 'initGroupSize']),
    seleteItem () {
    },
    selectThis () {
      // if (event.ctrlKey || event.shiftKey) {
      //   this.setEditModuleList(this.image)
      //   return
      // }
      this.setEditModule(this.image)
    },
    /**
     * 拖拽
     */
    mousedown () {
      let module = this.image
      if (this.image.lock) {
        if (!this.group) {
          this.setEditModule(this.image)
        }
        return
      }
      if (event.ctrlKey || event.shiftKey) {
        this.setEditModuleList(this.image)
        return
      }
      if (this.image.groupId) {
        this.setGroupModule(this.image.groupId)
        this.initGroupSize()

        this.selectThis()

        module = this.group
      }
      if (this.group && this.group.layerIds.indexOf(this.image.id) !== -1) {
        module = this.group
      } else {
        this.selectThis()
      }

      const oriX = event.clientX
      const oriY = event.clientY
      const orileft = module.left
      const oritop = module.top
      let pushHistory = false
      window.onmousemove = (event) => {
        const X = event.clientX
        const Y = event.clientY
        module.left = orileft + (X - oriX) * this.moveScale
        module.top = oritop + (Y - oriY) * this.moveScale
        pushHistory = true
        this.$bus.$emit('resetGroupItem')
      }
      window.onmouseup = (event) => {
        window.onmousemove = null
        window.onmouseup = null
        module.left = Math.round(module.left)
        module.height = Math.round(module.height)
        module.width = Math.round(module.width)
        module.top = Math.round(module.top)
        if (pushHistory) {
          this.pushHistoryRecord()
        } else {
          this.selectThis()
        }
      }
    },

    changeWidth () {
      let width = this.image.width

      if (this.image.width / this.image.height > this.image.picRate) {
        width = this.image.width
      } else {
        width = this.image.height * this.image.picRate
      }
      const rate = width / this.image.showInfo.showWidth
      this.image.showInfo.showWidth = width
      this.image.showInfo.backgroundPositionX *= rate
      this.image.showInfo.backImageWidth *= rate
    },
    changeHeight () {
      let height = this.image.height
      if (this.image.width / this.image.height < this.image.picRate) {
        height = this.image.height
      } else {
        height = this.image.width / this.image.picRate
      }
      const rate = height / this.image.showInfo.showHeight
      this.image.showInfo.showHeight = height
      this.image.showInfo.backgroundPositionY *= rate
      this.image.showInfo.backImageHeight *= rate
    }
  },
  computed: {
    ...mapState('edit', ['editSelectedId', 'scale', 'cropImage', 'group']),

    imageSrc () {
      return getFormatImageSrc(this.image.src)
    },
    shortBorder () {
      if (this.image.width < this.image.height) {
        return this.image.width
      } else {
        return this.image.height
      }
    },
    moveScale () {
      return 100 / this.scale
    },
    imageStyle () {
      return {
        width: `${this.image.showInfo.showWidth}px`,
        height: `${this.image.showInfo.showHeight}px`,
        background: `url(${this.image.src}) no-repeat`,
        backgroundPosition: `-${this.image.showInfo.backgroundPositionX}px -${this.image.showInfo.backgroundPositionY}px`,
        backgroundSize: `${this.image.showInfo.backImageWidth}px ${this.image.showInfo.backImageHeight}px`

      }
    },
    groupSelected () {
      if (!this.group) {
        return false
      }
      if (this.group.layerIds.findIndex((item) => item === this.image.id) !== -1) {
        return true
      }
      return false
    },
    editZindex () {
      if (!this.display && (this.image.id == this.editSelectedId || this.groupSelected)) {
        return 200
      }
      return 0
    }

  },
  watch: {
    'image.src' (newValue, oldValue) {
      this.picRate = this.image.showInfo.backImageWidth / this.image.showInfo.backImageHeight
      this.refresh = true
      setTimeout(() => {
        this.refresh = false
      }, 100)
    },
    'image.width' (newValue, oldValue) {
      this.changeWidth()
      this.changeHeight()
      // return width
    },
    'image.height' (newValue, oldValue) {
      this.changeWidth()
      this.changeHeight()
      // return height
    }

  }

}
</script>
<style lang="scss" scoped>
.img-content {
  position: absolute;
  display: inline-block;
  cursor: move;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &:hover {
    border: 1px solid rgb(0, 89, 255);
  }
  .tips {
    text-align: center;
    width: 120px;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.8);
    display: inline-block;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 0;
    transition: 0.2s;
  }
}

.lock {
  border: solid 1px red;
}
.selected {
  border: 1px solid rgb(0, 162, 255);
}
.item {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  z-index: 2000;
}
.item-Horizontal {
  position: absolute;
  width: 30px;
  height: 5px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  z-index: 2000;
}
.item-vertical {
  position: absolute;
  width: 5px;
  height: 30px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  z-index: 2000;
}
.image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.content-limit {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
</style>
