var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "receive-content",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.closeColorPicker($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          ref: "post",
          staticClass: "canvas-area",
          style: {
            width: _vm.postInfo.canvas.width + "px",
            height: _vm.postInfo.canvas.height + "px",
            transition: (_vm.anime ? 0.5 : 0) + "s",
            borderWidth:
              (80 / _vm.scale) * 100 +
              "px " +
              (80 / _vm.scale) * 100 +
              "px " +
              (80 / _vm.scale) * 100 +
              "px " +
              (80 / _vm.scale) * 100 +
              "px",
            transform:
              "scale(" + _vm.scale / 100 + "," + _vm.scale / 100 + ") ",
            left: _vm.canvasLeft + "px",
            top: _vm.canvasTop + "px"
          },
          on: { click: _vm.selectColor }
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.colorItem,
                expression: "colorItem"
              }
            ],
            staticClass: "colorSelected",
            style: {
              borderRadius: 30 * _vm.moveScale + "px",
              width: 60 * _vm.moveScale + "px",
              height: 60 * _vm.moveScale + "px",
              top: _vm.top + "px",
              left: _vm.left + "px",
              backgroundColor: _vm.backgroundColor,
              border: 1 * _vm.moveScale + "px solid black"
            }
          }),
          _c("div", {
            staticClass: "mask",
            on: {
              mousemove: _vm.mousemove,
              mouseenter: function($event) {
                _vm.colorItem = true
              },
              mouseout: function($event) {
                _vm.colorItem = false
              }
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }