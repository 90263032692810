var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "button-area",
        style: {
          transform: "scale(" + _vm.moveScale + ")",
          left: _vm.buttonArea.left + "px",
          top: _vm.buttonArea.top - 100 * _vm.moveScale + "px",
          transform: "scale(" + _vm.moveScale + ") translateX(-50%) "
        }
      },
      [
        _c("div", { staticClass: "block" }, [
          _c("span", { staticClass: "demonstration" }, [_vm._v("缩放")]),
          _c(
            "div",
            { staticClass: "slider" },
            [
              _c("el-slider", {
                attrs: { max: 200, min: 10 },
                on: { change: _vm.zoomChange },
                model: {
                  value: _vm.zoom,
                  callback: function($$v) {
                    _vm.zoom = $$v
                  },
                  expression: "zoom"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "button-item",
            on: {
              click: function($event) {
                return _vm.setCropImage(false)
              }
            }
          },
          [_c("i", { staticClass: "el-icon-close" })]
        ),
        _c(
          "div",
          { staticClass: "button-item", on: { click: _vm.changeImage } },
          [_c("i", { staticClass: "el-icon-check" })]
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "imageCrop",
        style: {
          left: _vm.backImage.left + "px",
          top: _vm.backImage.top + "px",
          transform:
            "rotate(" + (_vm.image.rotate ? _vm.image.rotate : 0) + "deg)",
          height: _vm.image.backImageHeight + "px",
          width: _vm.image.backImageWidth + "px"
        },
        on: { mousedown: _vm.mousedown }
      },
      [
        _c(
          "div",
          {
            staticClass: "content",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "backImg",
                style: {
                  height: _vm.image.backImageHeight + "px",
                  width: _vm.image.backImageWidth + "px"
                }
              },
              [
                _c("img", {
                  staticClass: "fullImg",
                  attrs: { src: _vm.imgsrc }
                }),
                _c(
                  "div",
                  {
                    staticClass: "showImage",
                    style: {
                      width: _vm.image.width + "px",
                      height: _vm.image.height + "px",
                      top: _vm.image.top + "px",
                      left: _vm.image.left + "px"
                    }
                  },
                  [
                    _c("div", { staticClass: "img-content" }, [
                      _c("img", {
                        staticClass: "back-img",
                        style: {
                          width: _vm.image.backImageWidth + "px",
                          height: _vm.image.backImageHeight + "px",
                          top: -_vm.image.top + "px",
                          left: -_vm.image.left + "px"
                        },
                        attrs: { src: _vm.imgsrc }
                      })
                    ]),
                    _c("div", { staticClass: "item-list" }, [
                      _c("div", {
                        staticClass: "item",
                        style: {
                          cursor: _vm.cursor.leftTop,
                          left: 0 + "px",
                          top: 0 + "px",
                          transform:
                            "translateX(-50%) translateY(-50%) scale(" +
                            _vm.moveScale +
                            ")"
                        },
                        on: {
                          mousedown: function($event) {
                            $event.stopPropagation()
                            return _vm.controlshape("left-top")
                          }
                        }
                      }),
                      _c("div", {
                        staticClass: "item-vertical",
                        style: {
                          cursor: _vm.cursor.leftMiddle,
                          left: 0 + "px",
                          top: _vm.image.height / 2 + "px",
                          transform:
                            "translateX(-50%) translateY(-50%) scale(" +
                            _vm.moveScale +
                            ")"
                        },
                        on: {
                          mousedown: function($event) {
                            $event.stopPropagation()
                            return _vm.controlshape("left-middle")
                          }
                        }
                      }),
                      _c("div", {
                        staticClass: "item",
                        style: {
                          cursor: _vm.cursor.leftDown,
                          left: 0 + "px",
                          top: _vm.image.height + "px",
                          transform:
                            "translateX(-50%) translateY(-50%) scale(" +
                            _vm.moveScale +
                            ")"
                        },
                        on: {
                          mousedown: function($event) {
                            $event.stopPropagation()
                            return _vm.controlshape("left-down")
                          }
                        }
                      }),
                      _c("div", {
                        staticClass: "item-Horizontal",
                        style: {
                          cursor: _vm.cursor.middleTop,
                          left: _vm.image.width / 2 + "px",
                          top: 0 + "px",
                          transform:
                            "translateX(-50%) translateY(-50%) scale(" +
                            _vm.moveScale +
                            ")"
                        },
                        on: {
                          mousedown: function($event) {
                            $event.stopPropagation()
                            return _vm.controlshape("middle-top")
                          }
                        }
                      }),
                      _c("div", {
                        staticClass: "item-Horizontal",
                        style: {
                          cursor: _vm.cursor.middleDown,
                          left: _vm.image.width / 2 + "px",
                          top: _vm.image.height + "px",
                          transform:
                            "translateX(-50%) translateY(-50%) scale(" +
                            _vm.moveScale +
                            ")"
                        },
                        on: {
                          mousedown: function($event) {
                            $event.stopPropagation()
                            return _vm.controlshape("middle-down")
                          }
                        }
                      }),
                      _c("div", {
                        staticClass: "item",
                        style: {
                          cursor: _vm.cursor.rightTop,
                          left: _vm.image.width + "px",
                          top: "0px",
                          transform:
                            "translateX(-50%) translateY(-50%) scale(" +
                            _vm.moveScale +
                            ")"
                        },
                        on: {
                          mousedown: function($event) {
                            $event.stopPropagation()
                            return _vm.controlshape("right-top")
                          }
                        }
                      }),
                      _c("div", {
                        staticClass: "item-vertical",
                        style: {
                          cursor: _vm.cursor.rightMiddle,
                          left: _vm.image.width + "px",
                          top: _vm.image.height / 2 + "px",
                          transform:
                            "translateX(-50%) translateY(-50%) scale(" +
                            _vm.moveScale +
                            ")"
                        },
                        on: {
                          mousedown: function($event) {
                            $event.stopPropagation()
                            return _vm.controlshape("right-middle")
                          }
                        }
                      }),
                      _c("div", {
                        staticClass: "item",
                        style: {
                          cursor: _vm.cursor.rightDown,
                          left: _vm.image.width + "px",
                          top: _vm.image.height + "px",
                          transform:
                            "translateX(-50%) translateY(-50%) scale(" +
                            _vm.moveScale +
                            ")"
                        },
                        on: {
                          mousedown: function($event) {
                            $event.stopPropagation()
                            return _vm.controlshape("right-down")
                          }
                        }
                      })
                    ])
                  ]
                )
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }