<template>
  <div
    class="navigation"
    :style="{ width: `${postItemWidth + 20}px` }"
    v-show="show"
  >
    <i class="el-icon-full-screen open" @click="shrink = false"></i>
    <i
      class="el-icon-circle-close close"
      v-show="!shrink"
      @click="shrink = true"
    ></i>

    <div
      class="postShow"
      v-show="!shrink"
      :style="{
        height: `${
          (postInfo.canvas.height * postItemWidth) / postInfo.canvas.width
        }px`,
      }"
    >
      <div
        class="view-port"
        @mousedown="mousedown"
        v-show="!shrink"
        :style="{
          width: `${viewWidth + 160 * scaleRate}px`,
          height: `${viewHeight + 80 * scaleRate}px`,
          top: `${top}px`,
          left: `${left}px`,
        }"
      ></div>
      <post-item
        :postItemWidth="postItemWidth"
        :postInfo="postInfo"
      ></post-item>
    </div>
  </div>
</template>

<script>
import PostItem from '../panel/PostItem.vue'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      shrink: false,
      postItemWidth: 90

    }
  },
  props: {
    scrollInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: { left: 0, top: 0 }
    }
  },
  components: {
    PostItem
  },

  methods: {
    /**
     * 拖拽显示框操作
     */
    mousedown (event) {
      let viewLeft = this.scrollLeft * this.scaleRate
      let viewTop = this.scrollTop * this.scaleRate
      const oriX = event.clientX
      const oriY = event.clientY
      const orileft = viewLeft
      const oritop = viewTop

      window.onmousemove = (event) => {
        const X = event.clientX
        const Y = event.clientY
        const borderNum = 80 * this.scaleRate
        if (orileft + X - oriX < 0) {
          viewLeft = 0
        } else if (orileft + X - oriX + this.viewWidth > this.postItemWidth + borderNum) {
          viewLeft = this.postItemWidth - this.viewWidth + borderNum
        } else {
          viewLeft = orileft + X - oriX
        }
        if (oritop + Y - oriY + borderNum < 0) {
          viewTop = -borderNum
        } else if (oritop + Y - oriY + this.viewHeight > this.canvasHeight + borderNum * 2) {
          viewTop = this.canvasHeight - this.viewHeight + borderNum * 2
          //  viewTop = oritop+Y-oriY
        } else {
          viewTop = oritop + Y - oriY
        }

        this.$bus.$emit('changeScroll', viewTop / this.scaleRate, viewLeft / this.scaleRate)
      }
      window.onmouseup = (event) => {
        window.onmousemove = null
      }
    }
  },
  computed: {
    ...mapState('edit', ['postInfo', 'scale', 'canvas', 'editSize']),
    /**
     * 位置
     */
    top () {
      if (this.canvas.height * this.scale / 100 <= this.editSize.editHeight) {
        return 0
      }
      // console.log(`${this.scrollTop}  ${this.scaleRate}   ${(this.scrollTop-400)*this.scaleRate}`)
      return (this.scrollTop - 80) * this.scaleRate
    },
    /**
        * 位置
        */
    left () {
      //  if(this.canvas.width*this.scale/100<=this.editSize.editWidth){
      //  return 0
      // }
      // return (this.scrollLeft+80)*this.scaleRate
      return (this.scrollLeft - 80) * this.scaleRate
    },
    /**
     * 显示框尺寸
     */
    viewWidth () {
      if (this.canvas.width * this.scale / 100 <= this.editSize.editWidth) {
        return this.postItemWidth
      } else {
        return this.editSize.editWidth * this.scaleRate
      }
    },
    /**
 * 显示框尺寸
 */
    viewHeight () {
      if (this.canvas.height * this.scale / 100 <= this.editSize.editHeight) {
        return this.canvasHeight
      } else {
        return this.editSize.editHeight * this.scaleRate
      }
    },
    canvasHeight () {
      return this.postItemWidth / this.canvas.width * this.canvas.height
    },
    scaleRate () {
      return this.postItemWidth / this.canvas.width * 100 / this.scale
    },
    show () {
      if (this.viewWidth < this.postItemWidth || this.viewHeight < this.canvasHeight) {
        return true
      } else {
        return false
      }
    },
    scrollLeft () {
      return this.scrollInfo.left
    },
    scrollTop () {
      return this.scrollInfo.top
    }
  },
  watch: {
    show (newValue, oldValue) {
      if (newValue) {
        this.shrink = false
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.navigation {
  right: 420px;
  bottom: 40px;
  /* overflow-y: scroll;
    overflow-x: hidden; */
  position: fixed;
  z-index: 999;

  .open {
    background-color: rgb(255, 255, 255);
    //  box-shadow: 0 0 2px  rgb(0, 0, 0);
    border: 1px solid rgb(0, 162, 255);
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: rgb(0, 195, 255);
    font-size: 20px;
  }
  .close {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;
    color: white;
    font-size: 20px;
    transform: translateX(-50%) translateY(-50%);
  }
  .postShow {
    display: inline-block;
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.472);
    overflow: hidden;
    position: relative;
  }
}
.view-port {
  border: 1px solid blue;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
</style>
