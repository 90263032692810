var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item-list" }, [
    _vm.module.type != "text"
      ? _c("div", {
          staticClass: "item",
          style: {
            cursor: _vm.cursor.leftTop,
            left: 0 + "px",
            top: 0 + "px",
            transform:
              "translateX(-50%) translateY(-50%) scale(" + _vm.moveScale + ")"
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.controlshape("left-top")
            }
          }
        })
      : _vm._e(),
    _vm.module.type != "group" && _vm.module.type != "code"
      ? _c("div", {
          staticClass: "item-vertical",
          style: {
            cursor: _vm.cursor.leftMiddle,
            left: 0 + "px",
            top: _vm.module.height / 2 + "px",
            transform:
              "translateX(-50%) translateY(-50%) scale(" + _vm.moveScale + ")"
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.controlshape("left-middle")
            }
          }
        })
      : _vm._e(),
    _vm.module.type != "text"
      ? _c("div", {
          staticClass: "item",
          style: {
            cursor: _vm.cursor.leftDown,
            left: 0 + "px",
            top: _vm.module.height + "px",
            transform:
              "translateX(-50%) translateY(-50%) scale(" + _vm.moveScale + ")"
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.controlshape("left-down")
            }
          }
        })
      : _vm._e(),
    _vm.module.type != "group" &&
    _vm.module.type != "code" &&
    _vm.module.type != "text"
      ? _c("div", {
          staticClass: "item-Horizontal",
          style: {
            cursor: _vm.cursor.middleTop,
            left: _vm.module.width / 2 + "px",
            top: 0 + "px",
            transform:
              "translateX(-50%) translateY(-50%) scale(" + _vm.moveScale + ")"
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.controlshape("middle-top")
            }
          }
        })
      : _vm._e(),
    _vm.module.type != "group" &&
    _vm.module.type != "code" &&
    _vm.module.type != "text"
      ? _c("div", {
          staticClass: "item-Horizontal",
          style: {
            cursor: _vm.cursor.middleDown,
            left: _vm.module.width / 2 + "px",
            top: _vm.module.height + "px",
            transform:
              "translateX(-50%) translateY(-50%) scale(" + _vm.moveScale + ")"
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.controlshape("middle-down")
            }
          }
        })
      : _vm._e(),
    _vm.module.type != "text"
      ? _c("div", {
          staticClass: "item",
          style: {
            cursor: _vm.cursor.rightTop,
            left: _vm.module.width + "px",
            top: "0px",
            transform:
              "translateX(-50%) translateY(-50%) scale(" + _vm.moveScale + ")"
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.controlshape("right-top")
            }
          }
        })
      : _vm._e(),
    _vm.module.type != "group" && _vm.module.type != "code"
      ? _c("div", {
          staticClass: "item-vertical",
          style: {
            cursor: _vm.cursor.rightMiddle,
            left: _vm.module.width + "px",
            top: _vm.module.height / 2 + "px",
            transform:
              "translateX(-50%) translateY(-50%) scale(" + _vm.moveScale + ")"
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.controlshape("right-middle")
            }
          }
        })
      : _vm._e(),
    _vm.module.type != "text"
      ? _c("div", {
          staticClass: "item",
          style: {
            cursor: _vm.cursor.rightDown,
            left: _vm.module.width + "px",
            top: _vm.module.height + "px",
            transform:
              "translateX(-50%) translateY(-50%) scale(" + _vm.moveScale + ")"
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.controlshape("right-down")
            }
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }