var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "MarketingActivities",
      on: {
        keydown: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "pic-area" }, [
          _c("div", { staticClass: "main-pic" }, [
            _c("img", { attrs: { src: "https://" + _vm.showImg } })
          ]),
          _vm.product.decript
            ? _c(
                "div",
                { staticClass: "small-pic-list" },
                _vm._l(_vm.product.decript.picture, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "small-pic-item",
                      on: {
                        mouseover: function($event) {
                          _vm.showImg = item
                        }
                      }
                    },
                    [_c("img", { attrs: { src: "https://" + item } })]
                  )
                }),
                0
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "info-area" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.product.spuName) + " ")
            ]),
            _c("div", { staticClass: "tip" }),
            _c("div", { staticClass: "total-price" }, [
              _vm._v("￥" + _vm._s(_vm.price))
            ]),
            _c("div", { staticClass: "purchase-information" }),
            _vm._m(0),
            _vm._l(_vm.product.saleAttrVos, function(item, index) {
              return _c("div", { key: index, staticClass: "oper-list" }, [
                _c("div", { staticClass: "oper-name" }, [
                  _vm._v(_vm._s(item.attrName))
                ]),
                _c(
                  "div",
                  { staticClass: "oper-button-area" },
                  _vm._l(item.attrValues, function(attrV, addtVIndex) {
                    return _c(
                      "div",
                      {
                        key: addtVIndex,
                        class: ["button", { active: item.selected == attrV }],
                        on: {
                          click: function($event) {
                            return _vm.selectAttr(item, attrV)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(attrV.attrValue) + " ")]
                    )
                  }),
                  0
                )
              ])
            }),
            _c("div", { staticClass: "oper-list" }, [
              _c("div", { staticClass: "oper-name" }, [_vm._v("文件")]),
              _c("div", { staticClass: "oper-button-area" }, [
                _vm._v(" " + _vm._s(_vm.printInfo.fileName) + " ")
              ])
            ]),
            _vm.publishStatus != "0"
              ? _c("div", { staticClass: "purchase-area" }, [
                  _c(
                    "div",
                    {
                      staticClass: "purchase-button",
                      on: { click: _vm.toOrderPage }
                    },
                    [_vm._v("立即购买")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "shopping-cart",
                      on: { click: _vm.addShopCar }
                    },
                    [_vm._v("加入购物车")]
                  )
                ])
              : _c("div", { staticClass: "warn", attrs: { align: "center" } }, [
                  _vm._v("该商品已下架")
                ])
          ],
          2
        ),
        _c("div", { staticClass: "tab-list" }, [
          _c(
            "div",
            {
              class: ["tab-item", { active: _vm.showPart == 1 }],
              on: {
                click: function($event) {
                  _vm.showPart = 1
                }
              }
            },
            [_vm._v(" 印品详情 ")]
          ),
          _c(
            "div",
            {
              class: ["tab-item", { active: _vm.showPart == 2 }],
              on: {
                click: function($event) {
                  _vm.showPart = 2
                }
              }
            },
            [_vm._v(" 订购流程 ")]
          )
        ]),
        _vm.product.decript
          ? _c("div", { staticClass: "tab-content" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPart == 1,
                      expression: "showPart == 1"
                    }
                  ]
                },
                _vm._l(_vm.product.decript.details, function(item, index) {
                  return _c("img", {
                    key: index,
                    staticClass: "product-info-img",
                    attrs: { src: "http://" + item }
                  })
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPart == 2,
                      expression: "showPart == 2"
                    }
                  ]
                },
                _vm._l(_vm.product.decript.process, function(item, index) {
                  return _c("img", {
                    key: index,
                    staticClass: "product-info-img",
                    attrs: { src: "http://" + item }
                  })
                }),
                0
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ensure-list" }, [
      _c("div", { staticClass: "ensure-item black" }, [_vm._v("正规发票")]),
      _c("div", { staticClass: "ensure-item blue" }, [_vm._v("品质保证")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }