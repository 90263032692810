var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Payment",
      on: {
        keydown: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "content", attrs: { align: "center" } },
        [
          _c("i", { staticClass: "el-icon-success tip-image" }),
          _c("div", [_vm._v("订单提交成功！请尽快支付！")]),
          _c("div", [
            _vm._v(" 共1张订单，"),
            _c(
              "span",
              {
                staticClass: "order-center",
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "PrintOrder" })
                  }
                }
              },
              [_vm._v("订单中心")]
            ),
            _vm._v(
              "。请在当天内完成支付，待支付：" + _vm._s(_vm.payAmount) + " "
            )
          ]),
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              model: {
                value: _vm.payChannel,
                callback: function($$v) {
                  _vm.payChannel = $$v
                },
                expression: "payChannel"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "微信支付", name: "1" } }, [
                _c("div", { ref: "qrcode1" })
              ]),
              false
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "支付宝支付", name: "2" } },
                    [_c("div", { ref: "qrcode2" })]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }