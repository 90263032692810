<template>
  <img :src="src" />
</template>

<script>
import qrcodejs2 from 'qrcodejs2'
export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      src: ''
    }
  },
  mounted () {
    const dom = document.createElement('div')
    const res = new qrcodejs2(dom, {
      text: this.url,
      width: 100,
      height: 100,
      colorDark: 'rgb(0, 0, 0)', // 二维码颜色
      colorLight: '#ffffff', // 二维码背景色
      correctLevel: qrcodejs2.CorrectLevel.L// 容错率，L/M/H
    })
    this.src = dom.querySelector('canvas').toDataURL('image/png')
  },
  watch: {
    url (nv) {
      const dom = document.createElement('div')
      const res = new qrcodejs2(dom, {
        text: this.url,
        width: 100,
        height: 100,
        colorDark: 'rgb(0, 0, 0)', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: qrcodejs2.CorrectLevel.L// 容错率，L/M/H
      })
      this.src = dom.querySelector('canvas').toDataURL('image/png')
    }
  }
}
</script>

<style>
</style>
