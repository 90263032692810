var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "group",
      style: {
        width: _vm.module.width + "px",
        height: _vm.module.height + "px",
        left: _vm.module.left + "px",
        top: _vm.module.top + "px",
        transform:
          "rotate(" + (_vm.module.rotate ? _vm.module.rotate : 0) + "deg)",
        borderWidth: _vm.moveScale * 1 + "px",
        borderColor: "" + (_vm.module.lock ? "red" : "blue"),
        zIndex: 100
      },
      on: {
        mousedown: function($event) {
          $event.stopPropagation()
          return _vm.mousedown($event)
        }
      }
    },
    [
      !_vm.module.lock
        ? _c("regulator", { attrs: { module: _vm.module } })
        : _vm._e(),
      !_vm.module.lock
        ? _c("rotate", {
            attrs: { module: _vm.module },
            on: { change: _vm.resetGroupItem }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }