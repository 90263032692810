<template>
  <div class="template-centent" v-infinite-scroll="loadRecommend">
    <template v-if="!noneModel">
      <div class="my-item" v-for="(itemList, index) in imgShow" :key="index">
        <div
          class="recommend-item"
          v-for="item in itemList"
          :key="item.id"
          @click="$emit('selectTemplate', item)"
        >
          <img :src="item.coverImg" />
        </div>
      </div>
    </template>
    <div v-else class="noneModelInfo">没有找到您想要的搜索结果</div>
  </div>
</template>

<script>
import { queryPage } from '@/api/cubeclient'
export default {
  data () {
    return {
      recommenPage: 1,
      limit: 20,
      recommenMore: true,
      imgShow: [
        [],
        []
      ],
      columnHeight: [0, 0]
    }
  },
  props: {
    searchName: {
      type: String,
      default: ''
    }
  },
  created () {
    this.seachRecommenList()
  },
  methods: {
    initWaterfall () {
      this.imgShow = [
        [],
        []
      ]
      this.columnHeight = [0, 0]
    },
    loadRecommend () {
      if (this.recommenMore) {
        this.seachRecommenList()
      }
    },
    // 搜索推荐模板
    async seachRecommenList () {
      const res = await queryPage({
        pageNo: this.recommenPage++,
        planarName: this.searchName,
        pageSize: this.limit,
        sort: 1
      })
      if (res.data.list == null || res.data.list.length < this.limit) {
        this.recommenMore = false
      }
      const imgList = res.data.list
      if (imgList.length == 0) {
        return
      }
      /**
       * 瀑布流操作
       */
      const loadImage = () => {
        const imgItem = imgList.pop()
        const img = new Image()
        img.src = imgItem.coverImg
        const columnHeight = this.columnHeight
        const imgShow = this.imgShow
        img.onload = () => {
          let minIndex = 0
          let minValue = columnHeight[0]
          columnHeight.forEach((e, index) => {
            if (e < minValue) {
              minValue = e
              minIndex = index
            }
          })
          imgShow[minIndex].push(imgItem)
          columnHeight[minIndex] += img.naturalHeight / img.naturalWidth * 230

          if (imgList.length != 0) {
            loadImage()
          }
        }
      }
      loadImage()
    }

  },
  computed: {
    noneModel () {
      const result = this.imgShow.flat(Infinity)
      return result.length == 0
    }
  },
  watch: {
    searchName (newV, oldV) {
      this.recommenPage = 0
      this.initWaterfall()
      this.seachRecommenList()
    }
  }

}
</script>

<style lang="scss" scoped>
.noneModelInfo {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  width: 200px;
  transform: translateX(-50%) translateY(-50%);
}
.template-centent {
  width: 350px;
  padding: 27px 0 0 28px;
  padding-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 750px;
  .my-item {
    cursor: pointer;
    display: inline-block;
    float: left;
    width: 136px;
    // height: 242px;
    margin: 0 20px 20px 0;
  }
  img {
    margin-bottom: 5px;
    width: 100%;
    height: 100%;
  }
  .own-item {
    float: left;
    width: 136px;
    margin: 0 20px 20px 0;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
</style>
