var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit-head",
      on: {
        keydown: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c("div", { staticClass: "logo-text", on: { click: _vm.toIndex } }, [
        _c("img", {
          staticClass: "logo-img",
          attrs: { src: require("@/assets/img/editLogo.png") }
        })
      ]),
      _c(
        "div",
        { staticClass: "file" },
        [
          _c(
            "el-popover",
            {
              staticStyle: { cursor: "pointer" },
              attrs: {
                placement: "bottom",
                width: "100",
                trigger: "click",
                "show-timeout": "0"
              }
            },
            [
              _c("div", [
                _c("div", { staticClass: "modelInfo" }, [
                  _c("div", [
                    _c("div", { staticClass: "planarName" }, [
                      _c("div", { staticClass: "name" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.editName,
                                expression: "!editName"
                              }
                            ]
                          },
                          [_vm._v(_vm._s(_vm.model.planarName))]
                        )
                      ]),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.editName,
                            expression: "!editName"
                          }
                        ],
                        staticClass: "el-icon-edit-outline",
                        on: { click: _vm.editPlanarName }
                      })
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editName,
                          expression: "editName"
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.planarName,
                          expression: "model.planarName"
                        }
                      ],
                      ref: "planarNameInput",
                      staticClass: "planarNameInput",
                      domProps: { value: _vm.model.planarName },
                      on: {
                        blur: _vm.changeName,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "planarName", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("div", [
                    _c("span", [_vm._v("尺寸:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.model.planarSize) + _vm._s(_vm.model.unit)
                      )
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "fileOperItem", on: { click: _vm.uploadPsd } },
                  [_vm._v("上传psd")]
                )
              ]),
              !_vm.admin
                ? _c("template", { slot: "reference" }, [
                    _c("div", { staticClass: "file-btn" }, [
                      _c("span", { staticClass: "file-text" }, [
                        _vm._v("文件")
                      ]),
                      _c("i", { staticClass: "el-icon-arrow-down" })
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("div", { staticClass: "operation-way" }, [
        _c("div", { staticClass: "way-show" }, [
          _c(
            "div",
            {
              class: [
                { editAbled: _vm.revokedAbled, unEditAbled: !_vm.revokedAbled }
              ],
              on: { click: _vm.back }
            },
            [
              _c("i", {
                staticClass: "iconfont icon-7chexiao icon-size",
                staticStyle: { cursor: "pointer" }
              }),
              _c("p", { staticClass: "undo" }, [_vm._v("撤销")])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "redo-btn",
              class: [
                { editAbled: _vm.redoAbled, unEditAbled: !_vm.redoAbled }
              ],
              on: { click: _vm.next }
            },
            [
              _c("i", {
                staticClass: "iconfont icon-fanchexiao icon-size",
                staticStyle: { cursor: "pointer" }
              }),
              _c("p", { staticClass: "redo" }, [_vm._v("重做")])
            ]
          ),
          _c("div", { staticClass: "editButtonList" }, [
            _c(
              "div",
              {
                class: [
                  { editAbled: _vm.drapAbled, unEditAbled: !_vm.drapAbled },
                  "btn-item"
                ],
                on: {
                  click: function($event) {
                    _vm.showLayers = true
                  },
                  mouseleave: function($event) {
                    _vm.showLayers = false
                  }
                }
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "调整图层",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("div", { staticClass: "inline-block" }, [
                      _c("i", {
                        staticClass: "iconfont icon-tuceng icon-size"
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showLayers,
                              expression: "!showLayers"
                            }
                          ],
                          staticClass: "way-btn",
                          staticStyle: { cursor: "pointer" }
                        },
                        [
                          _vm._v(" 调整图层 "),
                          _c("i", { staticClass: "el-icon-arrow-down" })
                        ]
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showLayers,
                        expression: "showLayers"
                      }
                    ],
                    staticClass: "way-btn"
                  },
                  [
                    _vm._v(" 调整图层 "),
                    _c("i", { staticClass: "el-icon-arrow-down" })
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showLayers,
                        expression: "showLayers"
                      }
                    ],
                    staticClass: "layers"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "layer-item",
                        on: {
                          click: function($event) {
                            return _vm.adjustLayers("up")
                          }
                        }
                      },
                      [_vm._v("上移一层")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "layer-item",
                        on: {
                          click: function($event) {
                            return _vm.adjustLayers("down")
                          }
                        }
                      },
                      [_vm._v(" 下移一层 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "layer-item",
                        on: {
                          click: function($event) {
                            return _vm.adjustLayers("top")
                          }
                        }
                      },
                      [_vm._v(" 置顶图层 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "layer-item",
                        on: {
                          click: function($event) {
                            return _vm.adjustLayers("bottom")
                          }
                        }
                      },
                      [_vm._v(" 置底图层 ")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                class: [
                  { editAbled: _vm.drapAbled, unEditAbled: !_vm.drapAbled },
                  "btn-item"
                ],
                on: {
                  click: function($event) {
                    _vm.showAlign = true
                  },
                  mouseleave: function($event) {
                    _vm.showAlign = false
                  }
                }
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "对齐分布",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("div", { staticClass: "inline-block" }, [
                      _c("i", {
                        staticClass: "iconfont icon-align-left icon-size"
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showAlign,
                              expression: "!showAlign"
                            }
                          ],
                          staticClass: "way-btn",
                          staticStyle: { cursor: "pointer" }
                        },
                        [
                          _vm._v(" 对齐分布 "),
                          _c("i", { staticClass: "el-icon-arrow-down" })
                        ]
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAlign,
                        expression: "showAlign"
                      }
                    ],
                    staticClass: "way-btn"
                  },
                  [
                    _vm._v(" 对齐分布 "),
                    _c("i", { staticClass: "el-icon-arrow-down" })
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAlign,
                        expression: "showAlign"
                      }
                    ],
                    staticClass: "aligns"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "align-item",
                        on: {
                          click: function($event) {
                            return _vm.align("left")
                          }
                        }
                      },
                      [_vm._v("左对齐")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "align-item",
                        on: {
                          click: function($event) {
                            return _vm.align("top")
                          }
                        }
                      },
                      [_vm._v("顶对齐")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "align-item",
                        on: {
                          click: function($event) {
                            return _vm.align("vcenter")
                          }
                        }
                      },
                      [_vm._v("垂直居中")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "align-item",
                        on: {
                          click: function($event) {
                            return _vm.align("hcenter")
                          }
                        }
                      },
                      [_vm._v("水平居中")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "align-item",
                        on: {
                          click: function($event) {
                            return _vm.align("right")
                          }
                        }
                      },
                      [_vm._v("右对齐")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "align-item",
                        on: {
                          click: function($event) {
                            return _vm.align("bottom")
                          }
                        }
                      },
                      [_vm._v("底对齐")]
                    ),
                    _c("div", { staticClass: "divide" }),
                    _vm.distribution
                      ? _c(
                          "div",
                          {
                            staticClass: "align-item",
                            on: {
                              click: function($event) {
                                return _vm.align("horizontaldistribution")
                              }
                            }
                          },
                          [_vm._v(" 水平分布 ")]
                        )
                      : _vm._e(),
                    _vm.distribution
                      ? _c(
                          "div",
                          {
                            staticClass: "align-item",
                            on: {
                              click: function($event) {
                                return _vm.align("verticaldistribution")
                              }
                            }
                          },
                          [_vm._v(" 垂直分布 ")]
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                class: [
                  { editAbled: _vm.lockAbled, unEditAbled: !_vm.lockAbled },
                  "btn-item"
                ],
                on: { click: _vm.lockModule }
              },
              [
                _c(
                  "el-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.editModule.lock,
                        expression: "!editModule.lock"
                      }
                    ],
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "锁定",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("div", { staticClass: "inline-block" }, [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.editModule.lock,
                            expression: "!editModule.lock"
                          }
                        ],
                        staticClass: "el-icon-unlock icon-size"
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "way-btn",
                          staticStyle: { cursor: "pointer" }
                        },
                        [_vm._v("锁定")]
                      )
                    ])
                  ]
                ),
                _c(
                  "el-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editModule.lock,
                        expression: "editModule.lock"
                      }
                    ],
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "解锁",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("div", { staticClass: "inline-block" }, [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editModule.lock,
                            expression: "editModule.lock"
                          }
                        ],
                        staticClass: "el-icon-lock icon-size"
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "way-btn",
                          staticStyle: { cursor: "pointer" }
                        },
                        [_vm._v("解锁")]
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                class: [
                  { editAbled: _vm.drapAbled, unEditAbled: !_vm.drapAbled },
                  "btn-item"
                ],
                on: { click: _vm.deleteModule }
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "删除",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("div", { staticClass: "inline-block" }, [
                      _c("i", { staticClass: "el-icon-delete icon-size" }),
                      _c(
                        "div",
                        {
                          staticClass: "way-btn",
                          staticStyle: { cursor: "pointer" }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                class: [
                  { editAbled: _vm.canCopy, unEditAbled: !_vm.canCopy },
                  "btn-item"
                ],
                on: { click: _vm.copyOper }
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "复制",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("div", { staticClass: "inline-block" }, [
                      _c("i", {
                        staticClass: "el-icon-document-copy icon-size"
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "way-btn",
                          staticStyle: { cursor: "pointer" }
                        },
                        [_vm._v("复制")]
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "right-button-list" }, [
        _c(
          "div",
          { staticClass: "save" },
          [
            _c("div", { staticClass: "save-btn", on: { click: _vm.save } }, [
              _c("i", { staticClass: "iconfont icon-166991 icon-size" }),
              _c("div", { staticClass: "save-text" }, [_vm._v("保存")])
            ]),
            _c(
              "el-popover",
              {
                attrs: { placement: "bottom", width: "300", trigger: "click" },
                model: {
                  value: _vm.visible,
                  callback: function($$v) {
                    _vm.visible = $$v
                  },
                  expression: "visible"
                }
              },
              [
                _c("div", { staticClass: "title-h4" }, [_vm._v("下载格式")]),
                _c(
                  "div",
                  { staticClass: "selecteArea" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                !_vm.free && !_vm.limitedTimeFree && _vm.purchased == false
                  ? _c("div", { staticClass: "title-h4" }, [
                      _vm._v(" 下载方式 ")
                    ])
                  : _vm._e(),
                !_vm.free && !_vm.limitedTimeFree && _vm.purchased == false
                  ? _c(
                      "div",
                      { staticClass: "radio-area" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.downLoadWay,
                              callback: function($$v) {
                                _vm.downLoadWay = $$v
                              },
                              expression: "downLoadWay"
                            }
                          },
                          [_vm._v("付费无水印下载")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value: _vm.downLoadWay,
                              callback: function($$v) {
                                _vm.downLoadWay = $$v
                              },
                              expression: "downLoadWay"
                            }
                          },
                          [_vm._v("免费有水印下载")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.purchased == false && _vm.visible
                  ? _c(
                      "div",
                      [
                        _vm.downLoadWay == 1 &&
                        !_vm.free &&
                        !_vm.limitedTimeFree
                          ? _c("div", { staticClass: "fee-info-area" }, [
                              _c("div", { staticClass: "fee-info" }, [
                                _c("div", { staticClass: "info-desc" }, [
                                  _vm._v("模板金额")
                                ]),
                                _c("div", { staticClass: "info-value" }, [
                                  _vm._v(_vm._s(_vm.salePrice) + "元")
                                ])
                              ]),
                              _c("div", { staticClass: "totle-info" }, [
                                _c("div", { staticClass: "info-desc" }, [
                                  _vm._v("实付金额")
                                ]),
                                _c("div", { staticClass: "info-value" }, [
                                  _vm._v(_vm._s(_vm.salePrice) + "元")
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.downLoadWay == 1 &&
                        !_vm.free &&
                        !_vm.limitedTimeFree
                          ? _c("model-pay-code", {
                              attrs: {
                                sourcePlanarId: _vm.model.sourcePlanarId,
                                orderNo: _vm.orderNo
                              },
                              on: { finishPay: _vm.downLoad }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.downLoadWay == 2 ||
                _vm.free ||
                _vm.limitedTimeFree ||
                _vm.purchased
                  ? _c("div", { staticClass: "downLoadButtonArea" }, [
                      _c(
                        "div",
                        {
                          staticClass: "downLoadButton",
                          on: { click: _vm.downLoad }
                        },
                        [_vm._v("确定")]
                      )
                    ])
                  : _vm._e(),
                !_vm.admin
                  ? _c(
                      "div",
                      {
                        staticClass: "download-btn",
                        attrs: { slot: "reference" },
                        on: { click: _vm.searchFeeInfo },
                        slot: "reference"
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-xiazai icon-size"
                        }),
                        _c("div", { staticClass: "down-text" }, [
                          _vm._v("下载")
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            )
          ],
          1
        ),
        _vm.model.printFlag == 1 && !_vm.admin
          ? _c(
              "div",
              { staticClass: "printing", on: { click: _vm.printButton } },
              [
                _c("i", { staticClass: "el-icon-printer white" }),
                _c("span", { staticClass: "printing-text" }, [_vm._v("印刷")])
              ]
            )
          : _vm._e()
      ]),
      _c("downLoad", {
        ref: "downLoad",
        attrs: { type: _vm.value, id: _vm.model.id }
      }),
      _c("image-canvas", { ref: "imageList", attrs: { id: _vm.model.id } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }