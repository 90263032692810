<template>
  <div
    ref="post"
    @click.stop
    class="canvas-area"
    :style="{
      backgroundColor: `#fff`,
      backgroundSize: `${1600 / scale}px ${1600 / scale}px`,
      backgroundPosition: `0 0,${800 / scale}px ${800 / scale}px`,
      borderWidth: `${(80 / scale) * 100}px ${(80 / scale) * 100}px ${
        (80 / scale) * 100
      }px ${(80 / scale) * 100}px`,
      backgroundImage: `linear-gradient(to top right,#ccc 25%,transparent 0,transparent 75%,#ccc 0,#ccc),linear-gradient(to top right,#ccc 25%,transparent 0,transparent 75%,#ccc 0,#ccc)`,
      width: postInfo.canvas.width + 'px',
      height: postInfo.canvas.height + 'px',
      transition: `${anime ? 0.5 : 0}s`,
      transform: `scale(${scale / 100},${scale / 100}) `,
      left: `${canvasLeft}px`,
      top: `${canvasTop}px`,
    }"
  >
    <div
      class="background"
      :style="{ background: postInfo.background.backgroundColor }"
    >
      <img
        v-if="postInfo.background.backgroundImage"
        :src="backImageSrc"
        :style="{
          width: `${postInfo.background.width}px`,
          height: `${postInfo.background.height}px`,
          top: `-${postInfo.background.imageInfo.backgroundPositionY}px`,
          left: `-${postInfo.background.imageInfo.backgroundPositionX}px`,
        }"
      />
    </div>

    <d-image
      v-for="item in postInfo.images"
      :image="item"
      :key="item.imageId"
      :display="true"
    ></d-image>
    <d-text
      v-for="item in postInfo.texts"
      :textInfo="item"
      :key="item.textId"
      :display="true"
    ></d-text>
    <d-code
      v-for="item in postInfo.codes"
      :code="item"
      :key="item.codeId"
      :display="true"
    ></d-code>
    <!-- {{postInfo.texts}} -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DImage from '../operation/DImage.vue'
import DText from '../operation/DText.vue'
import DCode from '../operation/DCode.vue'
import panel from './common/panel'
export default {
  data () {
    return {
    }
  },
  props: {
    cropImage: {
      type: Boolean,
      default: false
    },
    anime: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DImage,
    DText,
    DCode
  },
  computed: {
    ...mapState('edit', ['scale', 'postInfo', 'editModuleList', 'editSize']),
    backImageSrc () {
      return this.postInfo.background.backgroundImage.replace('https://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com', 'http://cube-test-1257418739.file.myqcloud.com')
    },
    canvasTop: panel.canvasTop,
    canvasLeft: panel.canvasLeft
  }

}
</script>

<style lang="scss" scoped>
.canvas-area {
  position: absolute;
  transform-origin: left top;
  overflow: hidden;
  box-sizing: content-box;
  border-style: solid;
  border-color: #f4f4fb;
  .background {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      position: absolute;
    }
  }
}
</style>
