<template>
  <div class="post-list">
    <!-- "canvas":{"width":720,"height":1280}, -->
    <div
      class="postItemList"
      v-for="(item, index) in postList"
      :key="index"
      @click="selectePost(index)"
    >
      <i
        class="el-icon-close delete"
        v-if="postList.length != 1 && !admin"
        @click.stop="deletePost(index)"
      ></i>
      <post-item :postItemWidth="130" :postInfo="item"></post-item>
    </div>

    <div v-if="showAdd">
      <el-button style="width: 100%" @click="createNewPost"
        ><i class="el-icon-plus"></i
      ></el-button>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import PostItem from './PostItem.vue'
import config from '@/config'
export default {
  props: {
    max: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      admin: config.admin
    }
  },
  mounted () {
  },
  components: {
    PostItem
  },
  methods: {
    ...mapMutations('edit', ['createPost', 'selectPostByIndex', 'pushHistoryRecord', 'setEditModule', 'deletePostByIndex', 'setBackEditModule']),
    /**
     * 删除页面
     */
    deletePost (index) {
      this.$confirm('确定要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deletePostByIndex(index)
        this.setBackEditModule()
        this.pushHistoryRecord()
      }).catch(() => {
      })
    },
    /**
     * 选择页面
     */
    selectePost (index) {
      this.selectPostByIndex(index)
      this.setBackEditModule()
    },
    /**
     * 创建新页面
     */
    createNewPost () {
      this.createPost()
      this.pushHistoryRecord()
      this.setBackEditModule()
    }
  },
  computed: {
    ...mapState('edit', ['postList', 'postInfo']),
    showAdd () {
      // eslint-disable-next-line eqeqeq
      if (this.max == 0) {
        return true
      }
      if (this.postList.length < this.max) {
        return true
      }
      return false
    }
  }

}
</script>

<style lang="scss" scoped>
.post-list {
  right: 420px;
  top: 100px;
  width: 130px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  z-index: 999;
}
.postItemList {
  display: inline-block;
  position: relative;
}
.delete {
  position: absolute;
  right: 10px;
  color: rgb(0, 0, 0);
  top: 2px;
  z-index: 999;
  cursor: pointer;
  background-color: white;
  border-radius: 2px;
  font-weight: bolder;
}
</style>
