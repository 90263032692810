<template>
  <div
    class="InputNumber"
    :style="{
      border: `${border ? '2px solid #303235' : ''}`,
    }"
  >
    <div class="InputNumber-input">
      <input type="number" class="input" :value="value" @change="changeEvent" />
    </div>
    <div class="tip">{{ tip }}</div>
    <div
      class="button-area"
      :style="{
        borderLeft: `${division ? '2px solid #2468F2' : ''}`,
      }"
    >
      <div class="item">
        <i class="el-icon-caret-top" @click="add"></i>
      </div>
      <div class="item">
        <i class="el-icon-caret-bottom" @click="reduce"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    value: {
      type: Number,
      default: 0

    },
    max: {
      type: Number,
      default: Number.MAX_VALUE
    },
    min: {
      type: Number,
      default: -100000
    },
    tip: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: false
    },
    division: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue () {
      return this.value
    }
  },
  methods: {
    add () {
      if (this.value < this.max) {
        this.$emit('input', this.inputValue + 1)
        this.$emit('change', this.inputValue)
      }
    },
    reduce () {
      if (this.value > this.min) {
        this.$emit('input', this.inputValue - 1)
        this.$emit('change', this.inputValue)
      }
    },
    changeEvent () {
      let value = Number(event.target.value)
      console.log(value)
      if (value < this.min) {
        value = this.min
      }
      if (value > this.max) {
        value = this.max
      }
      event.target.value = value
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }

}
</script>

<style lang="scss" scoped>
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.InputNumber {
  width: 100%;
  height: 100%;
  background: #1d1e23;
  // border: 2px solid #303235;
  border-radius: 4px;
  position: relative;
  .InputNumber-input {
    position: absolute;
    left: 2px;
    top: 0;
    right: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 1px;
    .input {
      background: none;
      border: none;
      outline: none;
      color: rgb(255, 255, 255);
      height: 100%;
      width: 100%;
      line-height: 50px;
    }
  }
  .tip {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 27px;
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  .button-area {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;

    .item {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      cursor: pointer;
      i {
        font-size: 12px;
        transform: scale(0.8, 0.8);
      }
    }
  }
}
</style>
