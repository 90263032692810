var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "InputNumber" }, [
    _c("div", { staticClass: "text-input" }, [
      _c(
        "div",
        {
          staticClass: "selected-font",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.showFontList = true
            }
          }
        },
        [
          !_vm.fontInfo.img
            ? [_vm._v(" " + _vm._s(_vm.fontInfo.name) + " ")]
            : [
                _c("div", { staticClass: "selectedBack" }, [
                  _c("img", { attrs: { src: _vm.fontInfo.img } })
                ])
              ],
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFontList,
                  expression: "showFontList"
                }
              ],
              staticClass: "font-type-area"
            },
            [
              _c("div", { staticClass: "font-type" }, [
                _c(
                  "div",
                  {
                    class: ["font-button", { active: _vm.active == "ch" }],
                    on: {
                      click: function($event) {
                        _vm.active = "ch"
                      }
                    }
                  },
                  [_vm._v(" 中文字体 ")]
                ),
                _c(
                  "div",
                  {
                    class: ["font-button", { active: _vm.active == "en" }],
                    on: {
                      click: function($event) {
                        _vm.active = "en"
                      }
                    }
                  },
                  [_vm._v(" 英文字体 ")]
                )
              ])
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFontList,
                  expression: "showFontList"
                }
              ],
              staticClass: "font-list"
            },
            [
              _vm._l(_vm.fontList, function(item, index) {
                return [
                  item.type == _vm.active
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass: "font-item",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.selectFont(item, index)
                            }
                          }
                        },
                        [
                          !item.img
                            ? [_vm._v(" " + _vm._s(item.name) + " ")]
                            : [_c("img", { attrs: { src: item.img } })]
                        ],
                        2
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        2
      ),
      _c("i", {
        staticClass: "el-icon-arrow-right arrow",
        style: {
          transform: "rotate(" + (_vm.showFontList ? 0 : 90) + "deg)"
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.showFontList = !_vm.showFontList
          }
        }
      })
    ]),
    _c("div", { staticClass: "tip" }, [_vm._v("字体")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }