/* eslint-disable indent */
<template>
  <div class="edit-head" @keydown.stop>
    <div class="logo-text" @click="toIndex">
      <img class="logo-img" src="@/assets/img/editLogo.png" />
    </div>
    <!-- 文件选择 -->
    <div class="file">
      <el-popover
        style="cursor: pointer"
        placement="bottom"
        width="100"
        trigger="click"
        show-timeout="0"
      >
        <div>
          <div class="modelInfo">
            <div>
              <div class="planarName">
                <div class="name">
                  <span v-show="!editName">{{ model.planarName }}</span>
                </div>

                <i
                  v-show="!editName"
                  class="el-icon-edit-outline"
                  @click="editPlanarName"
                ></i>
              </div>

              <input
                v-show="editName"
                ref="planarNameInput"
                class="planarNameInput"
                @blur="changeName"
                v-model="model.planarName"
              />
            </div>
            <div>
              <span>尺寸:</span>
              <span>{{ model.planarSize }}{{ model.unit }}</span>
            </div>
          </div>
          <div class="fileOperItem" @click="uploadPsd">上传psd</div>
        </div>
        <template slot="reference" v-if="!admin">
          <div class="file-btn">
            <span class="file-text">文件</span>
            <i class="el-icon-arrow-down"></i>
          </div>
        </template>
      </el-popover>
    </div>
    <!-- 撤销、头部编辑等操作 -->
    <div class="operation-way">
      <div class="way-show">
        <div
          @click="back"
          :class="[{ editAbled: revokedAbled, unEditAbled: !revokedAbled }]"
        >
          <i
            class="iconfont icon-7chexiao icon-size"
            style="cursor: pointer"
          ></i>
          <p class="undo">撤销</p>
        </div>
        <div
          class="redo-btn"
          @click="next"
          :class="[{ editAbled: redoAbled, unEditAbled: !redoAbled }]"
        >
          <i
            class="iconfont icon-fanchexiao icon-size"
            style="cursor: pointer"
          ></i>
          <p class="redo">重做</p>
        </div>
        <div class="editButtonList">
          <div
            :class="[
              { editAbled: drapAbled, unEditAbled: !drapAbled },
              'btn-item',
            ]"
            @click="showLayers = true"
            @mouseleave="showLayers = false"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="调整图层"
              placement="bottom"
            >
              <div class="inline-block">
                <i class="iconfont icon-tuceng icon-size"></i>
                <div
                  class="way-btn"
                  v-show="!showLayers"
                  style="cursor: pointer"
                >
                  调整图层
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
            </el-tooltip>
            <div class="way-btn" v-show="showLayers">
              调整图层
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="layers" v-show="showLayers">
              <div class="layer-item" @click="adjustLayers('up')">上移一层</div>
              <div class="layer-item" @click="adjustLayers('down')">
                下移一层
              </div>
              <div class="layer-item" @click="adjustLayers('top')">
                置顶图层
              </div>
              <div class="layer-item" @click="adjustLayers('bottom')">
                置底图层
              </div>
            </div>
          </div>
          <div
            :class="[
              { editAbled: drapAbled, unEditAbled: !drapAbled },
              'btn-item',
            ]"
            @click="showAlign = true"
            @mouseleave="showAlign = false"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="对齐分布"
              placement="bottom"
            >
              <div class="inline-block">
                <i class="iconfont icon-align-left icon-size"></i>
                <div
                  class="way-btn"
                  v-show="!showAlign"
                  style="cursor: pointer"
                >
                  对齐分布
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
            </el-tooltip>
            <div class="way-btn" v-show="showAlign">
              对齐分布
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="aligns" v-show="showAlign">
              <div class="align-item" @click="align('left')">左对齐</div>
              <div class="align-item" @click="align('top')">顶对齐</div>
              <div class="align-item" @click="align('vcenter')">垂直居中</div>
              <div class="align-item" @click="align('hcenter')">水平居中</div>
              <div class="align-item" @click="align('right')">右对齐</div>
              <div class="align-item" @click="align('bottom')">底对齐</div>
              <div class="divide"></div>
              <div
                class="align-item"
                v-if="distribution"
                @click="align('horizontaldistribution')"
              >
                水平分布
              </div>
              <div
                class="align-item"
                v-if="distribution"
                @click="align('verticaldistribution')"
              >
                垂直分布
              </div>
            </div>
          </div>
          <div
            :class="[
              { editAbled: lockAbled, unEditAbled: !lockAbled },
              'btn-item',
            ]"
            @click="lockModule"
          >
            <!-- <i v-show='editModule.lock' class="el-icon-lock icon-size"></i> -->
            <!-- <i v-show='!editModule.lock' class="el-icon-unlock icon-size"></i> -->
            <el-tooltip
              v-show="!editModule.lock"
              class="item"
              effect="dark"
              content="锁定"
              placement="bottom"
            >
              <div class="inline-block">
                <i
                  v-show="!editModule.lock"
                  class="el-icon-unlock icon-size"
                ></i>
                <div class="way-btn" style="cursor: pointer">锁定</div>
              </div>
            </el-tooltip>
            <el-tooltip
              v-show="editModule.lock"
              class="item"
              effect="dark"
              content="解锁"
              placement="bottom"
            >
              <div class="inline-block">
                <i v-show="editModule.lock" class="el-icon-lock icon-size"></i>
                <div class="way-btn" style="cursor: pointer">解锁</div>
              </div>
            </el-tooltip>
          </div>
          <div
            :class="[
              { editAbled: drapAbled, unEditAbled: !drapAbled },
              'btn-item',
            ]"
            @click="deleteModule"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <div class="inline-block">
                <i class="el-icon-delete icon-size"></i>
                <div class="way-btn" style="cursor: pointer">删除</div>
              </div>
            </el-tooltip>
          </div>
          <div
            :class="[{ editAbled: canCopy, unEditAbled: !canCopy }, 'btn-item']"
            @click="copyOper"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="复制"
              placement="bottom"
            >
              <div class="inline-block">
                <i class="el-icon-document-copy icon-size"></i>
                <div class="way-btn" style="cursor: pointer">复制</div>
              </div>
            </el-tooltip>
          </div>
          <!-- <div
            :class="[
              { editAbled: canPaste, unEditAbled: !canPaste },
              'btn-item',
            ]"
            @click="paste"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="粘贴"
              placement="bottom"
            >
              <div class="inline-block">
                <i class="el-icon-copy-document icon-size"></i>
                <div class="way-btn" style="cursor: pointer">粘贴</div>
              </div>
            </el-tooltip>
          </div> -->
        </div>
      </div>
    </div>
    <div class="right-button-list">
      <!-- 保存、下载 -->
      <!-- <el-button @click='getColor'>取色器测试</el-button> -->
      <div class="save">
        <div class="save-btn" @click="save">
          <i class="iconfont icon-166991 icon-size"></i>
          <div class="save-text">保存</div>
        </div>
        <el-popover
          placement="bottom"
          width="300"
          trigger="click"
          v-model="visible"
        >
          <div class="title-h4">下载格式</div>
          <div class="selecteArea">
            <el-select style="width: 100%" v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="title-h4"
            v-if="!free && !limitedTimeFree && purchased == false"
          >
            下载方式
          </div>
          <div
            class="radio-area"
            v-if="!free && !limitedTimeFree && purchased == false"
          >
            <el-radio v-model="downLoadWay" :label="1">付费无水印下载</el-radio>
            <el-radio v-model="downLoadWay" :label="2">免费有水印下载</el-radio>
          </div>
          <div v-if="purchased == false && visible">
            <div
              class="fee-info-area"
              v-if="downLoadWay == 1 && !free && !limitedTimeFree"
            >
              <div class="fee-info">
                <div class="info-desc">模板金额</div>
                <div class="info-value">{{ salePrice }}元</div>
              </div>
              <div class="totle-info">
                <div class="info-desc">实付金额</div>
                <div class="info-value">{{ salePrice }}元</div>
              </div>
            </div>
            <model-pay-code
              :sourcePlanarId="model.sourcePlanarId"
              :orderNo="orderNo"
              @finishPay="downLoad"
              v-if="downLoadWay == 1 && !free && !limitedTimeFree"
            ></model-pay-code>
          </div>

          <div
            class="downLoadButtonArea"
            v-if="downLoadWay == 2 || free || limitedTimeFree || purchased"
          >
            <div class="downLoadButton" @click="downLoad">确定</div>
          </div>
          <div
            slot="reference"
            class="download-btn"
            @click="searchFeeInfo"
            v-if="!admin"
          >
            <i class="iconfont icon-xiazai icon-size"></i>

            <div class="down-text">下载</div>
          </div>
        </el-popover>
      </div>
      <!-- 印刷 -->
      <div
        class="printing"
        @click="printButton"
        v-if="model.printFlag == 1 && !admin"
      >
        <i class="el-icon-printer white"></i>
        <span class="printing-text">印刷</span>
      </div>
    </div>
    <downLoad ref="downLoad" :type="value" :id="model.id"></downLoad>
    <image-canvas ref="imageList" :id="model.id"></image-canvas>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { planarjsonget, get as getModelImage, getPrice, checkUserHave } from '@/api/cubeclient'
import axios from 'axios'
import config from '@/config'
import downLoad from './common/downLoad.vue'
import ImageCanvas from './common/ImageCanvas.vue'
import ModelPayCode from './common/modelPayCode.vue'
export default {
  props: {
    model: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    }
  },
  data () {
    return {
      showLayers: false,
      showAlign: false,
      editName: false,
      admin: config.admin,
      showDownInfo: false,
      salePrice: 0,
      free: false,
      limitedTimeFree: false,
      purchased: false,
      orderNo: '',
      options: [{
        value: 'jpg',
        label: 'jpg'
      }, {
        value: 'png',
        label: 'png'
      }, {
        value: 'pdf',
        label: 'pdf'
      }],
      downLoadWay: 1,
      value: 'jpg',
      visible: false
    }
  },
  created () {

  },
  mounted () {

  },
  watch: {

  },
  components: {
    downLoad,
    ImageCanvas,
    ModelPayCode
  },
  computed: {
    ...mapState('edit', ['editModuleList', 'editModule', 'postList', 'group']),
    ...mapGetters('edit', ['revokedAbled', 'redoAbled', 'drapAbled', 'lockAbled', 'distribution', 'canPaste', 'canCopy']),
    isLogin () {
      if (localStorage.getItem('token')) {
        return true
      }
      return false
    }
  },
  methods: {
    getColor () {
    },
    toIndex () {
      if (config.admin) {
        return
      }
      this.$router.push({ name: 'Index' })
      // if (localStorage.getItem('token')) {
      //   drawCover({ id: this.model.id })
      // }
    },
    /**
     * 下载模板
     */
    async downLoad () {
      if (localStorage.getItem('token') == null) {
        this.$Login()
        setTimeout(() => {
          this.visible = false
        })
        return
      }

      if (this.downLoadWay == 1) {
        await this.$refs.imageList.createImage(false)
      } else {
        await this.$refs.imageList.createImage(true)
      }
      if (this.downLoadWay == 1) {
        this.$refs.downLoad.downLoad(false)
      } else {
        this.$refs.downLoad.downLoad(true)
      }
      this.visible = false
    },
    /**
     * 更改模板名
     */
    changeName () {
      this.editName = false
    },
    /**
     * 编辑模板名
     */
    editPlanarName () {
      this.editName = true
      this.$nextTick(() => {
        this.$refs.planarNameInput.focus()
      })
    },
    /**
     * 打印操作
     */
    async printButton () {
      if (localStorage.getItem('token') == null) {
        this.$Login()
        return
      }
      await this.$refs.imageList.createImage(false)
      this.loading = this.$loading({
        lock: true,
        text: '下载印刷材料',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const t = setInterval(async () => {
        const res = await getModelImage({ planarId: this.model.id })
        // eslint-disable-next-line eqeqeq
        if (res.data != '') {
          this.$bus.$emit('print', res.data)
          clearInterval(t)
          this.loading.close()
        }
      }, 1000)
    },
    ...mapMutations('edit', ['setPostInfo', 'removeModule', 'back', 'next', 'adjustLayers',
      'pushHistoryRecord', 'lock', 'setAlignOper', 'alignOper', 'addImage', 'addText',
      'pushLayers', 'loadFont', 'pushPost', 'selectLastPost', 'setBackEditModule', 'copy',
      'initAlignGroupSize', 'paste']),
    // 复制操作
    copyOper () {
      this.copy()
      this.paste()
    },
    adjustmentLayer (type) { // 调整图层
      this.adjustLayers(type)
      this.pushHistoryRecord()
    },
    deleteModule () { // 删除模块
      this.removeModule()
      this.pushHistoryRecord()
    },
    lockModule () { // 锁操作
      this.lock()
      this.pushHistoryRecord()
    },
    align (type) { // 对齐操作
      if (this.group && !this.group.id) {
        if (this.group.rotate !== 0) {
          this.group.rotate = 0
          this.initAlignGroupSize()
          this.$bus.$emit('initParameter')
        }
      }

      this.alignOper(type)

      if (this.group && !this.group.id) {
        this.initAlignGroupSize()
        this.$bus.$emit('initParameter')
      }
    },
    /**
     * 上传PSD
     */
    uploadPsd () {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.onchange = () => {
        if (!fileInput.files[0].name.endsWith('psd')) {
          this.$message.error('只能上传PSD文件')
          return
        }
        const file = fileInput.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        axios.post(`${process.env.VUE_APP_PSD}/newpsd/upload`, formData, config).then(
          (data) => {
            loading.close()
            // eslint-disable-next-line eqeqeq
            if (data.data.code == 0) {
              this.searchModel(data.data.data)
            } else {
              this.$message.error('上传异常')
            }
          }).catch(error => {
          loading.close()
          console.log(error)
          this.$message.error('上传异常')
        })
      }
      fileInput.click()
    },
    /**
     * 加载模板
     */
    searchModel (id) {
      let time = 0
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const t = setInterval(async () => {
        // eslint-disable-next-line eqeqeq
        if (time == 60) {
          clearInterval(t)
          loading.close()
          this.$message.error('加载异常')
        }
        const res = await planarjsonget({ id })
        if (res.data.psdJson != null) {
          loading.close()
          clearInterval(t)
          this.pushPost(JSON.parse(res.data.psdJson))
          this.loadFont()
          this.selectLastPost()
          this.setBackEditModule()
        }
        time++
      }, 1000)
    },
    // 保存
    async save () {
      if (localStorage.getItem('token') == null) {
        this.$Login()
        return
      }

      if (localStorage.getItem('token') && !this.admin) {
        this.$refs.imageList.createImage()
      } else {
        this.$bus.$emit('save')
      }
    },
    /**
     * 查找费用信息
     */
    async searchFeeInfo () {
      if (localStorage.getItem('token') == null) {
        this.$Login()
        setTimeout(() => {
          this.visible = false
        })
        return
      }

      const res = await getPrice({ id: this.model.id })
      if (res.code == 401) {
        this.visible = false
        return
      }
      this.salePrice = res.data.salePrice
      this.free = res.data.free
      this.limitedTimeFree = res.data.limitedTimeFree
      const checkRes = await checkUserHave({ id: this.model.sourcePlanarId })
      if (checkRes.data.orderNo) {
        this.orderNo = checkRes.data.orderNo
      }
      this.purchased = checkRes.data.purchased
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./EditHead.scss";
</style>
