var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v("图片")]),
    _c("div", { staticClass: "image-area", attrs: { align: "left" } }, [
      _c("div", { staticClass: "replace-img", on: { click: _vm.replaceImg } }, [
        _vm._v("替换图片")
      ])
    ]),
    _c("div", { staticClass: "title" }, [_vm._v("裁剪")]),
    _c("div", { staticClass: "image-area", attrs: { align: "left" } }, [
      _c(
        "div",
        {
          staticClass: "Tailoring-img",
          on: {
            click: function($event) {
              return _vm.setCropImage(true)
            }
          }
        },
        [_vm._v("自由裁剪")]
      )
    ]),
    _c("div", { staticClass: "space" }),
    _c("div", { staticClass: "spacing" }, [
      _c("div", { staticClass: "tips" }, [_vm._v("不透明度")]),
      _c(
        "div",
        { staticClass: "slider" },
        [
          _c("slider", {
            on: { change: _vm.debouncePushHistoryRecord },
            model: {
              value: _vm.editModule.opacity,
              callback: function($$v) {
                _vm.$set(_vm.editModule, "opacity", $$v)
              },
              expression: "editModule.opacity"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("input-number", {
            attrs: { min: 0, max: 100 },
            on: { change: _vm.debouncePushHistoryRecord },
            model: {
              value: _vm.editModule.opacity,
              callback: function($$v) {
                _vm.$set(_vm.editModule, "opacity", $$v)
              },
              expression: "editModule.opacity"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "spacing" }, [
      _c("div", { staticClass: "tips" }, [_vm._v("圆角")]),
      _c(
        "div",
        { staticClass: "slider" },
        [
          _c("slider", {
            attrs: { "show-tooltip": false, min: 0, max: 50 },
            on: { change: _vm.debouncePushHistoryRecord },
            model: {
              value: _vm.editModule.borderRadius,
              callback: function($$v) {
                _vm.$set(_vm.editModule, "borderRadius", $$v)
              },
              expression: "editModule.borderRadius"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("input-number", {
            attrs: { min: 0, max: 50, tip: "%" },
            on: { change: _vm.debouncePushHistoryRecord },
            model: {
              value: _vm.editModule.borderRadius,
              callback: function($$v) {
                _vm.$set(_vm.editModule, "borderRadius", $$v)
              },
              expression: "editModule.borderRadius"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "shadow" }, [
      _c("div", {
        class: [_vm.editModule.boxShadow ? "active" : "", "shadow-button"],
        on: {
          click: function($event) {
            _vm.editModule.boxShadow = !_vm.editModule.boxShadow
          }
        }
      }),
      _c("div", { staticClass: "tips" }, [_vm._v("阴影")])
    ]),
    _vm.editModule.boxShadow
      ? _c("div", [
          _c("div", { staticClass: "color-control" }, [
            _c("div", { staticClass: "tips" }, [_vm._v("颜色")]),
            _c("div", {
              staticClass: "showColor",
              style: { background: _vm.editModule.shadow.color },
              on: {
                click: function($event) {
                  return _vm.$refs.colorPicker.handleTrigger()
                }
              }
            }),
            _c(
              "div",
              { staticClass: "color-button" },
              [
                _c("color-picker", {
                  ref: "colorPicker",
                  attrs: { "show-alpha": "", size: "mini" },
                  model: {
                    value: _vm.editModule.shadow.color,
                    callback: function($$v) {
                      _vm.$set(_vm.editModule.shadow, "color", $$v)
                    },
                    expression: "editModule.shadow.color"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "spacing" }, [
            _c("div", { staticClass: "tips" }, [_vm._v("水平位置")]),
            _c(
              "div",
              { staticClass: "slider" },
              [
                _c("slider", {
                  attrs: { min: -100 },
                  on: { change: _vm.debouncePushHistoryRecord },
                  model: {
                    value: _vm.editModule.shadow.hShadow,
                    callback: function($$v) {
                      _vm.$set(_vm.editModule.shadow, "hShadow", $$v)
                    },
                    expression: "editModule.shadow.hShadow"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("input-number", {
                  attrs: { max: 100 },
                  on: { change: _vm.debouncePushHistoryRecord },
                  model: {
                    value: _vm.editModule.shadow.hShadow,
                    callback: function($$v) {
                      _vm.$set(_vm.editModule.shadow, "hShadow", $$v)
                    },
                    expression: "editModule.shadow.hShadow"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "spacing" }, [
            _c("div", { staticClass: "tips" }, [_vm._v("垂直位置")]),
            _c(
              "div",
              { staticClass: "slider" },
              [
                _c("slider", {
                  attrs: { "show-tooltip": false, min: -100 },
                  on: { change: _vm.debouncePushHistoryRecord },
                  model: {
                    value: _vm.editModule.shadow.vShadow,
                    callback: function($$v) {
                      _vm.$set(_vm.editModule.shadow, "vShadow", $$v)
                    },
                    expression: "editModule.shadow.vShadow"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("input-number", {
                  attrs: { max: 100 },
                  on: { change: _vm.debouncePushHistoryRecord },
                  model: {
                    value: _vm.editModule.shadow.vShadow,
                    callback: function($$v) {
                      _vm.$set(_vm.editModule.shadow, "vShadow", $$v)
                    },
                    expression: "editModule.shadow.vShadow"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "spacing" }, [
            _c("div", { staticClass: "tips" }, [_vm._v("模糊距离")]),
            _c(
              "div",
              { staticClass: "slider" },
              [
                _c("slider", {
                  attrs: { "show-tooltip": false },
                  on: { change: _vm.debouncePushHistoryRecord },
                  model: {
                    value: _vm.editModule.shadow.blur,
                    callback: function($$v) {
                      _vm.$set(_vm.editModule.shadow, "blur", $$v)
                    },
                    expression: "editModule.shadow.blur"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("input-number", {
                  attrs: { max: 100 },
                  on: { change: _vm.debouncePushHistoryRecord },
                  model: {
                    value: _vm.editModule.shadow.blur,
                    callback: function($$v) {
                      _vm.$set(_vm.editModule.shadow, "blur", $$v)
                    },
                    expression: "editModule.shadow.blur"
                  }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "space" }),
    _c("div", { staticClass: "position" }, [
      _c("div", { staticClass: "title" }, [_vm._v("位置尺寸")]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "tips" }, [_vm._v("位置")]),
        _c("div", { staticClass: "input" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("input-number", {
                attrs: { division: true, tip: "X" },
                on: { change: _vm.positionChange },
                model: {
                  value: _vm.editModuleLeft,
                  callback: function($$v) {
                    _vm.editModuleLeft = $$v
                  },
                  expression: "editModuleLeft"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "connect" }),
        _c("div", { staticClass: "input" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("input-number", {
                attrs: { division: true, tip: "Y" },
                on: { change: _vm.positionChange },
                model: {
                  value: _vm.editModuleTop,
                  callback: function($$v) {
                    _vm.editModuleTop = $$v
                  },
                  expression: "editModuleTop"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "tips" }, [_vm._v("尺寸")]),
        _c("div", { staticClass: "input" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("input-number", {
                attrs: { division: true, min: 40, tip: "宽" },
                on: { change: _vm.resetWidth },
                model: {
                  value: _vm.editModuleWidth,
                  callback: function($$v) {
                    _vm.editModuleWidth = $$v
                  },
                  expression: "editModuleWidth"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "connect" }, [
          !_vm.lock
            ? _c("i", {
                staticClass: "el-icon-link link",
                on: { click: _vm.lockActive }
              })
            : _vm._e(),
          _vm.lock
            ? _c("i", {
                staticClass: "el-icon-link active",
                on: {
                  click: function($event) {
                    _vm.lock = false
                  }
                }
              })
            : _vm._e()
        ]),
        _c("div", { staticClass: "input" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("input-number", {
                attrs: { division: true, min: 80, tip: "高" },
                on: { change: _vm.resetHeight },
                model: {
                  value: _vm.editModuleHeight,
                  callback: function($$v) {
                    _vm.editModuleHeight = $$v
                  },
                  expression: "editModuleHeight"
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }