<template>
  <div class="InputNumber">
    <div class="text-input">
      <div class="selected-font" @click.stop="showFontList = true">
        <template v-if="!fontInfo.img">
          {{ fontInfo.name }}
        </template>
        <template v-else>
          <div class="selectedBack">
            <img :src="fontInfo.img" />
          </div>
        </template>
        <div class="font-type-area" v-show="showFontList">
          <div class="font-type">
            <div
              :class="['font-button', { active: active == 'ch' }]"
              @click="active = 'ch'"
            >
              中文字体
            </div>
            <div
              :class="['font-button', { active: active == 'en' }]"
              @click="active = 'en'"
            >
              英文字体
            </div>
          </div>
        </div>

        <div class="font-list" v-show="showFontList">
          <template v-for="(item, index) in fontList">
            <div
              class="font-item"
              @click.stop="selectFont(item, index)"
              v-if="item.type == active"
              :key="index"
            >
              <template v-if="!item.img">
                {{ item.name }}
              </template>
              <template v-else>
                <img :src="item.img" />
              </template>
            </div>
          </template>
        </div>
      </div>
      <i
        class="el-icon-arrow-right arrow"
        @click.stop="showFontList = !showFontList"
        :style="{
          transform: `rotate(${showFontList ? 0 : 90}deg)`,
        }"
      ></i>
    </div>
    <div class="tip">字体</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import fontList from '@/lib/fontList'
export default {
  data () {
    return {
      showFontList: false,
      fontList: fontList,
      fontMap: {},
      active: 'ch'
    }
  },
  props: {
  },
  created () {
    document.addEventListener('click', this.closeSelect)
    this.fontList.forEach(e => {
      this.fontMap[e.fontFamily] = e
    })
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeSelect)
  },
  computed: {
    ...mapState('edit', ['editModule']),
    fontInfo () {
      // console.log(this.fontMap)
      return this.fontMap[this.editModule.fontFamily]
    }
  },
  methods: {
    closeSelect () {
      this.showFontList = false
    },
    async selectFont (item) {
      this.editModule.fontFamily = item.fontFamily
      this.editModule.fontUrl = item.url
      this.showFontList = false
      if (item.load) {
        return
      }
      const fullname = item.fontFamily
      const style = document.createElement('style')
      style.type = 'text/css'
      style.innerText = '@font-face {font-family:' + fullname + ';src:url(' + item.url + ')};font-display: swap'
      document.getElementsByTagName('head')[0].appendChild(style)
      item.load = true
    }

  }

}
</script>

<style lang="scss" scoped>
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.InputNumber {
  width: 100%;
  height: 100%;
  background: #1d1e23;
  border: 2px solid #303235;
  border-radius: 4px;
  position: relative;
  .text-input {
    position: absolute;
    left: 2px;
    top: 0;
    right: 30px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .selected-font {
    width: 90%;
    height: 100%;
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 5px;
    cursor: pointer;
    // justify-content: center;
    .font-list {
      position: absolute;
      left: 0;
      top: 100%;
      width: 300px;
      z-index: 2000;
      padding: 5px;
      max-height: 300px;
      overflow-y: scroll;
      background-color: white;
      cursor: pointer;
      text-align: center;
      padding-top: 50px;
      .font-item {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: left;
        background-color: white;
        color: black;
        &:hover {
          background-color: rgb(211, 211, 211);
        }
      }
    }
  }
  .arrow {
    color: white;
    cursor: pointer;
    transition: 0.2s;
  }
  .tip {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
.selectedBack {
  display: inline-block;
  height: 40px;
  width: 100%;
  -webkit-filter: invert(100%); /* Chrome, Safari, Opera */
  filter: invert(100%);
  img {
    height: 40px;
  }
}
.font-type-area {
  position: absolute;
  left: 0px;
  top: 100%;
  background-color: white;
  z-index: 2001;
  width: 300px;
  padding-top: 10px;
}
.font-type {
  margin-left: 25px;
  width: 240px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid gray;
  display: flex;
  overflow: hidden;
  font-size: 15px;

  text-align: center;
  background-color: white;
  .font-button {
    width: 120px;
    height: 40px;
    line-height: 40px;
    color: #000000;
    float: left;
  }
  .active {
    background-color: rgb(0, 123, 255);
    color: white;
  }
}
</style>
