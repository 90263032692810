var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "back-area" }, [
      _c("div", { staticClass: "title" }, [_vm._v("背景")]),
      _c("div", { staticClass: "back-oper-area" }, [
        _vm.editModule.backgroundImage
          ? _c("div", { staticClass: "back-img" }, [
              _c("img", { attrs: { src: _vm.editModule.backgroundImage } })
            ])
          : _vm._e(),
        !_vm.editModule.backgroundImage && _vm.editModule.backgroundColor
          ? _c("div", {
              staticClass: "back-color",
              style: {
                backgroundColor: _vm.editModule.backgroundColor
              }
            })
          : _vm._e(),
        !_vm.editModule.backgroundImage && !_vm.editModule.backgroundColor
          ? _c(
              "div",
              { staticClass: "addBack", on: { click: _vm.replaceBack } },
              [
                _c("i", { staticClass: "el-icon-plus" }),
                _vm._v(" 点击添加背景 ")
              ]
            )
          : _vm._e(),
        _vm.editModule.backgroundImage || _vm.editModule.backgroundColor
          ? _c(
              "button",
              { staticClass: "replace-back", on: { click: _vm.replaceBack } },
              [_vm._v(" 替换背景 ")]
            )
          : _vm._e(),
        _vm.editModule.backgroundImage || _vm.editModule.backgroundColor
          ? _c(
              "button",
              { staticClass: "remove-back", on: { click: _vm.removeBack } },
              [_vm._v(" 移除背景 ")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }