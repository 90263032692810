var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v("组合")]),
    _c("div", { staticClass: "image-area", attrs: { align: "left" } }, [
      !_vm.group.id
        ? _c(
            "div",
            { staticClass: "replace-img", on: { click: _vm.addNewGroup } },
            [_vm._v("成组")]
          )
        : _vm._e(),
      _vm.group.id
        ? _c("div", { staticClass: "replace-img", on: { click: _vm.split } }, [
            _vm._v("拆分组")
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }