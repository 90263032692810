var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.loadOwnModel,
          expression: "loadOwnModel"
        }
      ],
      staticClass: "template-centent"
    },
    [
      _vm.myModelList && _vm.myModelList.length == 0
        ? _c("div", { staticClass: "ownModel" }, [
            _vm._m(0),
            _c("div", [_vm._v("没有内容")]),
            _c("div", [
              _vm._v("去"),
              _c(
                "span",
                { staticClass: "link", on: { click: _vm.toModelCener } },
                [_vm._v("模板中心")]
              ),
              _vm._v("看看")
            ])
          ])
        : _vm._e(),
      _vm.myModelList && _vm.myModelList.length != 0
        ? _c(
            "div",
            _vm._l(_vm.imgShow, function(itemList, index) {
              return _c(
                "div",
                { key: index, staticClass: "my-item" },
                _vm._l(itemList, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "recommend-item",
                      on: {
                        click: function($event) {
                          return _vm.$emit("selectTemplate", item)
                        }
                      }
                    },
                    [_c("img", { attrs: { src: item.coverImg } })]
                  )
                }),
                0
              )
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-icon" }, [
      _c("i", { staticClass: "el-icon-box" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }