<template>
  <div
    class="materialList"
    :style="{
      left: left + '%',
    }"
  >
    <div class="title">
      <div class="type">{{ name }}</div>
      <div class="more" @click="left = -100">
        <i class="el-icon-arrow-left"></i>返回
      </div>
    </div>
    <!-- 搜索 -->
    <div class="search">
      <input
        v-model="imageName"
        placeholder="搜索素材"
        v-on:keyup.enter="resetSearch"
      />
      <i
        class="el-icon-close cancel-button"
        v-if="imageName"
        @click="initSearch"
      ></i>
      <i class="el-icon-search hang" @click="resetSearch"></i>
    </div>
    <div class="image-list" v-infinite-scroll="loadImageUpload">
      <div
        class="image-item"
        v-for="(item, index) in imageList"
        :key="index"
        @click="$emit('selecteImage', item.fileUrl)"
        draggable="true"
        @dragend="$emit('dragEnd')"
        @dragstart="$emit('drag', item.fileUrl)"
      >
        <div class="mask"></div>
        <img :src="`${item.fileUrl}?imageMogr2/thumbnail/!50p`" />
      </div>
    </div>
  </div>
</template>

<script>
import { sysmaterialpage } from '@/api/cubeclient'
export default {
  data () {
    return {
      imageName: "",
      left: -100,
      name: "",
      type: "",
      classificationId: "",
      pageNo: 1,
      pageSize: 20,
      imageList: []
    }
  },
  created () {
    // this.$bus.$on("showMaterialList", (param) => {
    //   this.resetSearch()
    //   this.left = 0
    //   this.name = param.name
    //   this.type = param.type
    //   this.classificationId = param.classificationId
    //   this.search()
    // })

  },
  beforeDestroy () {
    // this.$bus.$off("showMaterialList")
  },
  methods: {
    showMaterialList (param) {
      this.pageNo = 1
      this.left = 0
      this.name = param.name
      this.type = param.type
      this.classificationId = param.classificationId
      this.imageName = param.imageName
      this.search()
    },
    async search () {
      let res = await sysmaterialpage({
        classificationId: this.classificationId,
        title: this.imageName,
        pageNo: this.pageNo++,
        pageSize: this.pageSize,
        type: this.type
      })
      this.imageList = res.data.list
    },
    initSearch () {
      this.imageName = ""
      this.pageNo = 1
      this.search()
    },
    resetSearch () {
      this.pageNo = 1
      this.search()
    },
    async loadImageUpload () {
      if (this.type === "" || this.classificationId === "") {
        return
      }
      let res = await sysmaterialpage({
        classificationId: this.classificationId,
        title: this.imageName,
        pageNo: this.pageNo++,
        pageSize: this.pageSize,
        type: this.type
      })
      this.imageList = [...this.imageList, ...res.data.list]
    }
  }

}
</script>

<style lang="scss" scoped>
.materialList {
  top: 0;
  bottom: 0;
  width: 100%;

  position: absolute;
  background-color: #1d1e23;
  z-index: 99;
  transition: 0.5s;
}

.search {
  text-align: center;
  width: 290px;
  position: relative;
  margin: 0 28px;

  input {
    background-color: #1d1e23;
    width: 294px;
    height: 60px;
    border-radius: none;
    border: none;
    border-bottom: 1px solid #9da2ad;
    padding-left: 29px;
    color: #9da2ad;
    outline: none;
  }
  .hang {
    position: absolute;
    left: 0;
    top: 37%;
    width: 18px;
    height: 18px;
    color: #9da2ad;
  }
}
.cancel-button {
  position: absolute;
  right: 0;
  top: 37%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  color: #ffffff;
}

.title {
  margin: 0 28px;
  width: 290px;
  height: 30px;
  line-height: 30px;
}
.type {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  display: inline-block;
  float: left;
}
.more {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  float: right;
  cursor: pointer;
}

.image-list {
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 0;
  top: 100px;
  overflow: scroll;
}

.image-item {
  cursor: pointer;
  float: left;
  width: 136px;
  height: 136px;
  margin: 20px 20px 0 0;
  border-radius: 5px;
  background-color: #f6f6f6;
  position: relative;
  .mask {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: 1;
    &:hover {
      background-color: #0a40ad1c;
    }
  }
  img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left: 0px;
    right: 0px;
  }
}
</style>
