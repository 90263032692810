var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.loadRecommend,
          expression: "loadRecommend"
        }
      ],
      staticClass: "template-centent"
    },
    [
      !_vm.noneModel
        ? _vm._l(_vm.imgShow, function(itemList, index) {
            return _c(
              "div",
              { key: index, staticClass: "my-item" },
              _vm._l(itemList, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "recommend-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("selectTemplate", item)
                      }
                    }
                  },
                  [_c("img", { attrs: { src: item.coverImg } })]
                )
              }),
              0
            )
          })
        : _c("div", { staticClass: "noneModelInfo" }, [
            _vm._v("没有找到您想要的搜索结果")
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }