var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        {
          dotted:
            !_vm.textInfo.lock &&
            _vm.textInfo.id == _vm.editSelectedId &&
            !_vm.display,
          lock:
            _vm.textInfo.lock &&
            _vm.textInfo.id == _vm.editSelectedId &&
            !_vm.display
        },
        "text-content"
      ],
      style: {
        width: _vm.textInfo.width + "px",
        height:
          "" +
          (_vm.fontScale != 1
            ? _vm.textInfo.height * _vm.fontScale + "px"
            : "auto"),
        left: _vm.textInfo.left + "px",
        top: _vm.textInfo.top + "px",
        zIndex: _vm.textInfo.ZIndex + _vm.editZindex,
        transform:
          "rotate(" + (_vm.textInfo.rotate ? _vm.textInfo.rotate : 0) + "deg)",
        borderWidth: _vm.moveScale + "px"
      },
      on: {
        mouseenter: function($event) {
          _vm.showTips = true
        },
        mouseout: function($event) {
          _vm.showTips = false
        },
        keydown: function($event) {
          $event.stopPropagation()
        },
        mousedown: _vm.mousedown
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "tips",
          style: {
            transform:
              "translateY(-" +
              (40 + 20 * (_vm.moveScale - 1)) +
              "px)  translateX(-50%) scale(" +
              (_vm.showTips && !_vm.textInfo.lock && !_vm.contenteditable
                ? _vm.moveScale
                : 0) +
              ")",
            lineHeight: 1
          }
        },
        [_vm._v(" 双击编辑文字 ")]
      ),
      _c(
        "div",
        {
          staticClass: "tips",
          style: {
            transform:
              "translateY(-" +
              (40 + 20 * (_vm.moveScale - 1)) +
              "px)  translateX(-50%) scale(" +
              (_vm.showTips &&
              _vm.textInfo.lock &&
              _vm.textInfo.id != _vm.editSelectedId
                ? _vm.moveScale
                : 0) +
              ")",
            lineHeight: 1
          }
        },
        [_vm._v(" 单击编辑文字 ")]
      ),
      _c(
        "div",
        {
          style: {
            letterSpacing: _vm.textInfo.letterSpacing + "px"
          }
        },
        [
          _c(
            "div",
            {
              style: {
                fontSize: _vm.textInfo.fontSize + "px",
                fontWeight: _vm.textInfo.bold ? 900 : 400,
                fontStyle: _vm.textInfo.italic ? "italic" : "normal",
                textAlign: _vm.textInfo.align,
                lineHeight: _vm.textInfo.lineHeight
              }
            },
            [
              _vm.display
                ? _c("div", {
                    ref: "contentEle",
                    staticClass: "content",
                    style: {
                      opacity: _vm.textInfo.opacity / 100,
                      fontFamily: _vm.fontFullName,
                      transform: "scale(" + _vm.fontScale + ")",
                      width: _vm.textInfo.width / _vm.fontScale + "px",
                      textDecoration: _vm.textInfo.underline
                        ? "underline"
                        : "none",
                      color: _vm.textInfo.color
                    },
                    attrs: { contenteditable: !_vm.textInfo.lock },
                    domProps: { innerHTML: _vm._s(_vm.textInfo.html) },
                    on: { input: _vm.changeText, blur: _vm.commitText }
                  })
                : _vm._e(),
              !_vm.display
                ? _c("div", {
                    ref: "contentEle",
                    staticClass: "content",
                    style: {
                      color: "rgba(0,0,0,0)",
                      caretColor: _vm.textInfo.color,
                      cursor: _vm.contenteditable ? "text" : "move",
                      fontFamily: _vm.fontFullName,
                      transform: "scale(" + _vm.fontScale + ")",
                      width: _vm.textInfo.width / _vm.fontScale + "px"
                    },
                    attrs: {
                      contenteditable: !_vm.textInfo.lock && _vm.contenteditable
                    },
                    domProps: { innerHTML: _vm._s(_vm.html) },
                    on: {
                      input: _vm.changeText,
                      dblclick: _vm.selectContent,
                      blur: _vm.commitText
                    }
                  })
                : _vm._e()
            ]
          )
        ]
      ),
      _c("regulator", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.textInfo.id == _vm.editSelectedId &&
              !_vm.textInfo.lock &&
              !_vm.display &&
              !_vm.textInfo.groupId &&
              !_vm.group,
            expression:
              "\n      textInfo.id == editSelectedId &&\n      !textInfo.lock &&\n      !display &&\n      !textInfo.groupId &&\n      !group\n    "
          }
        ],
        attrs: { module: _vm.textInfo }
      }),
      _vm.textInfo.id == _vm.editSelectedId &&
      !_vm.display &&
      !_vm.textInfo.lock &&
      !_vm.textInfo.groupId &&
      !_vm.group
        ? _c("rotate", { attrs: { module: _vm.textInfo } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }