// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/img/colorsucker.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".canvas-area[data-v-3b88ed1c] {\n  position: absolute;\n  transform-origin: left top;\n  box-sizing: content-box;\n  border-style: solid;\n  border-color: #f4f4fb;\n  z-index: 99;\n}\n.canvas-area .background[data-v-3b88ed1c] {\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n.canvas-area .background img[data-v-3b88ed1c] {\n      position: absolute;\n}\n.mask[data-v-3b88ed1c] {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  z-index: 99;\n}\n.colorSelected[data-v-3b88ed1c] {\n  position: absolute;\n}\n.receive-content[data-v-3b88ed1c] {\n  position: absolute;\n  z-index: 99;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 28, default;\n}\n", ""]);
// Exports
module.exports = exports;
