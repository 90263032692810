<template>
  <div class="template-list">
    <div class="search">
      <input
        @keydown.stop
        v-model="modelName"
        placeholder="搜索模板"
        v-on:keyup.enter="initSearch"
      />
      <i
        class="el-icon-close cancel-button"
        v-if="modelName"
        @click="resetSearch"
      ></i>
      <i class="el-icon-search hang" @click="initSearch"></i>
    </div>
    <div class="template-category">
      <div
        class="category"
        @click="current = 0"
        :class="{ active: current === 0 }"
      >
        推荐模板
      </div>
      <div
        class="category"
        @click="collection"
        :class="{ active: current === 1 }"
      >
        收藏模板
      </div>
    </div>
    <recommen-post
      @selectTemplate="selectTemplate"
      v-show="current == 0"
      :searchName="searchName"
    ></recommen-post>
    <own-model
      @selectTemplate="selectTemplate"
      v-show="current != 0"
    ></own-model>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import { planargetone, saveBatch, planarjsonget } from '@/api/cubeclient'
import RecommenPost from './RecommenPost.vue'
import OwnModel from './OwnModel'
export default {
  props: ['posterStyle'],
  data () {
    return {
      imgList: ['素材库', '上传图片'],
      current: 0,
      bgcList: ['rgba(0, 0, 0, 1)', 'rgba(255, 91, 92, 1)', 'rgba(255, 189, 74, 1)',
        'rgba(254, 249, 81, 1)', 'rgba(153, 226, 101, 1)', 'rgba(53, 183, 42, 1)', 'rgba(68, 217, 230, 1)',
        'rgba(46, 179, 255, 1)', 'rgba(82, 113, 255, 1)', 'rgba(183, 96, 230, 1)', 'rgba(255, 99, 177, 1)', 'rgba(168, 168, 168, 1)'],

      isLogin: false,
      modelName: '',
      searchName: ''
    }
  },
  components: {
    RecommenPost,
    OwnModel
  },
  computed: {
    ...mapState('edit', ['editModule'])
  },
  created () {
    this.init()
  },
  beforeDestroy () {
  },
  methods: {

    collection () {
      if (!this.isLogin) {
        this.$Login()
        return
      }
      this.current = 1
    },
    initSearch () {
      this.current = 0
      this.searchName = this.modelName
    },
    resetSearch () {
      this.current = 0
      this.modelName = ''
      this.searchName = this.modelName
    },
    // 选择模板
    async selectTemplate (item) {
      const res = await planargetone({ id: item.id })
      this.modelItem = res.data
      this.saveInfo = res.data
      const jsonInfoList = res.data.psdList
      const JsonList = []
      // console.log(jsonInfoList)
      if (jsonInfoList == null) {
        return
      }
      for (let index = 0; index < jsonInfoList.length; index++) {
        if (!jsonInfoList[index].psdJsonId) {
          continue
        }
        const res = await planarjsonget({ id: jsonInfoList[index].psdJsonId })
        if (res.data.psdJson != null) {
          JsonList.push({ psdJson: res.data.psdJson })
        }
      }
      if (JsonList.length == 0) {
        this.$message({
          message: '当前模板信息异常',
          type: 'warning'
        })
        return
      }
      const JsonRes = await saveBatch(JsonList)
      jsonInfoList.forEach(element => {
        element.psdJsonId = JsonRes.data.shift()
      })
      const postInfoList = []
      JsonList.forEach(json => {
        postInfoList.push(JSON.parse(json.psdJson))
      })
      this.setPostInfoList(postInfoList)
    },
    // 初始化模板列表
    async init () {
      if (localStorage.getItem('token') == null) {
        this.isLogin = false
      } else {
        this.isLogin = true
      }
    },

    ...mapMutations('edit', ['setPostInfo', 'setPostInfoList'])

  }
}
</script>

<style lang="scss" scoped>
.cancel-button {
  position: absolute;
  right: 0;
  top: 37%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  color: #ffffff;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.hang {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 37%;
  width: 18px;
  height: 18px;
  color: #9da2ad;
}
.more {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: white;
  background-color: rgb(182, 182, 182);
  position: absolute;
  bottom: 0;
  left: 0;
}

.template-list {
  // 背景色
  box-sizing: border-box;
  width: 350px;
  height: 100%;
  overflow: hidden;
  .bgc {
    margin: 38px 0 0 28px;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      width: 30px;
      height: 30px;
      border: 1px solid #000000;
      border-radius: 4px;
      margin: 0 12px 12px 0;
      cursor: pointer;
      &:last-child {
        width: 72px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background: #404144;
        border: 1px solid #2b2c2f;
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #969ba5;
      }
    }
  }
  // 搜索
  .search {
    text-align: center;
    width: 290px;
    position: relative;
    margin: 0 28px;

    input {
      background-color: #1d1e23;
      width: 294px;
      height: 60px;
      border-radius: none;
      border: none;
      border-bottom: 1px solid #9da2ad;
      padding-left: 29px;
      color: #9da2ad;
      outline: none;
    }
  }
  // 模板
  .template-category {
    width: 350px;
    width: 294px;
    margin: 0 28px;
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    border-bottom: 1px solid #2b2c2f;
    padding-top: 27px;
    .category {
      margin-right: 43px;
      cursor: pointer;
    }
    .active {
      border-bottom: 2px solid #fff;
      padding-bottom: 6px;
    }
  }
}
</style>
