const panel = {
  canvasTop () {
    const top = (this.editSize.editHeight - this.postInfo.canvas.height * this.scale / 100 - 160) / 2 > 0 ? (this.editSize.editHeight - this.postInfo.canvas.height * this.scale / 100 - 160) / 2 : -40
    return top
    // return (this.editSize.editHeight-this.postInfo.canvas.height*this.scale/100)/2>0?(this.editSize.editHeight-this.postInfo.canvas.height*this.scale/100)/2-40/this.scale*100:-40/this.scale*100
  },
  canvasLeft () {
    // console.log(this.postInfo.canvas.width*this.scale/100+160)
    // console.log(this.postInfo.canvas.width*this.scale/100+160)
    const left = (this.editSize.editWidth - this.postInfo.canvas.width * this.scale / 100 - 160) / 2 > 0 ? (this.editSize.editWidth - this.postInfo.canvas.width * this.scale / 100 - 160) / 2 : -40
    return left
    // return (this.editSize.editWidth-this.postInfo.canvas.width*this.scale/100)/2>0?(this.editSize.editWidth-this.postInfo.canvas.width*this.scale/100)/2-40/this.scale*100:-40/this.scale*100
  }
}
export default panel
