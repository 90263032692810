import { render, staticRenderFns } from "./textList.vue?vue&type=template&id=32e74f1c&scoped=true&"
import script from "./textList.vue?vue&type=script&lang=js&"
export * from "./textList.vue?vue&type=script&lang=js&"
import style0 from "./textList.vue?vue&type=style&index=0&id=32e74f1c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e74f1c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data-jenkins/jenkins/workspace/html-cube-webapi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32e74f1c')) {
      api.createRecord('32e74f1c', component.options)
    } else {
      api.reload('32e74f1c', component.options)
    }
    module.hot.accept("./textList.vue?vue&type=template&id=32e74f1c&scoped=true&", function () {
      api.rerender('32e74f1c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/DesignEditor/component/modular/textList.vue"
export default component.exports