import { v4 as uuidv4 } from 'uuid'
import store from '../store'
import canvasConfig from '../config/canvas'
import fontList from './fontList'
/**
 * 模块元素创建方法
 */
const model = {
  /**
   * 获得画布中间位置
   * @param {} width
   * @param {*} height
   * @returns
   */
  getCenterPostion (width = 0, height = 0) {
    const { scale, canvas, scrollLeft, scrollTop } = store.state.edit
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height
    let left = 10
    let top = 10
    if (canvasWidth * scale / 100 < canvasConfig.editWidth) {
      left = (canvasWidth - width) / 2
    } else if (scrollLeft > 0) {
      left = scrollLeft * 100 / scale + (canvasConfig.editWidth * 100 / scale - width) / 2
    } else {
      left = (canvasConfig.editWidth * 100 / scale - width) / 2
    }
    if (canvasHeight * scale / 100 < canvasConfig.editHeight) {
      top = (canvasHeight - width) / 2
    } else if (scrollTop > 0) {
      top = scrollTop * 100 / scale + (canvasConfig.editHeight * 100 / scale - height) / 2
    } else {
      top = (canvasConfig.editHeight * 100 / scale - height) / 2
    }

    return { left, top }
  },
  /**
   * 创建图片
   * @param {} param0
   * @returns
   */
  createImage: function ({ src, height, width, opacity = 100, top, left }) {
    // console.log(uuidv4())
    const position = this.getCenterPostion(width, height)
    if (!top) {
      top = position.top
    }
    if (!left) {
      left = position.left
    }
    const picParam = {
      id: uuidv4(),
      top,
      left,
      src,
      type: 'image',
      height,
      width,
      picRate: width / height,
      imageId: uuidv4(),
      opacity, // 透明度
      borderRadius: 0, // 圆角
      boxShadow: false, // 是否开启阴影
      ZIndex: 0, // 图层
      rotate: 0, // 旋转角度
      shadow: { // 阴影参数
        hShadow: 10, // 水平阴影的位置
        vShadow: 10, // 垂直阴影的位置
        blur: 10, // 模糊距离
        color: 'rgba(0,0,0,1)'// 阴影的颜色
      },
      showInfo: { // 展示信息
        backImageWidth: width, // 背景图片宽
        backImageHeight: height, // 背景图片高
        backgroundPositionX: 0, // 展示坐标X轴
        backgroundPositionY: 0, // 展示坐标Y轴
        showWidth: width, // 显示宽
        showHeight: height// 显示高
      },
      lock: false // 锁
    }
    return picParam
  },
  /**
   * 创建文字
   * @param {*} param0
   * @returns
   */
  createText ({
    top, left, fontSize = 14, width, height = 0, opacity = 100, color = 'rgba(0,0,0,1)',
    text, bold = false, italic = false, underline = false, align = 'left', fontFamily = fontList[0].fontFamily,
    // fontStyle='Regular',
    fontUrl = fontList[0].url
  }) {
    const position = this.getCenterPostion(width, height)
    if (!top) {
      top = position.top
    }
    if (!left) {
      left = position.left
    }
    const textParam = {
      id: uuidv4(),
      type: 'text',
      left,
      top,
      text,
      width,
      fontSize,
      textId: uuidv4(),
      bold,
      height: 0,
      italic,
      underline, // 下划线
      color, // 颜色
      opacity, // 透明度
      align, // 对齐方式
      lineHeight: 1, // 行间距
      letterSpacing: 1, // 字间距
      ZIndex: 0, // 图层
      lock: false, // 锁
      rotate: 0, // 旋转角度
      html: text,
      fontFamily: fontFamily, // 字体
      fontUrl: fontUrl // 字体路径
      // fontStyle:fontStyle,//字体样式
    }
    return textParam
  },
  /**
   * 创建二维码
   * @param {*} param0
   * @returns
   */
  createCode ({ width, opacity = 100, url, height, colorDark = 'rgba(0,0,0,1)', top, left }) {
    const position = this.getCenterPostion(width, height)
    if (!top) {
      top = position.top
    }
    if (!left) {
      left = position.left
    }

    const codeParam = {
      id: uuidv4(),
      type: 'code',
      url,
      left,
      top,
      width,
      height,
      codeId: uuidv4(),
      opacity,
      colorDark,
      ZIndex: 0, // 图层
      rotate: 0, // 旋转角度
      lock: false, // 锁
      codeType: 2,
      typeInfo: {
        activity: {},
        subscription: ''
      }
    }
    return codeParam
  },
  /**
   * 相对于画布设置图片大小
   * @param {*} src
   * @returns
   */
  getAddImageSize (src) {
    const { canvas } = store.state.edit
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = src
      image.onload = function () {
        const naturalWidth = image.naturalWidth
        const naturalHeight = image.naturalHeight
        const rateW = naturalWidth * 3 / canvas.width
        const rateH = naturalHeight * 3 / canvas.height
        let rate = rateW
        if (rateW < rateH) {
          rate = rateH
        }
        resolve({
          width: naturalWidth / rate,
          height: naturalHeight / rate
        })
      }
    })
  },
  /**
   * 得到画布1/3的大小
   * @param {}} naturalWidth
   * @param {*} naturalHeight
   * @returns
   */
  getAddSize (naturalWidth, naturalHeight) {
    const { canvas } = store.state.edit

    const rateW = naturalWidth * 3 / canvas.width
    const rateH = naturalHeight * 3 / canvas.height
    let rate = rateW
    if (rateW < rateH) {
      rate = rateH
    }
    return {
      width: naturalWidth / rate,
      height: naturalHeight / rate
    }
  }
}
export default model
