var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "quik-content" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "title-info" }, [_vm._v("快捷键说明")]),
      _c("i", {
        staticClass: "icon iconfont icon-shouqi delete-icon",
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      })
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips-area" }, [
      _c("div", { staticClass: "tips-title" }, [_vm._v("常用操作")]),
      _c("div", { staticClass: "tips" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("保存")]),
          _c("div", { staticClass: "icon" }, [_vm._v("ctrl+s")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("删除")]),
          _c("div", { staticClass: "icon" }, [_vm._v("delete/backspace")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("快速移动")]),
          _c("div", { staticClass: "icon" }, [_vm._v("↑↓←→")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("移动")]),
          _c("div", { staticClass: "icon" }, [_vm._v("shift+↑↓←→")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("撤销")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+Z")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("重做")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+Shift+Z")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("复制")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+C")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("粘贴")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+V")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("剪切")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+X")])
        ])
      ]),
      _c("div", { staticClass: "tips-title" }, [_vm._v("图层元素")]),
      _c("div", { staticClass: "tips" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("上移一层")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+]")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("移动到顶层")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+shift+]")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("下移一层")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+[")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("移动到底层")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+shift+[")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("全选")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+A")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("全选")]),
          _c("div", { staticClass: "icon" }, [
            _vm._v("Ctrl/Shift+鼠票点击元素")
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("创建组合")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+ G")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("解开组合")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+Shift+G")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("锁定/取消锁定")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+L")])
        ])
      ]),
      _c("div", { staticClass: "tips-title" }, [_vm._v("画布")]),
      _c("div", { staticClass: "tips" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("画布放大缩小")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+ 鼠标滚轮")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("适应窗口")]),
          _c("div", { staticClass: "icon" }, [_vm._v("Ctrl+0")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }