
<template>
  <div class="MarketingActivities" @keydown.stop>
    <div class="content">
      <div class="title">收货人信息</div>

      <div class="addressInfo">
        <div class="text-title">
          <div class="name">
            {{ defaultAddress.name }}
          </div>
          <div class="number">
            {{ defaultAddress.phone }}
          </div>
        </div>
        <div class="text">
          {{ addressDetail }}
        </div>
      </div>

      <div class="space">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-button @click="showaddAdress = true">添加新地址</el-button>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 30px">
        <div class="title">商品信息</div>
        <el-row :gutter="20">
          <el-col :span="24">
            <div>
              <el-table
                :header-cell-style="{
                  'background-color': '#F5F7FA',
                  color: '#6b7594',
                }"
                border
                :data="[productInfo]"
                style="width: 100%"
              >
                <el-table-column
                  prop="productName"
                  label="货品名称"
                  align="center"
                  width="180"
                >
                  <template slot-scope="scope">
                    <img
                      class="product-img"
                      v-if="scope.row.decript != undefined"
                      :src="`https://${scope.row.decript.picture[0]}`"
                    />

                    {{ scope.row.productName }}
                  </template>
                </el-table-column>
                <!-- prop="skuCode" -->
                <el-table-column align="center" label="货品规格" width="180">
                  <template slot-scope="scope">
                    <!-- {{JSON.parse(scope.row.skuCode)}} -->
                    <!-- <span v-for="(item,index) in JSON.parse(scope.row.skuCode)">
                                {{item}}
                              </span> -->
                    <!-- {{}} -->
                    {{ scope.row.skuCode | skuCodeInfo }}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="文件">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      class="preview-button"
                      @click="previewFile(fileUrl)"
                      v-if="fileUrl"
                    >
                      文件预览
                    </el-button>
                    <!-- <div v-if="fileUrl" class="pointer">
                      <el-button
                        size="mini"
                        type="warning"
                        @click="downLoadFile(fileUrl)"
                        >文件下载</el-button
                      >
                    </div> -->
                    <div v-else>未上传</div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="price" label="单价（元）">
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="purchaseCount"
                  label="数量（个）"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="amount"
                  label="金额（元）"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="freight"
                  label="运费（元）"
                >
                </el-table-column>
              </el-table>
            </div>
            <div class="total-tips">
              <div class="title">订单备注</div>
              <el-row :gutter="20">
                <el-col :span="20">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    v-model="remark"
                    placeholder="可以把您的特殊要求告诉我们"
                  >
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <div align="right">
                    运费共计：<span class="money-font">{{
                      productInfo.freight
                    }}</span
                    >元
                  </div>
                  <div align="right">
                    货品总金额：<span class="money-font">{{
                      productInfo.amount
                    }}</span
                    >元
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>

      <div align="right" style="margin-top: 20px">
        <el-button type="danger" plain @click="submitOrder()"
          >提交订单</el-button
        >
        <el-button type="primary" plain @click="$emit('toPrintInfo')"
          >上一步</el-button
        >
      </div>

      <div class="space"></div>
      <el-dialog title="收货地址" :visible.sync="showAllAdress" width="50%">
        <el-table :data="allAddress" border style="width: 100%">
          <el-table-column prop="name" label="收货人姓名" width="100">
          </el-table-column>
          <el-table-column prop="phone" label="收货人手机" width="100">
          </el-table-column>
          <el-table-column prop="provinceName" label="所在地区" width="100">
          </el-table-column>
          <el-table-column prop="address" label="详细地址" width="100">
          </el-table-column>
          <el-table-column prop="isDefault" label="默认地址">
            <template slot-scope="scope">
              <el-switch
                @change="changeDefault(scope.row)"
                v-model="scope.row.isDefault"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="0"
                inactive-value="1"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="showAllAdress = false">取 消</el-button> -->
          <el-button type="primary" @click="showAllAdress = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <add-adress
        :visible="showaddAdress"
        @cancel="showaddAdress = false"
        @submit="commitAddress"
      ></add-adress>
      <!-- <add-invoice
        :visible="showAddInvoice"
        @cancel="showAddInvoice = false"
        @submit="showAddInvoice = false"
      ></add-invoice> -->
    </div>

    <preview-pic
      v-if="showPreview"
      :files="showFile"
      @close="
        () => {
          showPreview = false;
        }
      "
    ></preview-pic>
  </div>
</template>

<script>

import AddAdress from '@/components/AddAdress.vue'
import AddInvoice from '@/components/AddInvoice.vue'
import OrderItemUtil from '@/views/Order/common/OrderUtil'
import { getDefaultAddress } from '@/api/baseInfo'
import { getspuitem, assemblyOrder, placeOrder } from '@/api/printonline'
import { downLoadFile } from '@/lib/util'
import previewPic from '@/components/previewPic.vue'
import { queryPreview } from '@/api/cubeclient'
export default {
  data () {
    return {
      activeName: 'second',
      showAllAdress: false,
      showaddAdress: false,
      showselectInvoice: false,
      showAddInvoice: false,
      value: '',
      defaultAddress: {},
      spuId: 0,
      skuId: 0,
      productInfo: {},
      invoice: [],
      remark: '',
      allAddress: [],
      orderComment: '',
      invoiceStatus: 0,
      selectedId: '',
      fileUrl: '',
      showPreview: false

    }
  },
  props: {
    orderItem: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    },
    sourceWorkId: {
      type: String | Number,
      default: ''
    }
  },
  components: {
    AddAdress,
    previewPic,
    AddInvoice
  },
  mounted () {
    this.init()
  },
  methods: {
    /**
     * 下载印刷物资
     */
    downLoadFile (url) {
      const type = url.split('.').pop()
      downLoadFile(url, type)
    },
    commitAddress: OrderItemUtil.commitAddress,
    changeDefault: OrderItemUtil.changeDefault,
    /**
     * 提交订单
     */
    async submitOrder () {
      // eslint-disable-next-line eqeqeq
      if (this.productInfo.publishStatus == 0) {
        this.$message({
          message: '当前商品已下架',
          type: 'warning'
        })
        return
      }
      if (!this.defaultAddress.address) {
        this.$confirm('请添加收货地址', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.showaddAdress = true
        }).catch(() => {

        })
        return
      }
      const desc = this.productInfo.decript
      // 下订单
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const res = await placeOrder({
        consignee: this.defaultAddress.name,
        deliveryAddress: this.addressDetail,
        phoneNumber: this.defaultAddress.phone,
        freight: desc.freightFee,
        orderComment: this.remark,
        invoiceStatus: 0,
        itemList: [{
          taxonomyIds: [this.taxonomy],
          productName: this.productInfo.productName,
          productId: this.spuId,
          skuId: this.skuId,
          image: desc.picture ? desc.picture[0] : '',
          // QRCodeUrl:'http://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210126/0cc28f07a5654b989fb7f6f5841a723f.jpg',
          fileUrl: this.fileUrl,
          sourceWorkId: this.sourceWorkId
        }],
        demandOrderState: false,
        catalogId: this.orderItem.catalogId

      })
      loading.close()
      // eslint-disable-next-line eqeqeq
      if (res.code == 0) {
        this.$emit('toPayment', { orderCode: res.data })
      }
    },
    /**
     * 初始化信息
     */
    async init () {
      const orderInfo = this.orderItem
      this.spuId = orderInfo.spuId
      this.skuId = orderInfo.skuId
      this.fileUrl = orderInfo.fileUrl
      const res = await getDefaultAddress({})// 获取默认地址
      this.defaultAddress = res.data || {}
      const spuitemRes = await getspuitem({ spuId: this.spuId })
      spuitemRes.data.decript = JSON.parse(spuitemRes.data.decript) || {}
      const assemblyRes = await assemblyOrder({ skuIds: [parseInt(this.skuId)], num: null, catalogId: this.orderItem.catalogId })
      this.productInfo = { ...spuitemRes.data, ...assemblyRes.data[0] }
    },
    /**
     * 预览印刷物资
     */
    async previewFile (file) {
      const filsList = file.split(',').map(item => {
        return item.substring(item.lastIndexOf('/') + 1)
      })
      const res = await queryPreview({
        names: filsList,
        type: 'jpg'
      })

      if (res.data.length < 1) {
        this.$message.error('数据异常')
        return
      }
      this.showPreview = true
      this.showFile = res.data.map(item => {
        return `https://${item}`
      })
    }
  },
  filters: {
    skuCodeInfo: OrderItemUtil.skuCodeInfo
  },
  computed: {
    addressDetail: OrderItemUtil.addressDetail,
    selectedInvoceInfo: OrderItemUtil.selectedInvoceInfo

  }
}
</script>

<style lang="scss" scoped>
@import "../../../Order/scss/orderItem.scss";
</style>
