var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "50%",
        beforeClose: _vm.cancel,
        modal: true,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { attrs: { "status-icon": "", "label-width": "150px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "收货人姓名：" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收货人手机：" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.phone,
                  callback: function($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所在地区：" } },
            [
              _c("el-cascader", {
                attrs: { size: "large", options: _vm.regionData },
                model: {
                  value: _vm.selectedOptions,
                  callback: function($$v) {
                    _vm.selectedOptions = $$v
                  },
                  expression: "selectedOptions"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "详细地址：" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "100",
                  type: "textarea",
                  rows: 2,
                  placeholder: "街道门牌号等信息"
                },
                model: {
                  value: _vm.address,
                  callback: function($$v) {
                    _vm.address = $$v
                  },
                  expression: "address"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }