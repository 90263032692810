var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "post-list" },
    [
      _vm._l(_vm.postList, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "postItemList",
            on: {
              click: function($event) {
                return _vm.selectePost(index)
              }
            }
          },
          [
            _vm.postList.length != 1 && !_vm.admin
              ? _c("i", {
                  staticClass: "el-icon-close delete",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.deletePost(index)
                    }
                  }
                })
              : _vm._e(),
            _c("post-item", { attrs: { postItemWidth: 130, postInfo: item } })
          ],
          1
        )
      }),
      _vm.showAdd
        ? _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  on: { click: _vm.createNewPost }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }