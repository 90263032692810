var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "MarketingActivities",
      on: {
        keydown: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("收货人信息")]),
          _c("div", { staticClass: "addressInfo" }, [
            _c("div", { staticClass: "text-title" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(" " + _vm._s(_vm.defaultAddress.name) + " ")
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(" " + _vm._s(_vm.defaultAddress.phone) + " ")
              ])
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v(" " + _vm._s(_vm.addressDetail) + " ")
            ])
          ]),
          _c(
            "div",
            { staticClass: "space" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.showaddAdress = true
                            }
                          }
                        },
                        [_vm._v("添加新地址")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "30px" } },
            [
              _c("div", { staticClass: "title" }, [_vm._v("商品信息")]),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "header-cell-style": {
                                "background-color": "#F5F7FA",
                                color: "#6b7594"
                              },
                              border: "",
                              data: [_vm.productInfo]
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "productName",
                                label: "货品名称",
                                align: "center",
                                width: "180"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.decript != undefined
                                        ? _c("img", {
                                            staticClass: "product-img",
                                            attrs: {
                                              src:
                                                "https://" +
                                                scope.row.decript.picture[0]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.productName) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "货品规格",
                                width: "180"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("skuCodeInfo")(
                                              scope.row.skuCode
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { align: "center", label: "文件" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm.fileUrl
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "preview-button",
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.previewFile(
                                                    _vm.fileUrl
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" 文件预览 ")]
                                          )
                                        : _c("div", [_vm._v("未上传")])
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "price",
                                label: "单价（元）"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "purchaseCount",
                                label: "数量（个）"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "amount",
                                label: "金额（元）"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "freight",
                                label: "运费（元）"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "total-tips" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("订单备注")
                        ]),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 20 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 2, maxRows: 4 },
                                    placeholder: "可以把您的特殊要求告诉我们"
                                  },
                                  model: {
                                    value: _vm.remark,
                                    callback: function($$v) {
                                      _vm.remark = $$v
                                    },
                                    expression: "remark"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _c("div", { attrs: { align: "right" } }, [
                                _vm._v(" 运费共计："),
                                _c("span", { staticClass: "money-font" }, [
                                  _vm._v(_vm._s(_vm.productInfo.freight))
                                ]),
                                _vm._v("元 ")
                              ]),
                              _c("div", { attrs: { align: "right" } }, [
                                _vm._v(" 货品总金额："),
                                _c("span", { staticClass: "money-font" }, [
                                  _vm._v(_vm._s(_vm.productInfo.amount))
                                ]),
                                _vm._v("元 ")
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { align: "right" }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.submitOrder()
                    }
                  }
                },
                [_vm._v("提交订单")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("toPrintInfo")
                    }
                  }
                },
                [_vm._v("上一步")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "space" }),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "收货地址",
                visible: _vm.showAllAdress,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.showAllAdress = $event
                }
              }
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.allAddress, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "收货人姓名", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "收货人手机", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "provinceName",
                      label: "所在地区",
                      width: "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "详细地址", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "isDefault", label: "默认地址" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                                "active-value": "0",
                                "inactive-value": "1"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.changeDefault(scope.row)
                                }
                              },
                              model: {
                                value: scope.row.isDefault,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "isDefault", $$v)
                                },
                                expression: "scope.row.isDefault"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showAllAdress = false
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("add-adress", {
            attrs: { visible: _vm.showaddAdress },
            on: {
              cancel: function($event) {
                _vm.showaddAdress = false
              },
              submit: _vm.commitAddress
            }
          })
        ],
        1
      ),
      _vm.showPreview
        ? _c("preview-pic", {
            attrs: { files: _vm.showFile },
            on: {
              close: function() {
                _vm.showPreview = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }