var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "post",
      style: {
        height:
          (_vm.postInfo.canvas.height * _vm.postItemWidth) /
            _vm.postInfo.canvas.width +
          "px",
        width: _vm.postItemWidth + "px"
      }
    },
    [
      _c("div", { staticClass: "mask" }),
      _c(
        "div",
        {
          staticClass: "canvas",
          style: {
            width: _vm.postInfo.canvas.width + "px",
            height: _vm.postInfo.canvas.height + "px",
            transform:
              "scale(" +
              _vm.postItemWidth / _vm.postInfo.canvas.width +
              "," +
              _vm.postItemWidth / _vm.postInfo.canvas.width +
              ") translateX(" +
              -((1 * _vm.postInfo.canvas.width) / _vm.postItemWidth - 1) * 50 +
              "%) translateY(" +
              -((1 * _vm.postInfo.canvas.width) / _vm.postItemWidth - 1) * 50 +
              "%)"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "background",
              style: {
                background: _vm.postInfo.background.backgroundColor
              }
            },
            [
              _vm.postInfo.background.backgroundImage
                ? _c("img", {
                    style: {
                      width: _vm.postInfo.background.width + "px",
                      height: _vm.postInfo.background.height + "px",
                      top:
                        "-" +
                        _vm.postInfo.background.imageInfo.backgroundPositionY +
                        "px",
                      left:
                        "-" +
                        _vm.postInfo.background.imageInfo.backgroundPositionX +
                        "px"
                    },
                    attrs: { src: _vm.postInfo.background.backgroundImage }
                  })
                : _vm._e()
            ]
          ),
          _vm._l(_vm.postInfo.texts, function(item) {
            return _c("d-text", {
              key: item.textId,
              attrs: { textInfo: item, display: true }
            })
          }),
          _vm._l(_vm.postInfo.images, function(item) {
            return _c("d-image", {
              key: item.imageId,
              attrs: { image: item, display: true }
            })
          }),
          _vm._l(_vm.postInfo.codes, function(item) {
            return _c("d-code", {
              key: item.codeId,
              attrs: { code: item, display: true }
            })
          })
        ],
        2
      ),
      _vm.watermark ? _c("div", { staticClass: "watermark" }) : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }