<template>
  <div class="activity-List-area">
    <div class="class-item" @click="setTempleType(null)">全部</div>
    <div
      class="class-item"
      @click="setTempleType(index)"
      v-for="(type, index) in typeList"
      :key="index"
    >
      {{ type }}
    </div>
    <div class="activityList">
      <div class="item" v-for="(activity, index) in activityList" :key="index">
        <img :src="activity.cover" class="coverPic" />
        <div class="activity-name">{{ activity.templeName }}</div>
        <div class="mask">
          <div class="code" align="center">
            <q-r-code :url="activity.page | barcodeImgUrl"></q-r-code>
          </div>
          <div class="button-area">
            <el-button
              size="medium"
              type="primary"
              @click="toEdit(activity.templeId)"
              ><i class="el-icon-edit"></i>编辑</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div align="right" class="page-area">
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="limit"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/market'
import QRCode from './QRCode.vue'
export default {
  components: { QRCode },
  data () {
    return {
      typeList: ['开宝箱', '九宫格', '砸金蛋', '大转盘', '阅读有礼'],
      limit: 4,
      page: 1,
      templeType: null,
      activityList: [],
      total: 0,
      templeType: null
    }
  },
  created () {
    this.getActiveData()
  },
  methods: {
    setTempleType (templeType) {
      this.templeType = templeType
      this.page = 1
      this.total = 0
      this.getActiveData()
    },
    // 获取活动列表
    async getActiveData () {
      const res = await getList({ pageSize: this.limit, pageNO: this.page, type: this.templeType, isEnable: 1 })

      if (res.code == 200) {
        this.activityList = res.data.list
        this.total = res.data.total
      }
    },

    toEdit (templeId) {
      const { href } = this.$router.resolve({ path: '/MarketingActivityEdit', query: { templeId: templeId } })
      window.open(href, '_blank')
    },
    handleCurrentChange (val) {
      this.page = val
      this.getActiveData()
    }
  },
  filters: {

    barcodeImgUrl (val) {
      return JSON.parse(val).activityBase.barcodeImgUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-List-area {
  margin-top: 10px;
}
.class-item {
  cursor: pointer;
  height: 24px;
  display: inline-block;
  float: left;
  background: #404144;
  border-radius: 4px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #969ba5;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 24px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.item {
  margin-top: 20px;
  margin-right: 20px;
  display: inline-block;
  float: left;
  width: 164px;
  position: relative;
}
.coverPic {
  width: 164px;
  height: 164px;
}
.activity-name {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.37);
  opacity: 0;
  transition: 0.2s;
  &:hover {
    opacity: 1;
  }
}
.code {
  margin-top: 20px;
}
.button-area {
  height: 48px;
  width: 100%;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activityList {
  width: 100%;
  display: inline-block;
}
.page-area {
  width: 100%;
}
.el-pagination {
  /deep/ li {
    background-color: rgba(0, 0, 0, 0);
  }
  /deep/ .btn-next {
    background-color: rgba(0, 0, 0, 0);
  }
  /deep/ .btn-prev {
    background-color: rgba(0, 0, 0, 0);
  }
}
</style>
