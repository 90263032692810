<template>
  <div>
    <!-- <div class='title'>画布尺寸</div> -->
    <!-- <div class='canvasSizeEdit'>
           <div class='input-area'>
               <input type="number"  v-model.number="canvas.width" @blur="blur"/>
               <div class='tips'>
                   宽
               </div>
           </div>
            <div class='input-area'>
               <input type="number"  v-model.number="canvas.height" @blur="blur"/>
               <div class='tips'>
                   高
               </div>
           </div>
    </div> -->
    <div class="back-area">
      <div class="title">背景</div>
      <div class="back-oper-area">
        <div class="back-img" v-if="editModule.backgroundImage">
          <img :src="editModule.backgroundImage" />
        </div>
        <div
          class="back-color"
          v-if="!editModule.backgroundImage && editModule.backgroundColor"
          :style="{
            backgroundColor: editModule.backgroundColor,
          }"
        ></div>
        <div
          class="addBack"
          v-if="!editModule.backgroundImage && !editModule.backgroundColor"
          @click="replaceBack"
        >
          <i class="el-icon-plus"></i>
          点击添加背景
        </div>
        <button
          class="replace-back"
          v-if="editModule.backgroundImage || editModule.backgroundColor"
          @click="replaceBack"
        >
          替换背景
        </button>
        <button
          class="remove-back"
          v-if="editModule.backgroundImage || editModule.backgroundColor"
          @click="removeBack"
        >
          移除背景
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState('edit', ['editModule', 'canvas'])

  },
  methods: {
    removeBack () {
      this.editModule.backgroundImage = ''
      this.editModule.backgroundColor = ''
    },
    replaceBack () {
      this.$bus.$emit('menusChange', 'backgroundList')
    },
    blur () {
      if (this.canvas.width < 100) {
        this.canvas.width = 100
      }
      if (this.canvas.height < 100) {
        this.canvas.height = 100
      }
      this.canvas.width = Math.round(this.canvas.width)
      this.canvas.height = Math.round(this.canvas.height)
    }
  },
  watch: {

  }

}
</script>

<style lang="scss" scoped>
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
}
.canvasSizeEdit {
  display: inline-block;
}
.input-area {
  float: left;
  width: 114px;
  height: 34px;
  background: #1d1e23;
  border: 2px solid #303235;
  border-radius: 4px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #969ba5;
  &:hover {
    border: 2px solid #2468f2;
  }
  input {
    width: 60px;
    background: none;
    outline: none;
    border: none;
    color: white;
    &:focus {
      border: none;
    }
  }
}

.back-area {
  .back-oper-area {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .back-img {
      width: 235px;
      img {
        width: 100%;
      }
    }
    .back-color {
      width: 235px;
      height: 235px;
    }
    .addBack {
      width: 235px;
      height: 235px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      border: 1px dotted white;
      cursor: pointer;
    }
  }
}

.replace-back {
  cursor: pointer;
  margin-top: 20px;
  width: 236px;
  height: 40px;
  background: #2468f2;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  border: none;
}
.remove-back {
  cursor: pointer;
  margin-top: 20px;
  width: 236px;
  height: 40px;
  background: #1d1e23;
  border: 1px solid #5d6269;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5d6269;
}
</style>
