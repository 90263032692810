<template>
  <div>
    <el-dialog
      :title="title"
      :visible="visible"
      width="30%"
      :showClose="false"
      @beforeClose="$emit('submit')"
    >
      <el-form label-width="120px" ref="form" :model="form" :rules="rules">
        <el-form-item label="发票类型" prop="ifVat">
          <el-radio-group v-model="form.ifVat">
            <el-radio :label="1">增值普通发票</el-radio>
            <el-radio :label="2">增值专用发票</el-radio>
            <el-radio :label="3">个人</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="抬头名称"
          v-if="form.ifVat == 3"
          prop="vatInvoiceTitle"
        >
          <el-input
            v-model="form.vatInvoiceTitle"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称" v-else prop="vatInvoiceTitle">
          <el-input
            v-model="form.vatInvoiceTitle"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="纳税人识别号"
          v-if="form.ifVat != 3"
          prop="vatTaxpayerNum"
        >
          <el-input v-model="form.vatTaxpayerNum"></el-input>
        </el-form-item>
        <el-form-item
          label="注册地址"
          v-if="form.ifVat == 2"
          prop="vatCompanyAddress"
        >
          <el-input
            v-model="form.vatCompanyAddress"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="注册电话"
          v-if="form.ifVat == 2"
          prop="vatTelphone"
        >
          <el-input v-model="form.vatTelphone"></el-input>
        </el-form-item>
        <el-form-item
          label="开户银行"
          v-if="form.ifVat == 2"
          prop="vatBankName"
        >
          <el-input
            v-model="form.vatBankName"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="银行账户"
          v-if="form.ifVat == 2"
          prop="vatBankAccount"
        >
          <el-input v-model="form.vatBankAccount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('cancel')">取 消</el-button>
        <el-button type="primary" @click="submit">立即提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addInvoice } from '../api/baseInfo'
import { updateInvoice } from '@/api/userInfo'
import _ from 'lodash'
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    invoiceInfo: {
      type: Object,
      default: new Object(),
    }


  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入注册电话'));
      } else {
        var reg1 = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        var reg2 = /^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})$/;
        if (reg1.test(value) || reg2.test(value)) {
          callback();
        } else {
          callback(new Error('请输入正确的注册电话'));
        }
      }
    }
    var validateAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入银行账户'));
      } else if (value.length > 30) {
        callback(new Error('请输入正确的银行账户'))
      } else {
        var reg = /^\d+$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error('请输入正确的银行账户'));
        }
      }
    }
    var validateNum = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入纳税人识别号'));
      } else if (value.length > 30) {
        callback(new Error('请输入正确的纳税人识别号'))
      } else {
        var reg = /^[\d\w]+$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error('请输入正确的纳税人识别号'));
        }
      }
    }

    return {
      form: {
        ifVat: 1,
        vatTaxpayerNum: '',
        vatInvoiceTitle: '',
        vatCompanyAddress: '',
        vatTelphone: '',
        vatBankName: '',
        vatBankAccount: '',
      },
      rules: {
        ifVat: [
          { required: true, message: '请选择发票类型', trigger: 'change' },
        ],
        vatInvoiceTitle: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        vatTaxpayerNum: [
          { required: true, validator: validateNum, trigger: 'blur' },
        ],
        vatCompanyAddress: [
          { required: true, message: '请输入注册地址', trigger: 'blur' },
        ],
        vatTelphone: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        vatBankName: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
        ],
        vatBankAccount: [
          { required: true, validator: validateAccount, trigger: 'blur' },
        ],
      },
      visible: true
    }
  },
  created () {
    if (this.edit) {
      this.form = _.cloneDeep(this.invoiceInfo)
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = {}
          if (this.edit) {
            res = await updateInvoice(this.form)
          } else {
            res = await addInvoice(this.form)

          }
          if (res.code == 0) {
            this.$message.success('提交成功')
            this.$emit('submit')
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    }
  },
  computed: {
    title () {
      if (this.edit) {
        return '修改发票'
      } else {
        return '添加发票'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
