<template>
  <div>
    <el-dialog
      title="下载中"
      :visible.sync="dialogDownVisible"
      width="50%"
      center
      :show-close="false"
    >
      <el-progress :percentage="percentage"></el-progress>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDown">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { download } from '@/api/cubeclient'
export default {
  data () {
    return {
      percentage: 0,
      dialogDownVisible: false,
      time: 0,
      downIng: false
    }
  },
  props: {
    id: {
      type: String | Number,
      default: ""
    },
    type: {
      type: String,
      default: ''
    }
  },
  methods: {
    cancelDown () {
      this.dialogDownVisible = false
      this.downIng = false
    },
    async downLoad (watermark) {
      this.dialogDownVisible = true
      this.percentage = 0
      this.downIng = true
      this.$el.click()
      this.time = 0
      console.log(this)
      const getImage = async () => {
        const res = await download({ id: this.id, token: localStorage.getItem('token'), type: this.type, watermark: watermark })
        if (res.code) {
          if (res.code == 401) {
            return
          }
          if (this.time > 60) {
            this.time = 0
            this.$message({
              type: 'error',
              message: '下载异常'
            })
            this.dialogDownVisible = false
            return
          }
          if (this.percentage < 90) {
            this.percentage += 5
          }
          if (this.downIng) {
            setTimeout(() => {
              this.time++
              getImage()
            }, 1000)
          }
        } else {
          this.percentage = 100
          const url = `${process.env.VUE_APP_URL}/cube-client/user/planar/download?id=${this.id}&token=${localStorage.getItem('token')}&type=${this.type}&watermark=${watermark}`
          // 生成一个a标签
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          // // 生成时间戳
          const timestamp = new Date().getTime()
          link.download = timestamp
          document.body.appendChild(link)
          link.click()
          setTimeout(() => {
            this.dialogDownVisible = false
            this.percentage = 0
          }, 1000)
        }
      }
      getImage()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
