var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    staticClass: "el-icon-refresh swing-button",
    style: {
      transform: "translateX(-50%) scale(" + _vm.moveScale + ")",
      bottom: -80 * _vm.moveScale + "px"
    },
    on: {
      mousedown: function($event) {
        $event.stopPropagation()
        return _vm.rotate($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }