<template>
  <div
    @mousedown.stop="mousedown"
    class="group"
    :style="{
      width: module.width + 'px',
      height: module.height + 'px',
      left: module.left + 'px',
      top: module.top + 'px',
      transform: `rotate(${module.rotate ? module.rotate : 0}deg)`,
      borderWidth: `${moveScale * 1}px`,
      borderColor: `${module.lock ? 'red' : 'blue'}`,
      zIndex: 100,
    }"
  >
    <regulator v-if="!module.lock" :module="module"></regulator>
    <rotate
      v-if="!module.lock"
      @change="resetGroupItem"
      :module="module"
    ></rotate>
  </div>
</template>

<script>
import Rotate from './Rotate.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import Regulator from './Regulator.vue'
import MathUtil from '@/lib/MathUtil'
import PositionUtil from '@/lib/PositionUtil'

let parameter = {}
export default {
  components: { Regulator, Rotate },
  props: {
    module: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    }
  },
  data () {
    return {

    }
  },
  created () {
    this.initGroupSize()
    this.initParameter()
    this.$bus.$on('resetGroupItem', this.resetGroupItem)
    this.$bus.$on('initParameter', this.initParameter)
  },
  beforeDestroy () {
    this.$bus.$off('resetGroupItem')
    this.$bus.$off('initParameter')
  },
  methods: {
    ...mapMutations('edit', ['pushHistoryRecord', 'initGroupSize', 'initGroupSizeByGroupId']),
    mousedown () {
      if (this.module.lock) {
        return
      }
      const oriX = event.clientX
      const oriY = event.clientY
      const orileft = this.module.left
      const oritop = this.module.top
      let pushHistory = false
      window.onmousemove = (event) => {
        const X = event.clientX
        const Y = event.clientY
        this.module.left = orileft + (X - oriX) * this.moveScale
        this.module.top = oritop + (Y - oriY) * this.moveScale
        pushHistory = true
        this.resetGroupItem()
      }
      window.onmouseup = (event) => {
        window.onmousemove = null
        // if(pushHistory){
        this.module.left = Math.round(this.module.left)
        this.module.height = Math.round(this.module.height)
        this.module.width = Math.round(this.module.width)
        this.module.top = Math.round(this.module.top)
        if (pushHistory) {
          this.pushHistoryRecord()
        }

        window.onmouseup = null
        // }
      }
    },
    /**
     * 初始化组合中图层信息
     */
    initParameter () {
      parameter = {
        groupInfos: []
      }
      let groupIds = []

      const moduleCenerPosition = PositionUtil.getCenterPosition(
        this.module.left,
        this.module.top,
        this.module.width,
        this.module.height
      )
      this.module.layerIds.forEach(id => {
        const item = this.layers.find(layer => {
          return id === layer.id
        })
        if (item.groupId && item.groupId !== this.module.id) {
          groupIds.push(item.groupId)
        }
        if (!item) {
          return
        }
        const itemLengthInfo = PositionUtil.getPositionInfoByTwoPoint(
          moduleCenerPosition,
          PositionUtil.getCenterPosition(
            item.left,
            item.top,
            item.width,
            item.height
          )
        )
        const innerAngle = itemLengthInfo.angle - this.module.rotate
        const itemInfo = {}
        itemInfo.width = item.width / this.module.width
        itemInfo.height = item.height / this.module.height
        itemInfo.centerLeft = (itemLengthInfo.length * MathUtil.cos(innerAngle) +
          this.module.width / 2) /
          this.module.width
        itemInfo.centerTop = (this.module.height / 2 +
          itemLengthInfo.length * MathUtil.sin(innerAngle)) /
          this.module.height
        itemInfo.rotate = item.rotate - this.module.rotate
        if (item.type === 'text') {
          itemInfo.fontSize = item.fontSize / this.module.width
          itemInfo.letterSpacing = item.letterSpacing / this.module.width
        }
        parameter[item.id] = itemInfo
      })
      groupIds = new Set(groupIds)
      groupIds.forEach(id => {
        const group = this.groups.find(item => {
          return item.id === id
        })

        parameter.groupInfos.push({
          id: id,
          rotate: group.rotate - this.module.rotate,
          group: group
        })
      })
    },
    /**
     * 重置图层位置，大小
     */
    resetGroupItem () {
      parameter.groupInfos.forEach(item => {
        item.group.rotate = (item.rotate + this.module.rotate) % 360
      })
      this.module.layerIds.forEach(id => {
        const item = this.layers.find(layer => {
          return id === layer.id
        })
        const orgItem = parameter[item.id]
        item.rotate = (orgItem.rotate + this.module.rotate) % 360
        const width = (orgItem.centerLeft - 0.5) * this.module.width
        const height = (0.5 - orgItem.centerTop) * this.module.height
        const hypotenuse = MathUtil.getHypotenuse(width, height)
        const innerAngle = MathUtil.atan(height / width) - 180
        let angle = innerAngle - this.module.rotate
        if (orgItem.centerLeft < 0.5) {
          angle += 180
        }
        const centerPosition = PositionUtil.getPositionbyCenter(angle, hypotenuse, {
          left: this.module.left + this.module.width / 2,
          top: this.module.top + this.module.height / 2
        })
        item.left = centerPosition.left - item.width / 2
        item.top = centerPosition.top - item.height / 2
        item.width = this.module.width * orgItem.width
        item.height = this.module.height * orgItem.height
        if (item.type === 'text') {
          item.fontSize = this.module.width * orgItem.fontSize
          item.letterSpacing = this.module.width * orgItem.letterSpacing
        }
      })
    }
  },
  computed: {
    ...mapState('edit', ['scale']),
    ...mapGetters('edit', ['layers', 'groups']),
    moveScale () {
      return 100 / this.scale
    }
  },
  watch: {

    'module.layerIds' (nv, ov) {
      this.initGroupSize()
      this.initParameter()
    },
    'module.id' (nv, ov) {
      this.initGroupSize()
      this.initParameter()
    },
    'module.left' (nv, ov) {
      this.resetGroupItem()
    },
    'module.top' (nv, ov) {
      this.resetGroupItem()
    }

  }
}
</script>

<style>
.group {
  position: absolute;
  border: dashed;
}
</style>
