<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="50%"
    :beforeClose="cancel"
    :modal="true"
    append-to-body
  >
    <el-form status-icon label-width="150px">
      <el-form-item label="收货人姓名：">
        <el-input v-model="name"></el-input>
      </el-form-item>
      <el-form-item label="收货人手机：">
        <el-input v-model="phone"></el-input>
      </el-form-item>
      <el-form-item label="所在地区：">
        <el-cascader
          size="large"
          :options="regionData"
          v-model="selectedOptions"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="详细地址：">
        <el-input
          maxlength="100"
          type="textarea"
          :rows="2"
          v-model="address"
          placeholder="街道门牌号等信息"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data'
import { saveAddress } from '../api/baseInfo'
import { updateAddress } from '../api/userInfo'
import Tool from '../lib/tool'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    addressInfo: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      regionData: regionData,
      selectedOptions: '',
      name: '',
      phone: '',
      address: ''
    }
  },
  created () {
    if (this.edit) {
      this.name = this.addressInfo.name
      this.phone = this.addressInfo.phone
      this.address = this.addressInfo.address
      this.selectedOptions = [this.addressInfo.provinceCode, this.addressInfo.cityCode]
      if (this.addressInfo.areaCode) {
        this.selectedOptions.push(this.addressInfo.areaCode)
      }
    }

  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    submit () {
      let param = {}
      if (this.name == '') {
        this.$message({
          message: '请填写收货人姓名',
          type: 'warning'
        });
        return;
      }
      if (!Tool.isPhoneNumber(this.phone)) {

        this.$message({
          message: '请填写正确的手机号码',
          type: 'warning'
        });
        return;
      }
      if (this.selectedOptions == '') {
        this.$message({
          message: '请选择地址',
          type: 'warning'
        });
        return;
      }

      if (this.address == '') {
        this.$message({
          message: '请填写收货详细地址',
          type: 'warning'
        });
        return;
      }
      param.name = this.name
      param.phone = this.phone
      param.address = this.address
      // console.log(this.selectedOptions)
      this.selectedOptions.forEach((ele, index) => {
        switch (index) {
          case 0:
            param.provinceCode = ele
            param.provinceName = CodeToText[ele]
            break;
          case 1:
            param.cityCode = ele
            param.cityName = CodeToText[ele]
            break;
          case 2:
            param.areaCode = ele
            param.areaName = CodeToText[ele]
            break;

        }
      })
      if (this.edit) {
        param.id = this.addressInfo.id
        updateAddress(param)
        this.$message({
          message: '地址修改成功',
          type: 'success'
        });
        this.$emit('submit', param)
      } else {
        saveAddress(param)
        this.$message({
          message: '地址保存成功',
          type: 'success'
        });
        this.$emit('submit', param)
      }

    }
  },
  computed: {
    title () {
      if (this.edit) {
        return '修改收货地址'
      } else {
        return '新增收货地址'
      }
    }
  }



}
</script>

<style lang="scss" scoped>
.owndialog {
  z-index: 9999;
}
</style>
