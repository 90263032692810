var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-slider",
      class: {
        "is-vertical": _vm.vertical,
        "el-slider--with-input": _vm.showInput
      },
      attrs: {
        role: "slider",
        "aria-valuemin": _vm.min,
        "aria-valuemax": _vm.max,
        "aria-orientation": _vm.vertical ? "vertical" : "horizontal",
        "aria-disabled": _vm.sliderDisabled
      }
    },
    [
      _vm.showInput && !_vm.range
        ? _c("el-input-number", {
            ref: "input",
            staticClass: "el-slider__input",
            attrs: {
              step: _vm.step,
              disabled: _vm.sliderDisabled,
              controls: _vm.showInputControls,
              min: _vm.min,
              max: _vm.max,
              debounce: _vm.debounce,
              size: _vm.inputSize
            },
            on: { change: _vm.emitChange },
            model: {
              value: _vm.firstValue,
              callback: function($$v) {
                _vm.firstValue = $$v
              },
              expression: "firstValue"
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          ref: "slider",
          staticClass: "el-slider__runway",
          class: { "show-input": _vm.showInput, disabled: _vm.sliderDisabled },
          style: _vm.runwayStyle,
          on: { click: _vm.onSliderClick }
        },
        [
          _c("div", { staticClass: "el-slider__bar", style: _vm.barStyle }),
          _c("slider-button", {
            ref: "button1",
            attrs: {
              vertical: _vm.vertical,
              "tooltip-class": _vm.tooltipClass
            },
            model: {
              value: _vm.firstValue,
              callback: function($$v) {
                _vm.firstValue = $$v
              },
              expression: "firstValue"
            }
          }),
          _vm.range
            ? _c("slider-button", {
                ref: "button2",
                attrs: {
                  vertical: _vm.vertical,
                  "tooltip-class": _vm.tooltipClass
                },
                model: {
                  value: _vm.secondValue,
                  callback: function($$v) {
                    _vm.secondValue = $$v
                  },
                  expression: "secondValue"
                }
              })
            : _vm._e(),
          _vm._l(_vm.stops, function(item, key) {
            return _vm.showStops
              ? _c("div", {
                  key: key,
                  staticClass: "el-slider__stop",
                  style: _vm.getStopStyle(item)
                })
              : _vm._e()
          }),
          _vm.markList.length > 0
            ? [
                _c(
                  "div",
                  _vm._l(_vm.markList, function(item, key) {
                    return _c("div", {
                      key: key,
                      staticClass: "el-slider__stop el-slider__marks-stop",
                      style: _vm.getStopStyle(item.position)
                    })
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "el-slider__marks" },
                  _vm._l(_vm.markList, function(item, key) {
                    return _c("slider-marker", {
                      key: key,
                      style: _vm.getStopStyle(item.position),
                      attrs: { mark: item.mark }
                    })
                  }),
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }