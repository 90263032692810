var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-list" },
    [
      _c("div", { staticClass: "search" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.imageName,
              expression: "imageName"
            }
          ],
          attrs: { placeholder: "搜索图片" },
          domProps: { value: _vm.imageName },
          on: {
            keydown: function($event) {
              $event.stopPropagation()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.initSearch($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.imageName = $event.target.value
            }
          }
        }),
        _vm.imageName
          ? _c("i", {
              staticClass: "el-icon-close cancel-button",
              on: { click: _vm.resetSearch }
            })
          : _vm._e(),
        _c("i", {
          staticClass: "el-icon-search hang",
          on: { click: _vm.initSearch }
        })
      ]),
      _c("div", { staticClass: "template-category" }, [
        _c(
          "div",
          {
            staticClass: "category",
            class: { active: _vm.current === 0 },
            on: {
              click: function($event) {
                _vm.current = 0
              }
            }
          },
          [_vm._v(" 素材库 ")]
        ),
        !_vm.admin
          ? _c(
              "div",
              {
                staticClass: "category",
                class: { active: _vm.current === 1 },
                on: { click: _vm.uploadPicture }
              },
              [_vm._v(" 上传图片 ")]
            )
          : _vm._e()
      ]),
      _vm.current == 0
        ? _c("div", { staticClass: "template-centent" }, [
            _c(
              "div",
              { staticClass: "image-area" },
              [
                _vm._l(_vm.imageclassList, function(imageClass, index) {
                  return [
                    _c("imageblock", {
                      key: index,
                      attrs: {
                        classificationId: imageClass.id,
                        name: imageClass.name
                      },
                      on: {
                        selecteImage: _vm.selecteImage,
                        dragEnd: _vm.dragEnd,
                        drag: _vm.drag,
                        showMaterialList: _vm.showMaterialList
                      }
                    })
                  ]
                })
              ],
              2
            )
          ])
        : _c("div", { staticClass: "template-centent" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.loadImageUpload,
                    expression: "loadImageUpload"
                  }
                ],
                staticClass: "image-area"
              },
              _vm._l(_vm.imageUpload, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "my-item",
                    attrs: { draggable: "true" },
                    on: {
                      click: function($event) {
                        return _vm.selecteImage(item)
                      },
                      dragend: _vm.dragEnd,
                      dragstart: function($event) {
                        return _vm.drag(item)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "item-mask" }),
                    _c("img", {
                      attrs: {
                        origin: "http://192.168.1.55:8080/",
                        src: item + "?imageMogr2/thumbnail/!50p"
                      }
                    })
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "updataButton" },
              [
                _c(
                  "upload-file",
                  { attrs: { img: true }, on: { addFile: _vm.addImgEvent } },
                  [_c("i", { staticClass: "el-icon-plus" })]
                )
              ],
              1
            )
          ]),
      _c("material-list", {
        ref: "material",
        on: {
          selecteImage: _vm.selecteImage,
          dragEnd: _vm.dragEnd,
          drag: _vm.drag
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }