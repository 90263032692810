<template>
  <div>
    <div class="title">组合</div>
    <div class="image-area" align="left">
      <div class="replace-img" @click="addNewGroup" v-if="!group.id">成组</div>
      <div class="replace-img" @click="split" v-if="group.id">拆分组</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {}
  },
  methods: {
    ...mapMutations('edit', ['addGroup', 'splitGroup']),
    addNewGroup () {
      this.addGroup()
    },
    split () {
      this.splitGroup()
    }
  },
  computed: {
    ...mapState('edit', ['group'])
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #2b2c2f;
}
.image-area {
  height: 80px;
  padding: 20px 0px 20px 0px;
  .replace-img {
    width: 350px;
    height: 40px;
    background: #2468f2;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
