<template>
  <div>
    <div
      class="button-area"
      :style="{
        transform: `scale(${moveScale})`,
        left: `${buttonArea.left}px`,
        top: `${buttonArea.top - 100 * moveScale}px`,
        transform: `scale(${moveScale}) translateX(-50%) `,
      }"
    >
      <div class="block">
        <span class="demonstration">缩放</span>
        <div class="slider">
          <el-slider
            @change="zoomChange"
            v-model="zoom"
            :max="200"
            :min="10"
          ></el-slider>
        </div>
      </div>
      <div class="button-item" @click="setCropImage(false)">
        <i class="el-icon-close"></i>
      </div>
      <div class="button-item" @click="changeImage">
        <i class="el-icon-check"></i>
      </div>
    </div>
    <div
      class="imageCrop"
      :style="{
        left: `${backImage.left}px`,
        top: `${backImage.top}px`,
        transform: `rotate(${image.rotate ? image.rotate : 0}deg)`,
        height: `${image.backImageHeight}px`,
        width: `${image.backImageWidth}px`,
      }"
      @mousedown="mousedown"
    >
      <div class="content" @click.stop>
        <div
          class="backImg"
          :style="{
            height: `${image.backImageHeight}px`,
            width: `${image.backImageWidth}px`,
          }"
        >
          <img :src="imgsrc" class="fullImg" />

          <div
            class="showImage"
            :style="{
              width: `${image.width}px`,
              height: `${image.height}px`,
              top: `${image.top}px`,
              left: `${image.left}px`,
            }"
          >
            <div class="img-content">
              <img
                class="back-img"
                :src="imgsrc"
                :style="{
                  width: `${image.backImageWidth}px`,
                  height: `${image.backImageHeight}px`,
                  top: `${-image.top}px`,
                  left: `${-image.left}px`,
                }"
              />
            </div>

            <div class="item-list">
              <div
                class="item"
                @mousedown.stop="controlshape('left-top')"
                :style="{
                  cursor: cursor.leftTop,
                  left: 0 + 'px',
                  top: 0 + 'px',
                  transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
                }"
              ></div>
              <div
                class="item-vertical"
                @mousedown.stop="controlshape('left-middle')"
                :style="{
                  cursor: cursor.leftMiddle,
                  left: 0 + 'px',
                  top: image.height / 2 + 'px',
                  transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
                }"
              ></div>
              <div
                class="item"
                @mousedown.stop="controlshape('left-down')"
                :style="{
                  cursor: cursor.leftDown,
                  left: 0 + 'px',
                  top: image.height + 'px',
                  transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
                }"
              ></div>
              <div
                class="item-Horizontal"
                @mousedown.stop="controlshape('middle-top')"
                :style="{
                  cursor: cursor.middleTop,
                  left: image.width / 2 + 'px',
                  top: 0 + 'px',
                  transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
                }"
              ></div>
              <div
                class="item-Horizontal"
                @mousedown.stop="controlshape('middle-down')"
                :style="{
                  cursor: cursor.middleDown,
                  left: image.width / 2 + 'px',
                  top: image.height + 'px',
                  transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
                }"
              ></div>
              <div
                class="item"
                @mousedown.stop="controlshape('right-top')"
                :style="{
                  cursor: cursor.rightTop,
                  left: image.width + 'px',
                  top: '0px',
                  transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
                }"
              ></div>
              <div
                class="item-vertical"
                @mousedown.stop="controlshape('right-middle')"
                :style="{
                  cursor: cursor.rightMiddle,
                  left: image.width + 'px',
                  top: image.height / 2 + 'px',
                  transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
                }"
              ></div>
              <div
                class="item"
                @mousedown.stop="controlshape('right-down')"
                :style="{
                  cursor: cursor.rightDown,
                  left: image.width + 'px',
                  top: image.height + 'px',
                  transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
let backImage = {
  top: 0,
  left: 0
}
let imageShowInfo = {}
export default {
  data () {
    return {
      image: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        backImageHeight: 0,
        backImageWidth: 0
      },
      imgsrc: '',
      backImage: {
        top: 0,
        left: 0
      },
      buttonArea: {
        top: 0,
        left: 0
      },
      zoom: 100
    }
  },
  /**
   * 图片裁剪初始化数据
   */
  created () {
    const showInfo = this.editModule.showInfo
    const showCenterX = this.editModule.left + this.editModule.width / 2
    const showCenterY = this.editModule.top + this.editModule.height / 2
    const comWidth = (showInfo.backImageWidth - showInfo.showWidth) / 2 - showInfo.backgroundPositionX
    const comHeigth = (showInfo.backImageHeight - showInfo.showHeight) / 2 - showInfo.backgroundPositionY
    const r = Math.sqrt(Math.pow(comWidth, 2) + Math.pow(comHeigth, 2))// 算半径
    // eslint-disable-next-line eqeqeq
    let oriAngle = comHeigth != 0 ? Math.atan(comHeigth / comWidth) * 180 / Math.PI : 0
    if (comWidth < 0) {
      oriAngle += 180
    }
    this.image.width = this.editModule.width
    this.image.height = this.editModule.height
    this.image.top = showInfo.backgroundPositionY + (showInfo.showHeight - this.editModule.height) / 2
    this.image.left = showInfo.backgroundPositionX + (showInfo.showWidth - this.editModule.width) / 2
    this.image.backImageHeight = showInfo.backImageHeight
    this.image.backImageWidth = showInfo.backImageWidth
    this.image.rotate = this.editModule.rotate
    this.imgsrc = this.editModule.src
    this.backImage.left = showCenterX + r * Math.cos((oriAngle + this.editModule.rotate) / 180 * Math.PI) - showInfo.backImageWidth / 2
    this.backImage.top = showCenterY + r * Math.sin((oriAngle + this.editModule.rotate) / 180 * Math.PI) - showInfo.backImageHeight / 2

    this.setCenterInfo()
    this.setButtonAreaPosition()
  },
  computed: {
    ...mapState('edit', ['scale', 'editModule']),
    moveScale () {
      return 100 / this.scale
    },
    cursor () {
      const result = {}
      if ((this.image.rotate < 25 && this.image.rotate >= 0) || (this.image.rotate < 360 && this.image.rotate >= 340)) {
        result.leftTop = 'nw-resize'
        result.middleTop = 'n-resize'
        result.rightTop = 'ne-resize'
        result.rightMiddle = 'e-resize'
        result.rightDown = 'nw-resize'
        result.middleDown = 'n-resize'
        result.leftDown = 'ne-resize'
        result.leftMiddle = 'e-resize'
      }
      if (this.image.rotate < 70 && this.image.rotate >= 25) {
        result.leftTop = 'n-resize'
        result.middleTop = 'ne-resize'
        result.rightTop = 'e-resize'
        result.rightMiddle = 'nw-resize'
        result.rightDown = 'n-resize'
        result.middleDown = 'ne-resize'
        result.leftDown = 'e-resize'
        result.leftMiddle = 'nw-resize'
      }
      if (this.image.rotate < 115 && this.image.rotate >= 70) {
        result.leftTop = 'ne-resize'
        result.middleTop = 'e-resize'
        result.rightTop = 'nw-resize'
        result.rightMiddle = 'n-resize'
        result.rightDown = 'ne-resize'
        result.middleDown = 'e-resize'
        result.leftDown = 'nw-resize'
        result.leftMiddle = 'n-resize'
      }
      if (this.image.rotate < 160 && this.image.rotate >= 115) {
        result.leftTop = 'e-resize'
        result.middleTop = 'nw-resize'
        result.rightTop = 'n-resize'
        result.rightMiddle = 'ne-resize'
        result.rightDown = 'e-resize'
        result.middleDown = 'nw-resize'
        result.leftDown = 'n-resize'
        result.leftMiddle = 'ne-resize'
      }
      if (this.image.rotate < 205 && this.image.rotate >= 160) {
        result.leftTop = 'nw-resize'
        result.middleTop = 'n-resize'
        result.rightTop = 'ne-resize'
        result.rightMiddle = 'e-resize'
        result.rightDown = 'nw-resize'
        result.middleDown = 'n-resize'
        result.leftDown = 'ne-resize'
        result.leftMiddle = 'e-resize'
      }
      if (this.image.rotate < 250 && this.image.rotate >= 205) {
        result.leftTop = 'n-resize'
        result.middleTop = 'ne-resize'
        result.rightTop = 'e-resize'
        result.rightMiddle = 'nw-resize'
        result.rightDown = 'n-resize'
        result.middleDown = 'ne-resize'
        result.leftDown = 'e-resize'
        result.leftMiddle = 'nw-resize'
      }
      if (this.image.rotate < 295 && this.image.rotate >= 250) {
        result.leftTop = 'ne-resize'
        result.middleTop = 'e-resize'
        result.rightTop = 'nw-resize'
        result.rightMiddle = 'n-resize'
        result.rightDown = 'ne-resize'
        result.middleDown = 'e-resize'
        result.leftDown = 'nw-resize'
        result.leftMiddle = 'n-resize'
      }
      if (this.image.rotate < 340 && this.image.rotate >= 295) {
        result.leftTop = 'e-resize'
        result.middleTop = 'nw-resize'
        result.rightTop = 'n-resize'
        result.rightMiddle = 'ne-resize'
        result.rightDown = 'e-resize'
        result.middleDown = 'nw-resize'
        result.leftDown = 'n-resize'
        result.leftMiddle = 'ne-resize'
      }
      return result
    }
  },
  methods: {
    ...mapMutations('edit', ['setCropImage', 'initGroupSize']),
    /**
     * 操作按钮的位置
     */
    setButtonAreaPosition () {
      const image = this.image
      const comWidth = (image.backImageWidth - image.width) / 2 - image.left
      const comHeigth = (image.backImageHeight - image.height) / 2 - image.top
      const r = Math.sqrt(Math.pow(comWidth, 2) + Math.pow(comHeigth, 2))// 算半径
      const imageR = Math.sqrt(Math.pow(image.width / 2, 2) + Math.pow(image.height / 2, 2))// 算半径
      // eslint-disable-next-line eqeqeq
      let oriAngle = comHeigth != 0 ? Math.atan(comHeigth / comWidth) * 180 / Math.PI : 0
      if (comWidth < 0) {
        oriAngle += 180
      }
      const centerAngle = Math.atan(image.height / image.width) * 180 / Math.PI
      const increaseTop = imageR * Math.abs(Math.sin((360 - centerAngle - this.editModule.rotate) / 180 * Math.PI)) - image.height / 2
      console.log(increaseTop)
      this.buttonArea.left = image.backImageWidth / 2 + this.backImage.left - r * Math.cos((oriAngle + this.editModule.rotate) / 180 * Math.PI)
      this.buttonArea.top = image.backImageHeight / 2 + this.backImage.top - r * Math.sin((oriAngle + this.editModule.rotate) / 180 * Math.PI) - image.height / 2 - Math.abs(increaseTop)
    },
    /**
     * 图片拖拽
     */
    mousedown () {
      const oriX = event.clientX
      const oriY = event.clientY
      const orileft = this.backImage.left
      const oritop = this.backImage.top
      const showLeft = this.image.left
      const showTop = this.image.top
      const cos = Math.cos(this.image.rotate / 180 * Math.PI)
      const sin = Math.sin(this.image.rotate / 180 * Math.PI)
      window.onmousemove = (event) => {
        const X = event.clientX
        const Y = event.clientY
        // +(X-oriX)*this.moveScale*cos +(Y-oriY)*this.moveScale*sin
        const newTop = showTop + (X - oriX) * this.moveScale * sin - (Y - oriY) * this.moveScale * cos
        const newLeft = showLeft - (X - oriX) * this.moveScale * cos - (Y - oriY) * this.moveScale * sin
        const backNewLeft = orileft + (X - oriX) * this.moveScale
        const backNewTop = oritop + (Y - oriY) * this.moveScale

        this.backImage.left = backNewLeft
        this.backImage.top = backNewTop
        this.image.left = newLeft
        this.image.top = newTop

        if (this.image.left < 0) {
          this.backImage.left += cos * this.image.left
          this.backImage.top += sin * this.image.left
          this.image.left = 0
        }
        if (this.image.top < 0) {
          this.backImage.left -= sin * this.image.top
          this.backImage.top += cos * this.image.top
          this.image.top = 0
        }
        if (this.image.left + this.image.width > this.image.backImageWidth) {
          this.backImage.left += cos * (this.image.left + this.image.width - this.image.backImageWidth)
          this.backImage.top += sin * (this.image.left + this.image.width - this.image.backImageWidth)
          this.image.left = this.image.backImageWidth - this.image.width
        }
        if (this.image.top + this.image.height > this.image.backImageHeight) {
          this.backImage.left -= sin * (this.image.top + this.image.height - this.image.backImageHeight)
          this.backImage.top += cos * (this.image.top + this.image.height - this.image.backImageHeight)
          this.image.top = this.image.backImageHeight - this.image.height
        }
      }
      window.onmouseup = (event) => {
        this.setCenterInfo()
        window.onmousemove = null
        window.onmouseup = null
      }
    },
    changeImage () {
      this.editModule.showInfo.backgroundPositionY = this.image.top
      this.editModule.showInfo.backgroundPositionX = this.image.left
      this.editModule.showInfo.showWidth = this.image.width
      this.editModule.showInfo.showHeight = this.image.height
      this.editModule.width = this.image.width
      this.editModule.height = this.image.height
      this.editModule.picRate = this.image.width / this.image.height // 长宽比
      this.setPosition()
      this.setCropImage(false)
      this.initGroupSize()
      this.$bus.$emit('initParameter')
    },
    setPosition () {
      const showInfo = this.image
      const comWidth = (showInfo.backImageWidth - showInfo.width) / 2 - showInfo.left
      const comHeigth = (showInfo.backImageHeight - showInfo.height) / 2 - showInfo.top
      const r = Math.sqrt(Math.pow(comWidth, 2) + Math.pow(comHeigth, 2))// 算半径
      // eslint-disable-next-line eqeqeq
      let oriAngle = comHeigth != 0 ? Math.atan(comHeigth / comWidth) * 180 / Math.PI : 0
      if (comWidth < 0) {
        oriAngle += 180
      }
      console.log(oriAngle)
      this.editModule.left = showInfo.backImageWidth / 2 + this.backImage.left - r * Math.cos((oriAngle + this.editModule.rotate) / 180 * Math.PI) - this.editModule.width / 2
      this.editModule.top = showInfo.backImageHeight / 2 + this.backImage.top - r * Math.sin((oriAngle + this.editModule.rotate) / 180 * Math.PI) - this.editModule.height / 2
      this.editModule.showInfo.backImageWidth = showInfo.backImageWidth
      this.editModule.showInfo.backImageHeight = showInfo.backImageHeight
    },
    /**
     * 控制点，8个点的操作均不相同
     */
    controlshape (direction) {
      const oriX = event.clientX
      const oriY = event.clientY
      const oriTop = this.image.top
      const oriLeft = this.image.left
      const width = this.image.width
      const height = this.image.height
      const cos = Math.cos(this.image.rotate / 180 * Math.PI)
      const sin = Math.sin(this.image.rotate / 180 * Math.PI)
      // eslint-disable-next-line eqeqeq
      if (direction == 'right-down') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newWidth = width + (X - oriX) * this.moveScale * cos + (Y - oriY) * this.moveScale * sin
          const newHeight = height - (X - oriX) * this.moveScale * sin + (Y - oriY) * this.moveScale * cos
          if (newWidth < 0) {
            this.image.width = 0
          } else if (newWidth <= this.image.backImageWidth - oriLeft) {
            this.image.width = newWidth
          }

          if (newHeight < 0) {
            this.image.height = 0
          } else if (newHeight < this.image.backImageHeight - oriTop) {
            this.image.height = newHeight
          }
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'right-middle') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newWidth = width + (X - oriX) * this.moveScale * cos + (Y - oriY) * this.moveScale * sin
          if (newWidth < 0) {
            this.image.width = 0
          } else if (newWidth <= this.image.backImageWidth - oriLeft) {
            this.image.width = newWidth
          }
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'right-top') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newTop = oriTop - (X - oriX) * this.moveScale * sin + (Y - oriY) * this.moveScale * cos
          const newWidth = width + (X - oriX) * this.moveScale * cos + (Y - oriY) * this.moveScale * sin
          const newHeight = height + (X - oriX) * this.moveScale * sin - (Y - oriY) * this.moveScale * cos
          if (newTop < 0) {
            this.image.top = 0
            this.image.height = height + oriTop
          } else if (newTop < this.image.backImageHeight && newHeight > 0) {
            this.image.top = newTop
            this.image.height = newHeight
          }
          if (newWidth < 0) {
            this.image.width = 0
          } else if (newWidth <= this.image.backImageWidth - oriLeft) {
            this.image.width = newWidth
          }
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'middle-down') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newHeight = height - (X - oriX) * this.moveScale * sin + (Y - oriY) * this.moveScale * cos
          if (newHeight < 0) {
            this.image.height = 0
          } else if (newHeight < this.image.backImageHeight - oriTop && newHeight > 0) {
            this.image.height = newHeight
          }
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'middle-top') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newTop = oriTop - (X - oriX) * this.moveScale * sin + (Y - oriY) * this.moveScale * cos
          const newHeight = height + (X - oriX) * this.moveScale * sin - (Y - oriY) * this.moveScale * cos
          if (newTop < 0) {
            this.image.top = 0
            this.image.height = height + oriTop
          } else if (newTop < this.image.backImageHeight && newHeight > 0) {
            this.image.top = newTop
            this.image.height = newHeight
          }
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'left-top') {
        window.onmousemove = (event) => {
          const Y = event.clientY
          const X = event.clientX
          const newLeft = oriLeft + (X - oriX) * this.moveScale * cos + (Y - oriY) * this.moveScale * sin
          const newTop = oriTop - (X - oriX) * this.moveScale * sin + (Y - oriY) * this.moveScale * cos
          const newWidth = width - (X - oriX) * this.moveScale * cos - (Y - oriY) * this.moveScale * sin
          const newHeight = height + (X - oriX) * this.moveScale * sin - (Y - oriY) * this.moveScale * cos
          if (newLeft < 0) {
            this.image.left = 0
            this.image.width = width + oriLeft
          } else if (newLeft < this.image.backImageWidth && newWidth > 0) {
            this.image.left = newLeft
            this.image.width = newWidth
          }
          if (newTop < 0) {
            this.image.top = 0
            this.image.height = height + oriTop
          } else if (newTop < this.image.backImageHeight && newHeight > 0) {
            this.image.top = newTop
            this.image.height = newHeight
          }
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'left-middle') {
        window.onmousemove = (event) => {
          console.log(width + oriX)
          const X = event.clientX
          const Y = event.clientY
          const newLeft = oriLeft + (X - oriX) * this.moveScale * cos + (Y - oriY) * this.moveScale * sin
          const newWidth = width - (X - oriX) * this.moveScale * cos - (Y - oriY) * this.moveScale * sin
          if (newLeft < 0) {
            this.image.left = 0
            this.image.width = width + oriLeft
          } else if (newLeft < this.image.backImageWidth && newWidth > 0) {
            this.image.left = newLeft
            this.image.width = newWidth
          }
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'left-down') {
        window.onmousemove = (event) => {
          const Y = event.clientY
          const X = event.clientX
          const newLeft = oriLeft + (X - oriX) * this.moveScale * cos + (Y - oriY) * this.moveScale * sin
          const newWidth = width - (X - oriX) * this.moveScale * cos - (Y - oriY) * this.moveScale * sin
          const newHeight = height - (X - oriX) * this.moveScale * sin + (Y - oriY) * this.moveScale * cos
          if (newLeft < 0) {
            this.image.left = 0
            this.image.width = width + oriLeft
          } else if (newLeft < this.image.backImageWidth && newWidth > 0) {
            this.image.left = newLeft
            this.image.width = newWidth
          }

          if (newHeight < 0) {
            this.image.height = 0
          } else if (newHeight < this.image.backImageHeight - oriTop) {
            this.image.height = newHeight
          }
        }
      }
      window.onmouseup = (event) => {
        window.onmousemove = null
        window.onmouseup = null
        this.setCenterInfo()
        this.setButtonAreaPosition()
      }
    },
    setCenterInfo () {
      const showInfo = this.editModule.showInfo
      backImage = {
        left: this.backImage.left + (this.image.backImageWidth - showInfo.backImageWidth) / 2,
        top: this.backImage.top + (this.image.backImageHeight - showInfo.backImageHeight) / 2
      }
      imageShowInfo = {
        top: this.image.top - (this.image.backImageHeight - showInfo.backImageHeight) / 2,
        left: this.image.left - (this.image.backImageWidth - showInfo.backImageWidth) / 2
      }
    },
    zoomChange () {
      // console.log(this.image.top<0||this.image.left<0||(this.image.left+this.image.width>image.backImageWidth)||(this.image.top+this.image.height>image.backImageHeight))
      if (this.image.top < 0 || this.image.left < 0 || (this.image.left + this.image.width > this.image.backImageWidth) || (this.image.top + this.image.height > this.image.backImageHeight)) {
        const t = setInterval(() => {
          if (!(this.image.top < 0 || this.image.left < 0 || (this.image.left + this.image.width > this.image.backImageWidth) || (this.image.top + this.image.height > this.image.backImageHeight))) {
            clearInterval(t)
            console.log('停止')
          }
          this.zoom += 5
        }, 1)
      }
    }
  },
  watch: {
    zoom (newV, oldV) {
      const showInfo = this.editModule.showInfo
      this.image.backImageWidth = showInfo.backImageWidth * newV / 100
      this.backImage.left = backImage.left - (this.image.backImageWidth - showInfo.backImageWidth) / 2
      this.image.backImageHeight = showInfo.backImageHeight * newV / 100
      this.backImage.top = backImage.top - (this.image.backImageHeight - showInfo.backImageHeight) / 2
      this.image.top = imageShowInfo.top + (this.image.backImageHeight - showInfo.backImageHeight) / 2
      this.image.left = imageShowInfo.left + (this.image.backImageWidth - showInfo.backImageWidth) / 2
    }
  }

}
</script>

<style lang="scss" scoped>
.item-list {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid rgb(0, 204, 255);
}
.fullImg {
  opacity: 0.7;
}
.back-img {
  position: absolute;
  overflow: hidden;
}
.imageCrop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.157);
  z-index: 999;
  cursor: move;
}
.backImg {
  position: absolute;
  left: 0;
  top: 0;
  img {
    width: 100%;
    height: 100%;
  }
  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.596);
    top: 0;
    left: 0;
  }
}
.showImage {
  position: absolute;
  background-repeat: no-repeat;
  z-index: 99;
  .img-content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    pointer-events: none;
  }
}
.item {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}
.item-Horizontal {
  position: absolute;
  width: 30px;
  height: 5px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}
.item-vertical {
  position: absolute;
  width: 5px;
  height: 30px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}

.button-area {
  position: absolute;
  background-color: white;
  height: 30px;
  width: 300px;
  display: flex;
  z-index: 2000;
  border-radius: 5px;
  box-shadow: 0 0 4px #888888;
  display: flex;
  align-items: center;
  .block {
    width: 250px;
    display: flex;
    align-items: center;
    .demonstration {
      margin-left: 10px;
      color: #000;
      font-size: 14px;
    }
    .slider {
      margin-left: 10px;
      width: 180px;
      float: right;
    }
  }
  .button-item {
    cursor: pointer;
    // background: rgb(61, 61, 61);
    width: 20px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    margin-right: 10px;
    color: rgb(1, 196, 255);
    font-weight: bolder;
    border-radius: 5px;
    float: left;
  }
}
</style>
