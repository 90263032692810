var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title", on: { click: _vm.addTitle } }, [
      _c("span", { attrs: { draggable: "true" } }, [_vm._v("添加正标题")])
    ]),
    _c(
      "div",
      {
        staticClass: "subtitle",
        attrs: { draggable: "true" },
        on: { click: _vm.addSubtitle }
      },
      [_c("span", { attrs: { draggable: "true" } }, [_vm._v("添加副标题")])]
    ),
    _c(
      "div",
      {
        staticClass: "content",
        attrs: { draggable: "true" },
        on: { click: _vm.addContent }
      },
      [_c("span", { attrs: { draggable: "true" } }, [_vm._v("添加正文")])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }