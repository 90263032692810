<template>
  <div>
    <div class="title">文本</div>
    <div class="font-adjust" align="left">
      <div class="font-size-input">
        <text-select></text-select>
      </div>
      <div class="font-size-input">
        <input-number
          :border="true"
          :min="6"
          @change="sizeChange"
          v-model="editModule.fontSize"
          tip="px"
        ></input-number>
      </div>
      <div class="font-statu">
        <div
          :class="[editModule.bold ? 'active' : '', 'item']"
          @click="editModule.bold = !editModule.bold"
        >
          <i class="icon iconfont icon-bold"></i>
        </div>
        <div
          :class="[editModule.italic ? 'active' : '', 'item']"
          @click="editModule.italic = !editModule.italic"
        >
          <i class="icon iconfont icon-zitixieti"></i>
        </div>
        <div
          class="item"
          :class="[editModule.underline ? 'active' : '', 'item']"
          @click="editModule.underline = !editModule.underline"
        >
          <i class="icon iconfont icon-zitixiahuaxian"></i>
        </div>
      </div>
      <div class="color-picker">
        <div
          class="showColor"
          @click="$refs.colorPicker.handleTrigger()"
          :style="{ background: editModule.color }"
        ></div>

        <div class="color-button">
          <color-picker
            ref="colorPicker"
            v-model="editModule.color"
            show-alpha
            size="mini"
            @change="debouncePushHistoryRecord"
            :predefine="predefineColors"
          ></color-picker>
        </div>
        <span class="tips">
          <img src="@/assets/img/colorslide.png" />
        </span>
      </div>
    </div>
    <div class="transparency">
      <div class="tips">不透明度</div>
      <div class="slider">
        <slider
          @change="debouncePushHistoryRecord"
          v-model="editModule.opacity"
        ></slider>
      </div>

      <div class="content">
        <input-number
          :min="0"
          @change="debouncePushHistoryRecord"
          v-model="editModule.opacity"
          :max="100"
        ></input-number>
      </div>
    </div>
    <div class="alignment">
      <div class="title">对齐</div>
      <div class="contentlist">
        <div
          :class="[editModule.align == 'left' ? 'active' : '', 'item']"
          @click="changeAlign('left')"
        >
          <i class="icon iconfont icon-zuoduiqi"></i>
        </div>
        <div
          :class="[editModule.align == 'center' ? 'active' : '', 'item']"
          @click="changeAlign('center')"
        >
          <i class="icon iconfont icon-juzhongduiqi"></i>
        </div>
        <div
          :class="[editModule.align == 'right' ? 'active' : '', 'item']"
          @click="changeAlign('right')"
        >
          <i class="icon iconfont icon-youduiqi"></i>
        </div>
      </div>
    </div>
    <div class="spacing">
      <div class="tips">行间距</div>
      <div class="slider">
        <slider
          :min="10"
          @change="sizeChange"
          v-model="lineHeight"
          :show-tooltip="false"
        ></slider>
      </div>

      <div class="content">{{ editModule.lineHeight }}倍</div>
    </div>
    <div class="transparency">
      <div class="tips">字间距</div>
      <div class="slider">
        <slider
          :max="1000"
          @change="sizeChange"
          v-model="editModule.letterSpacing"
          :show-tooltip="false"
        ></slider>
      </div>

      <div class="content">{{ editModule.letterSpacing | integer }}px</div>
    </div>
    <div class="position">
      <div class="title">位置尺寸</div>
      <div class="item">
        <div class="tips">位置</div>
        <div class="input">
          <div class="content">
            <input-number
              @change="sizeChange"
              :division="true"
              v-model="editModuleLeft"
              tip="X"
            ></input-number>
          </div>
        </div>
        <div class="connect"></div>
        <div class="input">
          <div class="content">
            <input-number
              @change="sizeChange"
              :division="true"
              v-model="editModuleTop"
              tip="Y"
            ></input-number>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="tips">尺寸</div>
        <div class="input">
          <div class="content">
            <input-number
              :min="editModule.fontSize"
              :division="true"
              @change="sizeChange"
              v-model="editModuleWidth"
              tip="px"
            ></input-number>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import InputNumber from '@c/InputNumber.vue'
import TextSelect from '../common/TextSelect.vue'
import _ from 'lodash'
import slider from '../common/slider'

export default {
  data () {
    return {
      color: '',
      value: 0,
      predefineColors: [
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)'
      ]
    }
  },
  mounted () {
    this.debouncePushHistoryRecord = _.debounce(() => {
      this.pushHistoryRecord()
    }, 1000)
  },
  components: {
    InputNumber,
    TextSelect,
    slider
  },
  computed: {
    ...mapState('edit', ['editModule']),
    lineHeight: {
      get () {
        return this.editModule.lineHeight * 10
      },
      set (value) {
        this.editModule.lineHeight = value / 10
      }
    },
    editModuleLeft: {
      get () {
        return Math.round(this.editModule.left)
      },
      set (value) {
        this.editModule.left = value
      }
    },
    editModuleTop: {
      get () {
        return Math.round(this.editModule.top)
      },
      set (value) {
        this.editModule.top = value
      }
    },
    editModuleWidth: {
      get () {
        return Math.round(this.editModule.width)
      },
      set (value) {
        this.editModule.width = value
      }
    }
  },
  methods: {
    ...mapMutations('edit', ['pushHistoryRecord', 'initGroupSize']),
    changeAlign (dir) {
      this.editModule.align = dir
      this.debouncePushHistoryRecord()
    },
    debouncePushHistoryRecord: function () { },
    sizeChange () {
      this.debouncePushHistoryRecord()
      this.initGroupSize()
      this.$bus.$emit('initParameter')
    }
  },
  watch: {
    'editModule.fontSize' (newValue, oldValue) {
      this.editModule.fontSize = Math.round(newValue)
    }
  },
  filters: {
    integer (val) {
      return Math.round(val)
    }
  }

}
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #2b2c2f;
}
.font-size-input {
  width: 345px;
  height: 44px;
  margin-top: 16px;
}

.font-statu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  width: 345px;
  height: 44px;
  background: #1d1e23;
  border: 2px solid #303235;
  border-radius: 4px;
  .item {
    cursor: pointer;
    flex: 1;
    color: #404144;
    border: 1px solid #303235;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  i {
    font-size: 24px;
  }
  .active {
    color: white;
  }
}
.color-picker {
  width: 341px;
  height: 40px;
  margin-top: 16px;
  background: #2b2c2f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  .showColor {
    width: 280px;
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
  }
  .tips {
    color: #ffffff;
    height: 24px;
    img {
      height: 24px;
    }
  }
  .color-button {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
}
.transparency {
  height: 70px;
  margin-top: 15px;
  // display: flex;
  align-items: center;
  // justify-content: space-around;
  display: flex;
  border-bottom: 1px solid #2b2c2f;
  padding-bottom: 30px;
  .tips {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    width: 60px;
  }
  .slider {
    width: 180px;
    margin: 20px;
  }
  .content {
    width: 64px;
    height: 32px;
    background: #1a1a1a;
    border: 1px solid #2468f2;
    color: white;
    text-align: center;
    line-height: 30px;
  }
}
/deep/ .el-input__inner {
  background: none;
}
.alignment {
  .title {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #8b909a;
    border: 0;
  }
  .contentlist {
    display: flex;
    .item {
      cursor: pointer;
      width: 72px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #969ba5;
      background: #404144;
      border-radius: 4px;
      margin-right: 27px;
      i {
        font-size: 30px;
      }
    }
    .active {
      color: white;
      background: #2468f2;
    }
  }
  .active {
    color: white;
  }
}
.spacing {
  height: 40px;
  margin-top: 15px;
  // display: flex;
  align-items: center;
  // justify-content: space-around;
  display: flex;
  .tips {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    width: 60px;
  }
  .slider {
    width: 180px;
    margin: 20px;
  }
  .content {
    width: 64px;
    height: 32px;
    background: #1a1a1a;
    border: 1px solid #2468f2;
    color: white;
    text-align: center;
    line-height: 30px;
  }
}
.position {
  .title {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #8b909a;
    border: 0;
  }
  .item {
    display: flex;
    align-items: center;
    height: 66px;
    .tips {
      width: 70px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .input {
      width: 120px;
      .content {
        width: 114px;
        height: 34px;
        background: #1d1e23;
        border: 2px solid #2468f2;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .connect {
      width: 54px;
    }
  }
}
</style>
