<template>
  <div class="item-list">
    <div
      class="item"
      v-if="module.type != 'text'"
      @mousedown.stop="controlshape('left-top')"
      :style="{
        cursor: cursor.leftTop,
        left: 0 + 'px',
        top: 0 + 'px',
        transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
      }"
    ></div>
    <div
      class="item-vertical"
      v-if="module.type != 'group' && module.type != 'code'"
      @mousedown.stop="controlshape('left-middle')"
      :style="{
        cursor: cursor.leftMiddle,
        left: 0 + 'px',
        top: module.height / 2 + 'px',
        transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
      }"
    ></div>
    <div
      class="item"
      v-if="module.type != 'text'"
      @mousedown.stop="controlshape('left-down')"
      :style="{
        cursor: cursor.leftDown,
        left: 0 + 'px',
        top: module.height + 'px',
        transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
      }"
    ></div>
    <div
      class="item-Horizontal"
      v-if="
        module.type != 'group' && module.type != 'code' && module.type != 'text'
      "
      @mousedown.stop="controlshape('middle-top')"
      :style="{
        cursor: cursor.middleTop,
        left: module.width / 2 + 'px',
        top: 0 + 'px',
        transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
      }"
    ></div>
    <div
      class="item-Horizontal"
      v-if="
        module.type != 'group' && module.type != 'code' && module.type != 'text'
      "
      @mousedown.stop="controlshape('middle-down')"
      :style="{
        cursor: cursor.middleDown,
        left: module.width / 2 + 'px',
        top: module.height + 'px',
        transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
      }"
    ></div>
    <div
      class="item"
      v-if="module.type != 'text'"
      @mousedown.stop="controlshape('right-top')"
      :style="{
        cursor: cursor.rightTop,
        left: module.width + 'px',
        top: '0px',
        transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
      }"
    ></div>
    <div
      class="item-vertical"
      v-if="module.type != 'group' && module.type != 'code'"
      @mousedown.stop="controlshape('right-middle')"
      :style="{
        cursor: cursor.rightMiddle,
        left: module.width + 'px',
        top: module.height / 2 + 'px',
        transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
      }"
    ></div>
    <div
      class="item"
      v-if="module.type != 'text'"
      @mousedown.stop="controlshape('right-down')"
      :style="{
        cursor: cursor.rightDown,
        left: module.width + 'px',
        top: module.height + 'px',
        transform: `translateX(-50%) translateY(-50%) scale(${moveScale})`,
      }"
    ></div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    module: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    }
  },
  data () {
    return {}
  },
  methods: {
    ...mapMutations('edit', ['pushHistoryRecord']),
    /**
     * 控制点拖拽操作
     */
    controlshape (direction) {
      const oriX = event.clientX
      const oriY = event.clientY
      const oriTop = this.module.top
      const oriLeft = this.module.left
      const width = this.module.width
      const height = this.module.height
      const rate = width / height
      const cos = Math.cos(this.module.rotate / 180 * Math.PI)
      const sin = Math.sin(this.module.rotate / 180 * Math.PI)
      let pushHistory = false

      // eslint-disable-next-line eqeqeq
      if (direction == 'right-down') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newWidth = width + (X - oriX) * this.moveScale * cos + (Y - oriY) * this.moveScale * sin
          this.module.width = newWidth > 20 ? newWidth : 20
          this.module.left = oriLeft - (this.module.width - width) * (1 - cos) / 2
          this.module.top = oriTop + (this.module.width - width) * sin / 2
          this.module.height = this.module.width / rate
          this.module.left = this.module.left - (this.module.height - height) * sin / 2
          this.module.top = this.module.top - (this.module.height - height) * (1 - cos) / 2
          pushHistory = true
          this.$bus.$emit('resetGroupItem')
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'right-middle') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newWidth = width + (X - oriX) * this.moveScale * cos + (Y - oriY) * this.moveScale * sin
          this.module.width = newWidth > 20 ? newWidth : 20
          this.module.left = oriLeft - (this.module.width - width) * (1 - cos) / 2
          this.module.top = oriTop + (this.module.width - width) * sin / 2
          pushHistory = true
          this.$bus.$emit('resetGroupItem')
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'right-top') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newWidth = width + (X - oriX) * this.moveScale * cos + (Y - oriY) * this.moveScale * sin
          this.module.width = newWidth > 20 ? newWidth : 20
          this.module.left = oriLeft - (this.module.width - width) * (1 - cos) / 2
          this.module.top = oriTop + (this.module.width - width) * sin / 2
          this.module.height = this.module.width / rate
          this.module.left = this.module.left + (this.module.height - height) * sin / 2
          this.module.top = this.module.top - (this.module.height - height) * (1 + cos) / 2
          pushHistory = true
          this.$bus.$emit('resetGroupItem')
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'middle-down') {
        window.onmousemove = (event) => {
          const Y = event.clientY
          const X = event.clientX
          const newHeight = height - (X - oriX) * this.moveScale * sin + (Y - oriY) * this.moveScale * cos
          this.module.height = newHeight > 40 ? newHeight : 40
          this.module.left = oriLeft - (this.module.height - height) * sin / 2
          this.module.top = oriTop - (this.module.height - height) * (1 - cos) / 2
          pushHistory = true
          this.$bus.$emit('resetGroupItem')
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'middle-top') {
        window.onmousemove = (event) => {
          const Y = event.clientY
          const X = event.clientX
          const newHeight = height + (X - oriX) * this.moveScale * sin - (Y - oriY) * this.moveScale * cos
          this.module.height = newHeight > 40 ? newHeight : 40
          this.module.left = oriLeft + (this.module.height - height) * sin / 2
          this.module.top = oriTop - (this.module.height - height) * (1 + cos) / 2
          pushHistory = true
          this.$bus.$emit('resetGroupItem')
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'left-top') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newWidth = width - (X - oriX) * this.moveScale * cos - (Y - oriY) * this.moveScale * sin
          this.module.width = newWidth > 20 ? newWidth : 20
          this.module.left = oriLeft - (this.module.width - width) * (cos + 1) / 2
          this.module.top = oriTop - (this.module.width - width) * sin / 2
          this.module.height = this.module.width / rate
          this.module.left = this.module.left + (this.module.height - height) * sin / 2
          this.module.top = this.module.top - (this.module.height - height) * (1 + cos) / 2
          pushHistory = true
          this.$bus.$emit('resetGroupItem')
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'left-middle') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newWidth = width - (X - oriX) * this.moveScale * cos - (Y - oriY) * this.moveScale * sin
          this.module.width = newWidth > 20 ? newWidth : 20
          this.module.left = oriLeft - (this.module.width - width) * (cos + 1) / 2
          this.module.top = oriTop - (this.module.width - width) * sin / 2
          pushHistory = true
          this.$bus.$emit('resetGroupItem')
        }
        // eslint-disable-next-line eqeqeq
      } else if (direction == 'left-down') {
        window.onmousemove = (event) => {
          const X = event.clientX
          const Y = event.clientY
          const newWidth = width - (X - oriX) * this.moveScale * cos - (Y - oriY) * this.moveScale * sin
          this.module.width = newWidth > 20 ? newWidth : 20
          this.module.left = oriLeft - (this.module.width - width) * (cos + 1) / 2
          this.module.top = oriTop - (this.module.width - width) * sin / 2
          this.module.height = this.module.width / rate
          this.module.left = this.module.left - (this.module.height - height) * sin / 2
          this.module.top = this.module.top - (this.module.height - height) * (1 - cos) / 2
          pushHistory = true
          this.$bus.$emit('resetGroupItem')
        }
      }

      window.onmouseup = (event) => {
        window.onmousemove = null
        window.onmouseup = null
        this.module.left = Math.round(this.module.left)
        this.module.height = Math.round(this.module.height)
        this.module.width = Math.round(this.module.width)
        this.module.top = Math.round(this.module.top)
        if (pushHistory) {
          this.pushHistoryRecord()
        }
      }
    }

  },
  computed: {
    ...mapState('edit', ['scale']),
    moveScale () {
      return 100 / this.scale
    },
    cursor () {
      const result = {}
      if ((this.module.rotate < 25 && this.module.rotate >= 0) || (this.module.rotate < 360 && this.module.rotate >= 340)) {
        result.leftTop = 'nw-resize'
        result.middleTop = 'n-resize'
        result.rightTop = 'ne-resize'
        result.rightMiddle = 'e-resize'
        result.rightDown = 'nw-resize'
        result.middleDown = 'n-resize'
        result.leftDown = 'ne-resize'
        result.leftMiddle = 'e-resize'
      }
      if (this.module.rotate < 70 && this.module.rotate >= 25) {
        result.leftTop = 'n-resize'
        result.middleTop = 'ne-resize'
        result.rightTop = 'e-resize'
        result.rightMiddle = 'nw-resize'
        result.rightDown = 'n-resize'
        result.middleDown = 'ne-resize'
        result.leftDown = 'e-resize'
        result.leftMiddle = 'nw-resize'
      }
      if (this.module.rotate < 115 && this.module.rotate >= 70) {
        result.leftTop = 'ne-resize'
        result.middleTop = 'e-resize'
        result.rightTop = 'nw-resize'
        result.rightMiddle = 'n-resize'
        result.rightDown = 'ne-resize'
        result.middleDown = 'e-resize'
        result.leftDown = 'nw-resize'
        result.leftMiddle = 'n-resize'
      }
      if (this.module.rotate < 160 && this.module.rotate >= 115) {
        result.leftTop = 'e-resize'
        result.middleTop = 'nw-resize'
        result.rightTop = 'n-resize'
        result.rightMiddle = 'ne-resize'
        result.rightDown = 'e-resize'
        result.middleDown = 'nw-resize'
        result.leftDown = 'n-resize'
        result.leftMiddle = 'ne-resize'
      }
      if (this.module.rotate < 205 && this.module.rotate >= 160) {
        result.leftTop = 'nw-resize'
        result.middleTop = 'n-resize'
        result.rightTop = 'ne-resize'
        result.rightMiddle = 'e-resize'
        result.rightDown = 'nw-resize'
        result.middleDown = 'n-resize'
        result.leftDown = 'ne-resize'
        result.leftMiddle = 'e-resize'
      }
      if (this.module.rotate < 250 && this.module.rotate >= 205) {
        result.leftTop = 'n-resize'
        result.middleTop = 'ne-resize'
        result.rightTop = 'e-resize'
        result.rightMiddle = 'nw-resize'
        result.rightDown = 'n-resize'
        result.middleDown = 'ne-resize'
        result.leftDown = 'e-resize'
        result.leftMiddle = 'nw-resize'
      }
      if (this.module.rotate < 295 && this.module.rotate >= 250) {
        result.leftTop = 'ne-resize'
        result.middleTop = 'e-resize'
        result.rightTop = 'nw-resize'
        result.rightMiddle = 'n-resize'
        result.rightDown = 'ne-resize'
        result.middleDown = 'e-resize'
        result.leftDown = 'nw-resize'
        result.leftMiddle = 'n-resize'
      }
      if (this.module.rotate < 340 && this.module.rotate >= 295) {
        result.leftTop = 'e-resize'
        result.middleTop = 'nw-resize'
        result.rightTop = 'n-resize'
        result.rightMiddle = 'ne-resize'
        result.rightDown = 'e-resize'
        result.middleDown = 'nw-resize'
        result.leftDown = 'n-resize'
        result.leftMiddle = 'ne-resize'
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  z-index: 2000;
}
.item-Horizontal {
  position: absolute;
  width: 30px;
  height: 5px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  z-index: 2000;
}
.item-vertical {
  position: absolute;
  width: 5px;
  height: 30px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  z-index: 2000;
}
</style>
