var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pay-content" }, [
    _c("div", { staticClass: "title" }, [_vm._v("请使用微信扫码支付")]),
    _c("div", { staticClass: "code-area" }, [
      _c("div", { staticClass: "code" }, [
        _vm.payType == 1
          ? _c("img", { attrs: { src: _vm.src, alt: "加载中" } })
          : _vm._e(),
        _vm.payType == 2 ? _c("span", [_vm._v("暂不支持支付宝支付")]) : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "button-area" }, [
      _c("div", { staticClass: "radio-group" }, [
        _c("div", { class: ["radio", { active: _vm.payType == 1 }] }, [
          _vm._v("微信支付")
        ]),
        false
          ? _c(
              "div",
              {
                class: ["radio", { active: _vm.payType == 2 }],
                on: {
                  click: function($event) {
                    return _vm.selectPayWay(2)
                  }
                }
              },
              [_vm._v(" 支付宝 ")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }