var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "navigation",
      style: { width: _vm.postItemWidth + 20 + "px" }
    },
    [
      _c("i", {
        staticClass: "el-icon-full-screen open",
        on: {
          click: function($event) {
            _vm.shrink = false
          }
        }
      }),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.shrink,
            expression: "!shrink"
          }
        ],
        staticClass: "el-icon-circle-close close",
        on: {
          click: function($event) {
            _vm.shrink = true
          }
        }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.shrink,
              expression: "!shrink"
            }
          ],
          staticClass: "postShow",
          style: {
            height:
              (_vm.postInfo.canvas.height * _vm.postItemWidth) /
                _vm.postInfo.canvas.width +
              "px"
          }
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.shrink,
                expression: "!shrink"
              }
            ],
            staticClass: "view-port",
            style: {
              width: _vm.viewWidth + 160 * _vm.scaleRate + "px",
              height: _vm.viewHeight + 80 * _vm.scaleRate + "px",
              top: _vm.top + "px",
              left: _vm.left + "px"
            },
            on: { mousedown: _vm.mousedown }
          }),
          _c("post-item", {
            attrs: { postItemWidth: _vm.postItemWidth, postInfo: _vm.postInfo }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }