<template>
  <div
    @mouseenter="showTips = true"
    @mouseout="showTips = false"
    :class="[
      'codeContent',
      { lock: code.lock && code.id == editSelectedId && !display },
      { selected: code.id == editSelectedId && !code.lock && !display },
      { codehover: code.id != editSelectedId },
    ]"
    @mousedown="mousedown"
    :style="{
      left: `${code.left}px`,
      top: `${code.top}px`,
      zIndex: code.ZIndex + editZindex,
      transform: `rotate(${code.rotate ? code.rotate : 0}deg)`,
      width: `${code.width}px`,
      height: `${code.height}px`,
      borderWidth: `${moveScale}px`,
    }"
  >
    <div
      class="tips"
      :style="{
        transform: `translateY(-${
          40 + 20 * (moveScale - 1)
        }px) translateX(-50%) scale(${
          showTips && code.id != editSelectedId ? moveScale : 0
        })`,
      }"
    >
      点击编辑二维码
    </div>
    <div ref="qrcode" v-if="display" class="qrcode" draggable="false"></div>
    <div
      ref="qrcode"
      v-if="!display"
      :style="{ opacity: 0 }"
      class="qrcode"
      draggable="false"
    ></div>
    <div class="control" style="border: 0px"></div>

    <regulator
      :module="code"
      v-show="
        code.id == editSelectedId &&
        !code.lock &&
        !display &&
        !code.groupId &&
        !group
      "
    ></regulator>
    <rotate
      v-if="
        code.id == editSelectedId &&
        !display &&
        !code.lock &&
        !code.groupId &&
        !group
      "
      :module="code"
    ></rotate>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import QRCode from 'qrcodejs2'
import Rotate from './Rotate'
import Regulator from './Regulator.vue'
import _ from 'lodash'
export default {
  props: {
    code: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    },
    display: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.debounceCreateQRCode = _.debounce(() => {
      this.createQRCode()
    }, 500)
  },
  mounted () {
    this.createQRCode()
  },
  data () {
    return {
      showTips: false
    }
  },
  components: {
    Rotate,
    Regulator
  },
  computed: {
    ...mapState('edit', ['editSelectedId', 'scale', 'group']),
    moveScale () {
      return 100 / this.scale
    },
    groupSelected () {
      if (!this.group) {
        return false
      }
      if (this.group.layerIds.findIndex((item) => item === this.code.id) !== -1) {
        return true
      }
      return false
    },
    editZindex () {
      if (!this.display && (this.code.id == this.editSelectedId || this.groupSelected)) {
        return 200
      }
      return 0
    }

  },
  watch: {
    'code.width' (newValue, oldValue) {
      this.debounceCreateQRCode()
    },
    'code.url' (newValue, oldValue) {
      this.createQRCode()
    },
    'code.rotate' (newValue, oldValue) {
    }
  },
  methods: {
    createQRCode () {
      this.$refs.qrcode.innerHTML = ''
      // eslint-disable-next-line no-new
      new QRCode(this.$refs.qrcode, {
        text: this.code.url,
        width: this.code.width - 10 > 1000 ? 1000 : this.code.width - 10,
        height: this.code.height - 10 > 1000 ? 1000 : this.code.height - 10,
        colorDark: this.code.colorDark, // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L// 容错率，L/M/H
      })
      this.$refs.qrcode.querySelector('img').style.width = '100%'
      this.$refs.qrcode.querySelector('img').style.height = '100%'
    },
    selectThis () {
      // this.setEditSelectedId(this.code.imageId)
      // if (event.ctrlKey || event.shiftKey) {
      //   this.setEditModuleList(this.code)
      //   return
      // }
      this.setEditModule(this.code)
    },
    ...mapMutations('edit', ['pushHistoryRecord', 'setEditModuleList', 'setEditModule', 'setGroupModule', 'initGroupSize']),
    mousedown () {
      let module = this.code
      if (this.code.lock) {
        if (!this.group) {
          this.setEditModule(this.code)
        }
        return
      }
      if (event.ctrlKey || event.shiftKey) {
        this.setEditModuleList(this.code)
        return
      }
      if (this.code.groupId) {
        this.setGroupModule(this.code.groupId)
        this.initGroupSize()

        this.selectThis()

        module = this.group
      }
      if (this.group && this.group.layerIds.indexOf(this.code.id) !== -1) {
        module = this.group
      } else {
        this.selectThis()
      }

      const oriX = event.clientX
      const oriY = event.clientY
      const orileft = module.left
      const oritop = module.top
      let pushHistory = false
      window.onmousemove = (event) => {
        const X = event.clientX
        const Y = event.clientY
        module.left = orileft + (X - oriX) * this.moveScale
        module.top = oritop + (Y - oriY) * this.moveScale
        // console.log(this.top)
        pushHistory = true
        this.$bus.$emit('resetGroupItem')
      }
      window.onmouseup = (event) => {
        window.onmousemove = null
        // if(pushHistory){
        module.left = Math.round(module.left)
        module.height = Math.round(module.height)
        module.width = Math.round(module.width)
        module.top = Math.round(module.top)
        if (pushHistory) {
          this.pushHistoryRecord()
        } else {
          this.selectThis()
        }

        window.onmouseup = null
        // }
      }
    }

  }

}
</script>
<style lang="scss" scoped>
.lock {
  border: solid 1px red;
}
.codehover {
  &:hover {
    border: 1px solid rgb(0, 81, 255);
  }
}
.selected {
  border: 1px solid blue;
}
.codeContent {
  position: absolute;
  display: inline-block;
  cursor: move;
  top: 0px;
  left: 0px;
  padding: 5px;
  border-radius: 5px;

  .tips {
    text-align: center;
    width: 120px;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.8);
    display: inline-block;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 0;
    transition: 0.2s;
  }
  // border: 1px solid black;
}
.control {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // bottom: 0;
  // right: 0;
  z-index: 99;
  border: 1px solid rgb(0, 247, 255);
}
.item {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}
</style>
