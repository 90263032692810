<template>
  <div class="quik-content">
    <div class="title">
      <div class="title-info">快捷键说明</div>
      <i
        class="icon iconfont icon-shouqi delete-icon"
        @click="$emit('close')"
      ></i>
    </div>
    <div class="tips-area">
      <div class="tips-title">常用操作</div>
      <div class="tips">
        <div class="item">
          <div class="name">保存</div>
          <div class="icon">ctrl+s</div>
        </div>
        <div class="item">
          <div class="name">删除</div>
          <div class="icon">delete/backspace</div>
        </div>
        <div class="item">
          <div class="name">快速移动</div>
          <div class="icon">↑↓←→</div>
        </div>
        <div class="item">
          <div class="name">移动</div>
          <div class="icon">shift+↑↓←→</div>
        </div>
        <div class="item">
          <div class="name">撤销</div>
          <div class="icon">Ctrl+Z</div>
        </div>
        <div class="item">
          <div class="name">重做</div>
          <div class="icon">Ctrl+Shift+Z</div>
        </div>
        <div class="item">
          <div class="name">复制</div>
          <div class="icon">Ctrl+C</div>
        </div>
        <div class="item">
          <div class="name">粘贴</div>
          <div class="icon">Ctrl+V</div>
        </div>
        <div class="item">
          <div class="name">剪切</div>
          <div class="icon">Ctrl+X</div>
        </div>
      </div>

      <div class="tips-title">图层元素</div>
      <div class="tips">
        <div class="item">
          <div class="name">上移一层</div>
          <div class="icon">Ctrl+]</div>
        </div>
        <div class="item">
          <div class="name">移动到顶层</div>
          <div class="icon">Ctrl+shift+]</div>
        </div>
        <div class="item">
          <div class="name">下移一层</div>
          <div class="icon">Ctrl+[</div>
        </div>
        <div class="item">
          <div class="name">移动到底层</div>
          <div class="icon">Ctrl+shift+[</div>
        </div>
        <div class="item">
          <div class="name">全选</div>
          <div class="icon">Ctrl+A</div>
        </div>
        <div class="item">
          <div class="name">全选</div>
          <div class="icon">Ctrl/Shift+鼠票点击元素</div>
        </div>
        <div class="item">
          <div class="name">创建组合</div>
          <div class="icon">Ctrl+ G</div>
        </div>
        <div class="item">
          <div class="name">解开组合</div>
          <div class="icon">Ctrl+Shift+G</div>
        </div>
        <div class="item">
          <div class="name">锁定/取消锁定</div>
          <div class="icon">Ctrl+L</div>
        </div>
      </div>

      <div class="tips-title">画布</div>
      <div class="tips">
        <div class="item">
          <div class="name">画布放大缩小</div>
          <div class="icon">Ctrl+ 鼠标滚轮</div>
        </div>
        <div class="item">
          <div class="name">适应窗口</div>
          <div class="icon">Ctrl+0</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods () {

  }
}
</script>

<style lang="scss" scoped>
.title {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: linear-gradient(90deg, #3c74f1, #11abff, #81afd6);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  line-height: 34px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}
.quik-content {
  position: absolute;
  bottom: 10px;
  left: 74px;
  border-radius: 10px;
  width: 300px;
  z-index: 999;
  overflow: hidden;
  height: 400px;
  background-color: white;
  box-shadow: 0px 5px 15px 0px rgba(35, 68, 140, 0.12);
}
.tips-title {
  margin-top: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  width: 71px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #000000;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    content: "";
    display: inline-block;
    width: 3px;
    height: 12px;
    background-color: #3c74f1ff;
  }
}
.tips {
  background: #f2f6fe;
  border-radius: 8px;
  margin: 10px;
  overflow: hidden;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid rgba(202, 202, 202, 0.342);

    .name {
      font-size: 12px;
      font-weight: 400;
      color: #000000;
    }
    .icon {
      height: 20px;
      font-size: 12px;
      background: #ffffff;
      border: 1px solid #e2e7f2;
      box-shadow: 0px 2px 5px 0px #e1e5e9;
      border-radius: 4px;
      color: #000000;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
.tips-area {
  overflow: scroll;
  height: 370px;
}
.delete-icon {
  cursor: pointer;
}
</style>