<template>
  <div
    ref="post"
    @drop="drop"
    @dragover="allowDrop"
    @click.stop
    class="canvas-area"
    :style="{
      width: postInfo.canvas.width + 'px',
      height: postInfo.canvas.height + 'px',
      borderWidth: `${(80 / scale) * 100}px ${(80 / scale) * 100}px ${
        (80 / scale) * 100
      }px ${(80 / scale) * 100}px`,
      transform: `scale(${scale / 100},${scale / 100}) `,
      transition: `${anime ? 0.5 : 0}s`,
      left: `${canvasLeft}px`,
      top: `${canvasTop}px`,
    }"
  ></div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import model from '@/lib/model'
import panel from './common/panel'
function getFormatImageSrc (src) {
  src = src.replace('https://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-test-1257418739.file.myqcloud.com')
  src = src.replace('http://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-test-1257418739.file.myqcloud.com')
  src = src.replace('https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-prod-1257418739.file.myqcloud.com')
  src = src.replace('http://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-prod-1257418739.file.myqcloud.com')
  return src
}
export default {
  data () {
    return {
    }
  },
  props: {
    anime: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  computed: {
    ...mapState('edit', ['scale', 'postInfo', 'editModuleList', 'cropImage', 'canvas', 'editSize']),
    canvasTop: panel.canvasTop,
    canvasLeft: panel.canvasLeft
  },
  methods: {
    ...mapMutations('edit', ['setBackEditModule', 'setCropImage', 'addImage', 'setEditModule', 'addCode', 'pushHistoryRecord']),
    /**
     * 拖拽
     */
    async drop (e) {
      let code = e.dataTransfer.getData('code')
      const imgUrl = e.dataTransfer.getData('imgUrl')
      if (code) {
        code = JSON.parse(code)
        code.left = e.offsetX - 50
        code.top = e.offsetY - 50
        const param = model.createCode(code)
        this.addCode(param)
        this.setEditModule(param)
        this.pushHistoryRecord()
      }
      if (imgUrl) {
        // const img = new Image()

        let src = getFormatImageSrc(e.dataTransfer.getData('imgUrl'))
        const size = await model.getAddImageSize(src)

        const imgInfo = model.createImage({
          src: src,
          height: size.height,
          width: size.width,
          left: e.offsetX - size.width / 2,
          top: e.offsetY - size.height / 2
        })

        this.addImage(imgInfo)
        this.setEditModule(imgInfo)
        this.pushHistoryRecord()
      }
    },
    allowDrop (e) {
      e.preventDefault()
    }
  }

}
</script>

<style lang="scss" scoped>
.canvas-area {
  position: absolute;
  transform-origin: left top;
  margin-bottom: 30px;
  box-sizing: content-box;
  border-style: solid;
  border-color: #f4f4fb;
  .background {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
