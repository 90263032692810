<template>
  <div class="imageblock">
    <div class="title">
      <div class="type">{{ name }}</div>
      <div class="more" @click="showMore">
        更多<i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="image-list">
      <div
        class="image-item"
        v-for="(item, index) in imageList"
        :key="index"
        @click="$emit('selecteImage', item.fileUrl)"
        draggable="true"
        @dragend="$emit('dragEnd')"
        @dragstart="$emit('drag', item.fileUrl)"
      >
        <div class="mask"></div>
        <img :src="`${item.fileUrl}?imageMogr2/thumbnail/!50p`" />
      </div>
    </div>
  </div>
</template>

<script>
import { sysmaterialpage } from '@/api/cubeclient'
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "img"
    },
    classificationId: {
      type: String,
      default: "img"
    }
  },
  data () {
    return {
      imageList: []
    }
  },
  async mounted () {
    let res = await sysmaterialpage({
      classificationId: this.classificationId,
      // title: this.name,
      pageNo: 0,
      pageSize: 4,
      type: this.type
    })
    this.imageList = res.data.list
  },
  methods: {
    showMore () {

      this.$emit("showMaterialList", {
        name: this.name,
        type: this.type,
        classificationId: this.classificationId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.imageblock {
  width: 320px;
  display: block;
  float: left;
  margin-bottom: 30px;
}
.title {
  width: 290px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid grey;
}
.type {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  display: inline-block;
  float: left;
}
.more {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4e5258;
  display: inline-block;
  float: right;
  cursor: pointer;
}
.image-item {
  cursor: pointer;
  float: left;
  width: 136px;
  height: 136px;
  margin: 20px 20px 0 0;
  border-radius: 5px;
  background-color: #f6f6f6;
  position: relative;
  .mask {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: 1;
    &:hover {
      background-color: #0a40ad1c;
    }
  }
  img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left: 0px;
    right: 0px;
  }
}
</style>