<template>
  <div
    @mouseenter="showTips = true"
    @mouseout="showTips = false"
    @keydown.stop
    :class="[
      {
        dotted: !textInfo.lock && textInfo.id == editSelectedId && !display,
        lock: textInfo.lock && textInfo.id == editSelectedId && !display,
      },
      'text-content',
    ]"
    @mousedown="mousedown"
    :style="{
      width: textInfo.width + 'px',
      height: `${fontScale != 1 ? textInfo.height * fontScale + 'px' : 'auto'}`,
      left: textInfo.left + 'px',
      top: textInfo.top + 'px',
      zIndex: textInfo.ZIndex + editZindex,
      transform: `rotate(${textInfo.rotate ? textInfo.rotate : 0}deg)`,
      borderWidth: `${moveScale}px`,
    }"
  >
    <div
      class="tips"
      :style="{
        transform: `translateY(-${
          40 + 20 * (moveScale - 1)
        }px)  translateX(-50%) scale(${
          showTips && !textInfo.lock && !contenteditable ? moveScale : 0
        })`,
        lineHeight: 1,
      }"
    >
      双击编辑文字
    </div>
    <div
      class="tips"
      :style="{
        transform: `translateY(-${
          40 + 20 * (moveScale - 1)
        }px)  translateX(-50%) scale(${
          showTips && textInfo.lock && textInfo.id != editSelectedId
            ? moveScale
            : 0
        })`,
        lineHeight: 1,
      }"
    >
      单击编辑文字
    </div>
    <div
      :style="{
        letterSpacing: textInfo.letterSpacing + 'px',
      }"
    >
      <div
        :style="{
          fontSize: textInfo.fontSize + 'px',
          fontWeight: textInfo.bold ? 900 : 400,
          fontStyle: textInfo.italic ? 'italic' : 'normal',
          textAlign: textInfo.align,
          lineHeight: textInfo.lineHeight,
        }"
      >
        <div
          class="content"
          v-if="display"
          v-html="textInfo.html"
          :contenteditable="!textInfo.lock"
          :style="{
            opacity: textInfo.opacity / 100,
            fontFamily: fontFullName,
            transform: `scale(${fontScale})`,
            width: `${textInfo.width / fontScale}px`,
            textDecoration: textInfo.underline ? 'underline' : 'none',
            color: textInfo.color,
          }"
          ref="contentEle"
          @input="changeText"
          @blur="commitText"
        ></div>
        <div
          class="content"
          v-if="!display"
          v-html="html"
          :contenteditable="!textInfo.lock && contenteditable"
          :style="{
            color: 'rgba(0,0,0,0)',
            caretColor: textInfo.color,
            cursor: contenteditable ? 'text' : 'move',
            fontFamily: fontFullName,
            transform: `scale(${fontScale})`,
            width: `${textInfo.width / fontScale}px`,
          }"
          ref="contentEle"
          @input="changeText"
          @dblclick="selectContent"
          @blur="commitText"
        ></div>
      </div>
    </div>

    <regulator
      :module="textInfo"
      v-show="
        textInfo.id == editSelectedId &&
        !textInfo.lock &&
        !display &&
        !textInfo.groupId &&
        !group
      "
    ></regulator>
    <rotate
      v-if="
        textInfo.id == editSelectedId &&
        !display &&
        !textInfo.lock &&
        !textInfo.groupId &&
        !group
      "
      :module="textInfo"
    ></rotate>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Rotate from './Rotate'
// import Tool from '@/lib/tool'
import Regulator from './Regulator.vue'
export default {
  props: {
    textInfo: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    },
    display: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contenteditable: false,
      html: '',
      showTips: false
    }
  },
  mounted () {
    this.textInfo.height = this.$refs.contentEle.clientHeight
    this.html = this.textInfo.html
    //  console.log(this.display,this.textInfo.id==this.editSelectedId)
    // eslint-disable-next-line eqeqeq
    if (!this.display && this.textInfo.id == this.editSelectedId) {
      this.contenteditable = true
      this.$nextTick(() => {
        this.$refs.contentEle.focus()
        document.execCommand('selectAll')
      })
    }
  },
  components: {
    Rotate,
    Regulator
  },
  computed: {
    ...mapState('edit', ['editSelectedId', 'scale', 'group']),
    fontFullName () {
      // return Tool.getFontFullName(this.textInfo.fontFamily,this.textInfo.fontStyle)
      return this.textInfo.fontFamily
    },
    moveScale () {
      return 100 / this.scale
    },

    fontScale () {
      const fontSize = this.textInfo.fontSize
      if (fontSize < 12) {
        return fontSize / 12
      }
      return 1
    },
    groupSelected () {
      if (!this.group) {
        return false
      }
      if (this.group.layerIds.findIndex((item) => item === this.textInfo.id) !== -1) {
        return true
      }
      return false
    },
    editZindex () {
      if (!this.display && (this.textInfo.id == this.editSelectedId || this.groupSelected)) {
        return 200
      }
      return 0
    }
  },
  watch: {
    'textInfo.width': function (newValue, oldValue) {
      this.$nextTick(() => {
        this.textInfo.height = this.$refs.contentEle.clientHeight
      })
    },
    'textInfo.letterSpacing': function (newValue, oldValue) {
      this.$nextTick(() => {
        this.textInfo.height = this.$refs.contentEle.clientHeight
      })
    },
    'textInfo.lineHeight': function (newValue, oldValue) {
      this.$nextTick(() => {
        this.textInfo.height = this.$refs.contentEle.clientHeight
      })
    },
    'textInfo.fontSize': function (newValue, oldValue) {
      this.$nextTick(() => {
        this.textInfo.height = this.$refs.contentEle.clientHeight
      })
    }
  },
  methods: {
    ...mapMutations('edit', ['setEditSelectedId', 'setEditModule', 'pushHistoryRecord', 'setEditModuleList', 'setGroupModule', 'initGroupSize']),

    selectContent () {
      this.html = this.textInfo.html
      this.contenteditable = true
      this.$nextTick(() => {
        this.$refs.contentEle.focus()
        document.execCommand('selectAll')
      })
    },
    selectThis () {
      // if (event.ctrlKey || event.shiftKey) {
      //   this.setEditModuleList(this.textInfo)
      //   return
      // }
      this.setEditModule(this.textInfo)
    },
    commitText () {
      this.contenteditable = false
      // eslint-disable-next-line eqeqeq
      if (this.textInfo.text == this.$refs.contentEle.innerHTML) {
        this.textInfo.html = this.$refs.contentEle.innerHTML
        this.textInfo.text = this.$refs.contentEle.innerText
        this.pushHistoryRecord()
        window.Text = JSON.stringify(this.textInfo)
      }
    },
    changeText () {
      this.textInfo.height = this.$refs.contentEle.clientHeight
      this.textInfo.html = this.$refs.contentEle.innerHTML
      this.textInfo.text = this.$refs.contentEle.innerText
      this.initGroupSize()
      this.$bus.$emit('initParameter')
    },
    mousedown () {
      let module = this.textInfo
      if (this.textInfo.lock || this.contenteditable) {
        if (!this.group) {
          this.setEditModule(this.textInfo)
        }
        return
      }
      if (event.ctrlKey || event.shiftKey) {
        this.setEditModuleList(this.textInfo)
        return
      }
      if (this.textInfo.groupId) {
        this.setGroupModule(this.textInfo.groupId)
        this.initGroupSize()

        this.selectThis()

        module = this.group
      }
      if (this.group && this.group.layerIds.indexOf(this.textInfo.id) !== -1) {
        module = this.group
      } else {
        this.selectThis()
      }

      const oriX = event.clientX
      const oriY = event.clientY
      const orileft = module.left
      const oritop = module.top
      let pushHistory = false
      window.onmousemove = (event) => {
        const X = event.clientX
        const Y = event.clientY
        module.left = orileft + (X - oriX) * this.moveScale
        module.top = oritop + (Y - oriY) * this.moveScale
        if (Y - oriY || X - oriX) {
          pushHistory = true
        }
        this.$bus.$emit('resetGroupItem')
      }
      window.onmouseup = (event) => {
        window.onmousemove = null
        window.onmouseup = null
        module.left = Math.round(module.left)
        module.top = Math.round(module.top)
        if (pushHistory) {
          this.pushHistoryRecord()
        } else {
          this.selectThis()
        }
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.item {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}
.item-Horizontal {
  position: absolute;
  width: 30px;
  height: 5px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}
.item-vertical {
  position: absolute;
  width: 5px;
  height: 6px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}
.dotted {
  border: solid 1px rgb(23, 201, 255);
}
.lock {
  border: solid 1px red;
}
.text-content {
  position: absolute;
  display: inline-block;
  cursor: move;
  box-sizing: content-box;
  &:hover {
    border: 1px solid rgb(0, 132, 255);
  }
  .content {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    transform-origin: 0px 0px;
    word-break: break-word;
    white-space: normal;
    &:focus {
      outline: none;
    }
  }
  .tips {
    text-align: center;
    width: 120px;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.8);
    display: inline-block;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 0;
    transition: 0.2s;
  }
}
</style>
