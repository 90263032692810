<template>
  <div class="pay-content">
    <div class="title">请使用微信扫码支付</div>
    <div class="code-area">
      <div class="code">
        <img :src="src" alt="加载中" v-if="payType == 1" />
        <span v-if="payType == 2">暂不支持支付宝支付</span>
      </div>
    </div>
    <div class="button-area">
      <div class="radio-group">
        <!-- @click="selectPayWay(1)" -->
        <div :class="['radio', { active: payType == 1 }]">微信支付</div>
        <div
          v-if="false"
          :class="['radio', { active: payType == 2 }]"
          @click="selectPayWay(2)"
        >
          支付宝
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { submit, pay, checkOrderStatus } from '@/api/cubeclient'
import qrcodejs2 from 'qrcodejs2'
export default {
  props: {
    sourcePlanarId: {
      type: String | Number,
      default: ''
    },
    orderNo: {
      type: String | Number,
      default: ''
    }
  },
  async mounted () {

    if (!localStorage.getItem('token')) {
      return
    }
    window.orderpay = this
    if (this.orderNo === '') {
      let res = await submit({
        planarId: this.sourcePlanarId,
        invoiceStatus: 0
      })
      this.orderId = res.data
    } else {
      this.orderId = this.orderNo
    }
    await this.drawPayCode()
    this.reDrawTime = setInterval(() => {
      this.drawPayCode()
    }, 60 * 1000 * 10)
    this.orderTime = setInterval(async () => {
      let res = await checkOrderStatus({ orderNo: this.orderId })
      if (res.data == 1) {
        clearInterval(this.orderTime)
        this.$emit("finishPay")
      }
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.reDrawTime)
    clearInterval(this.orderTime)
  },
  data () {
    return {
      orderId: '',
      src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPklEQVR4nO3BMQEAAADCoPVPbQsvoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgKcBnKQAAaZ1lY4AAAAASUVORK5CYII=',
      payType: 1
    }
  },
  methods: {
    selectPayWay (payType) {

      this.payType = payType
      if (payType == 2) {
        return
      }
      this.drawPayCode()
    },
    async drawPayCode () {
      const dom = document.createElement('div')
      let payres = await pay({ orderNo: this.orderId, payType: this.payType })
      new qrcodejs2(dom, {
        text: payres.data.codeUrl,
        width: 100,
        height: 100,
        colorDark: 'rgb(0, 0, 0)', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: qrcodejs2.CorrectLevel.L// 容错率，L/M/H
      })
      dom.querySelector('canvas').toBlob((blob) => {
        let url = URL.createObjectURL(blob);
        this.src = url
      })
      // this.src = dom.querySelector('canvas').toDataURL('image/png')
    }

  }

}
</script>

<style lang="scss" scoped>
.pay-content {
  margin-top: 20px;
}
.title {
  height: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
.code-area {
  display: flex;
  justify-content: center;
  padding: 13px;
}
.code {
  width: 160px;
  height: 160px;
  background-image: url("~@/assets/img/QR_bg.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}
.button-area {
  display: flex;
  justify-content: center;
}
.radio-group {
  width: 160px;
  height: 30px;
  background: #fafafa;
  border: 1px solid #e2e7f2;
  border-radius: 4px;
  display: flex;
  .radio {
    width: 160px;
    //  width: 80px;
    // cursor: pointer;
    height: 30px;
    float: left;
    display: inline-block;
    line-height: 30px;
    text-align: center;
  }
  .radio:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .radio:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .active {
    border: 1px solid #2468f2ff;
    font-size: 14px;
    background: #e9f0fe;
    color: #2468f2ff;
  }
}
</style>