<template>
  <div>
    <div class="title">图片</div>
    <div class="image-area" align="left">
      <div class="replace-img" @click="replaceImg">替换图片</div>
    </div>
    <div class="title">裁剪</div>
    <div class="image-area" align="left">
      <div class="Tailoring-img" @click="setCropImage(true)">自由裁剪</div>
    </div>
    <div class="space"></div>
    <div class="spacing">
      <div class="tips">不透明度</div>
      <div class="slider">
        <slider
          @change="debouncePushHistoryRecord"
          v-model="editModule.opacity"
        ></slider>
      </div>

      <div class="content">
        <input-number
          :min="0"
          @change="debouncePushHistoryRecord"
          v-model="editModule.opacity"
          :max="100"
        ></input-number>
      </div>
    </div>
    <div class="spacing">
      <div class="tips">圆角</div>
      <div class="slider">
        <slider
          @change="debouncePushHistoryRecord"
          :show-tooltip="false"
          v-model="editModule.borderRadius"
          :min="0"
          :max="50"
        ></slider>
      </div>

      <div class="content">
        <input-number
          @change="debouncePushHistoryRecord"
          v-model="editModule.borderRadius"
          :min="0"
          :max="50"
          tip="%"
        ></input-number>
      </div>
    </div>
    <div class="shadow">
      <div
        :class="[editModule.boxShadow ? 'active' : '', 'shadow-button']"
        @click="editModule.boxShadow = !editModule.boxShadow"
      ></div>
      <div class="tips">阴影</div>
    </div>
    <div v-if="editModule.boxShadow">
      <div class="color-control">
        <div class="tips">颜色</div>

        <div
          class="showColor"
          @click="$refs.colorPicker.handleTrigger()"
          :style="{ background: editModule.shadow.color }"
        ></div>

        <div class="color-button">
          <color-picker
            ref="colorPicker"
            v-model="editModule.shadow.color"
            show-alpha
            size="mini"
          ></color-picker>
        </div>
      </div>
      <div class="spacing">
        <div class="tips">水平位置</div>
        <div class="slider">
          <slider
            @change="debouncePushHistoryRecord"
            v-model="editModule.shadow.hShadow"
            :min="-100"
          ></slider>
        </div>

        <div class="content">
          <input-number
            @change="debouncePushHistoryRecord"
            v-model="editModule.shadow.hShadow"
            :max="100"
          ></input-number>
        </div>
      </div>
      <div class="spacing">
        <div class="tips">垂直位置</div>
        <div class="slider">
          <slider
            @change="debouncePushHistoryRecord"
            v-model="editModule.shadow.vShadow"
            :show-tooltip="false"
            :min="-100"
          ></slider>
        </div>

        <div class="content">
          <input-number
            @change="debouncePushHistoryRecord"
            v-model="editModule.shadow.vShadow"
            :max="100"
          ></input-number>
        </div>
      </div>
      <div class="spacing">
        <div class="tips">模糊距离</div>
        <div class="slider">
          <slider
            @change="debouncePushHistoryRecord"
            v-model="editModule.shadow.blur"
            :show-tooltip="false"
          ></slider>
        </div>

        <div class="content">
          <input-number
            @change="debouncePushHistoryRecord"
            v-model="editModule.shadow.blur"
            :max="100"
          ></input-number>
        </div>
      </div>
    </div>
    <div class="space"></div>
    <div class="position">
      <div class="title">位置尺寸</div>
      <div class="item">
        <div class="tips">位置</div>
        <div class="input">
          <div class="content">
            <input-number
              @change="positionChange"
              :division="true"
              v-model="editModuleLeft"
              tip="X"
            ></input-number>
          </div>
        </div>
        <div class="connect"></div>
        <div class="input">
          <div class="content">
            <input-number
              @change="positionChange"
              :division="true"
              v-model="editModuleTop"
              tip="Y"
            ></input-number>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="tips">尺寸</div>
        <div class="input">
          <div class="content">
            <input-number
              @change="resetWidth"
              v-model="editModuleWidth"
              :division="true"
              :min="40"
              tip="宽"
            ></input-number>
          </div>
        </div>
        <div class="connect">
          <i v-if="!lock" @click="lockActive" class="el-icon-link link"></i>
          <i v-if="lock" @click="lock = false" class="el-icon-link active"></i>
        </div>
        <div class="input">
          <div class="content">
            <input-number
              @change="resetHeight"
              v-model="editModuleHeight"
              :division="true"
              :min="80"
              tip="高"
            ></input-number>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import InputNumber from '@c/InputNumber.vue'
import slider from '../common/slider'
import _ from 'lodash'

export default {
  data () {
    return {
      color: '',
      value: 0,
      lock: false,
      rate: 1
    }
  },
  mounted () {
    this.debouncePushHistoryRecord = _.debounce(() => {
      this.pushHistoryRecord()
    }, 1000)
  },
  components: {
    InputNumber,
    slider

  },
  computed: {
    ...mapState('edit', ['editModule', 'cropImage']),
    editModuleLeft: {
      get () {
        return Math.round(this.editModule.left)
      },
      set (value) {
        this.editModule.left = value
      }
    },
    editModuleTop: {
      get () {
        return Math.round(this.editModule.top)
      },
      set (value) {
        this.editModule.top = value
      }
    },
    editModuleWidth: {
      get () {
        return Math.round(this.editModule.width)
      },
      set (value) {
        this.editModule.width = value
      }
    },
    editModuleHeight: {
      get () {
        return Math.round(this.editModule.height)
      },
      set (value) {
        this.editModule.height = value
      }
    }
  },
  methods: {
    ...mapMutations('edit', ['pushHistoryRecord', 'setCropImage', 'initGroupSize']),
    lockActive () {
      this.lock = true
      this.rate = this.editModule.width / this.editModule.height
    },
    positionChange () {
      this.debouncePushHistoryRecord()
      this.initGroupSize()
      this.$bus.$emit('initParameter')
    },
    resetWidth () {
      //  console.log(this.editModule.height)
      //   console.log(this.editModule.height)
      if (this.lock) {
        this.editModule.height = Math.round(this.editModule.width / this.rate)
      }

      this.debouncePushHistoryRecord()
      this.initGroupSize()
      this.$bus.$emit('initParameter')
    },
    resetHeight () {
      if (this.lock) {
        this.editModule.width = Math.round(this.editModule.height / this.rate)
      }

      this.debouncePushHistoryRecord()
      this.initGroupSize()
      this.$bus.$emit('initParameter')
    },
    changeAlign (dir) {
      this.editModule.align = dir
      this.debouncePushHistoryRecord()
    },
    debouncePushHistoryRecord: function () { },
    replaceImg () {
      this.$bus.$emit('menusChange', 'imagesList')
    }
  },
  watch: {
    'editModule.id' () {
      this.rate = this.editModule.width / this.editModule.height
    }
  }

}
</script>

<style lang="scss" scoped>
.space {
  width: 368px;
  height: 1px;
  background: #2b2c2f;
}
.title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #2b2c2f;
}
.font-size-input {
  width: 345px;
  height: 44px;
  margin-top: 16px;
}
.font-statu {
  margin-top: 16px;
  width: 345px;
  height: 44px;
  background: #1d1e23;
  border: 2px solid #303235;
  border-radius: 4px;
}
.font-statu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .item {
    flex: 1;
    color: #404144;
    border: 1px solid #303235;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  i {
    font-size: 24px;
  }
  .active {
    color: white;
  }
}
.color-picker {
  width: 341px;
  height: 40px;
  margin-top: 16px;
  background: #2b2c2f;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .showColor {
    width: 266px;
    height: 24px;
  }
}

.alignment {
  .title {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #8b909a;
    border: 0;
  }
  .contentlist {
    display: flex;
    .item {
      width: 72px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #969ba5;
      background: #404144;
      border-radius: 4px;
      margin-right: 27px;
      i {
        font-size: 30px;
      }
    }
    .active {
      color: white;
      background: #2468f2;
    }
  }
  .active {
    color: white;
  }
}
.spacing {
  height: 40px;
  margin-top: 15px;
  // display: flex;
  align-items: center;
  // justify-content: space-around;
  display: flex;
  .tips {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    width: 60px;
  }
  .slider {
    width: 180px;
    margin: 20px;
  }
  .content {
    width: 74px;
    height: 32px;
    background: #1a1a1a;
    border: 1px solid #2468f2;
    color: white;
    text-align: center;
    line-height: 32px;
  }
}
.position {
  .title {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #8b909a;
    border: 0;
  }
  .item {
    display: flex;
    align-items: center;
    height: 66px;
    .tips {
      width: 70px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .input {
      width: 120px;
      .content {
        width: 114px;
        height: 34px;
        background: #1d1e23;
        border: 2px solid #2468f2;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .connect {
      width: 54px;
      font-size: 20px;
      i {
        transform: rotate(45deg);
        margin-left: 15px;
        cursor: pointer;
      }
      .link {
        color: rgb(184, 184, 184);
      }
      .active {
        color: rgb(0, 153, 255);
      }
    }
  }
}

.image-area {
  height: 80px;
  padding: 20px 0px 20px 0px;
  .replace-img {
    width: 350px;
    height: 40px;
    background: #2468f2;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .Tailoring-img {
    width: 350px;
    height: 44px;
    background: #1d1e23;
    border: 2px solid #5d6269;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.shadow {
  height: 50px;
  display: flex;
  align-items: center;

  .shadow-button {
    width: 18px;
    height: 18px;
    background: #c7c7c7;
    border-radius: 50%;
    float: left;
    cursor: pointer;
  }
  .active {
    background: #ff5555;
  }
  .tips {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-left: 10px;
  }
}

.color-control {
  display: flex;
  align-items: center;
  padding-left: 15px;
  .tips {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 10px;
  }
  .showColor {
    margin-left: 30px;
    width: 266px;
    height: 24px;
    cursor: pointer;
  }
  .color-button {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
}
</style>
