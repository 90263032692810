var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.cropImage || _vm.image.id != _vm.editSelectedId,
          expression: "!cropImage || image.id != editSelectedId"
        }
      ],
      class: [
        "img-content",
        {
          selected:
            _vm.image.id == _vm.editSelectedId &&
            !_vm.image.lock &&
            !_vm.display,
          lock: _vm.image.lock && _vm.image.id == _vm.editSelectedId
        }
      ],
      style: {
        width: _vm.image.width + "px",
        height: _vm.image.height + "px",
        left: _vm.image.left + "px",
        top: _vm.image.top + "px",
        zIndex: _vm.image.ZIndex + _vm.editZindex,
        transform:
          "rotate(" + (_vm.image.rotate ? _vm.image.rotate : 0) + "deg)",
        borderWidth: _vm.moveScale * 0.5 + "px"
      },
      on: {
        mouseenter: function($event) {
          _vm.showTips = true
        },
        mouseout: function($event) {
          _vm.showTips = false
        },
        mousedown: _vm.mousedown
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "tips",
          style: {
            transform:
              "translateY(-" +
              (40 + 20 * (_vm.moveScale - 1)) +
              "px) translateX(-50%) scale(" +
              (_vm.showTips && _vm.image.id != _vm.editSelectedId
                ? _vm.moveScale
                : 0) +
              ")"
          }
        },
        [_vm._v(" 单击操作图片 ")]
      ),
      _vm.display && !_vm.refresh
        ? _c(
            "div",
            {
              staticClass: "content-limit",
              style: {
                filter: _vm.image.boxShadow
                  ? "drop-shadow(" +
                    _vm.image.shadow.hShadow +
                    "px " +
                    _vm.image.shadow.vShadow +
                    "px " +
                    _vm.image.shadow.blur +
                    "px " +
                    _vm.image.shadow.color +
                    ")"
                  : "none",
                opacity: _vm.image.opacity / 100,
                borderRadius:
                  (_vm.image.borderRadius * _vm.shortBorder) / 100 + "px"
              }
            },
            [
              _c("div", {
                staticClass: "image",
                style: {
                  width: _vm.image.showInfo.showWidth + "px",
                  height: _vm.image.showInfo.showHeight + "px",
                  background: "url(" + _vm.imageSrc + ") no-repeat",
                  backgroundPosition:
                    "-" +
                    _vm.image.showInfo.backgroundPositionX +
                    "px -" +
                    _vm.image.showInfo.backgroundPositionY +
                    "px",
                  backgroundSize:
                    _vm.image.showInfo.backImageWidth +
                    "px " +
                    _vm.image.showInfo.backImageHeight +
                    "px"
                }
              })
            ]
          )
        : _vm._e(),
      _c("regulator", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.image.id == _vm.editSelectedId &&
              !_vm.image.lock &&
              !_vm.display &&
              !_vm.image.groupId &&
              !_vm.group,
            expression:
              "\n      image.id == editSelectedId &&\n      !image.lock &&\n      !display &&\n      !image.groupId &&\n      !group\n    "
          }
        ],
        attrs: { module: _vm.image }
      }),
      _vm.image.id == _vm.editSelectedId &&
      !_vm.display &&
      !_vm.image.lock &&
      !_vm.image.groupId &&
      !_vm.group
        ? _c("rotate", { attrs: { module: _vm.image } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }