var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-list" },
    [
      _c("div", { staticClass: "search" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.modelName,
              expression: "modelName"
            }
          ],
          attrs: { placeholder: "搜索模板" },
          domProps: { value: _vm.modelName },
          on: {
            keydown: function($event) {
              $event.stopPropagation()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.initSearch($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.modelName = $event.target.value
            }
          }
        }),
        _vm.modelName
          ? _c("i", {
              staticClass: "el-icon-close cancel-button",
              on: { click: _vm.resetSearch }
            })
          : _vm._e(),
        _c("i", {
          staticClass: "el-icon-search hang",
          on: { click: _vm.initSearch }
        })
      ]),
      _c("div", { staticClass: "template-category" }, [
        _c(
          "div",
          {
            staticClass: "category",
            class: { active: _vm.current === 0 },
            on: {
              click: function($event) {
                _vm.current = 0
              }
            }
          },
          [_vm._v(" 推荐模板 ")]
        ),
        _c(
          "div",
          {
            staticClass: "category",
            class: { active: _vm.current === 1 },
            on: { click: _vm.collection }
          },
          [_vm._v(" 收藏模板 ")]
        )
      ]),
      _c("recommen-post", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.current == 0,
            expression: "current == 0"
          }
        ],
        attrs: { searchName: _vm.searchName },
        on: { selectTemplate: _vm.selectTemplate }
      }),
      _c("own-model", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.current != 0,
            expression: "current != 0"
          }
        ],
        on: { selectTemplate: _vm.selectTemplate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }