<template>
  <div
    ref="post"
    @drop="drop"
    @dragover="allowDrop"
    @click.stop
    class="canvas-area"
    :style="{
      width: postInfo.canvas.width + 'px',
      height: postInfo.canvas.height + 'px',
      borderWidth: `${(80 / scale) * 100}px ${(80 / scale) * 100}px ${
        (80 / scale) * 100
      }px ${(80 / scale) * 100}px`,
      transform: `scale(${scale / 100},${scale / 100}) `,
      transition: `${anime ? 0.5 : 0}s`,
      left: `${canvasLeft}px`,
      top: `${canvasTop}px`,
    }"
  >
    <div
      v-show="showbleedingline"
      class="bleedingline"
      :style="{
        width: postInfo.canvas.width + 'px',
        height: postInfo.canvas.height + 'px',
      }"
    >
      <div
        class="bleed-line"
        :style="{
          borderWidth: `${(2 / scale) * 100}px`,
        }"
      ></div>
      <div class="bleed-item one"></div>
      <div class="bleed-item two"></div>
      <div class="bleed-item three"></div>
      <div class="bleed-item four"></div>
      <div class="bleed-item five"></div>
      <div class="bleed-item six"></div>
      <div class="bleed-item seven"></div>
      <div class="bleed-item eight"></div>
    </div>
    <div class="background" v-if="water" @click.stop="setBack"></div>
    <d-group
      v-if="group && group.layerIds.length > 1"
      :module="group"
    ></d-group>
    <d-image
      v-for="item in postInfo.images"
      :image="item"
      :key="item.imageId"
    ></d-image>
    <d-text
      v-for="item in postInfo.texts"
      :textInfo="item"
      :key="item.textId"
    ></d-text>
    <d-code
      v-for="item in postInfo.codes"
      :code="item"
      :key="item.codeId"
    ></d-code>
    <image-crop v-if="cropImage"></image-crop>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import model from '@/lib/model'
import DImage from '../operation/DImage.vue'
import ImageCrop from '../operation/ImageCrop.vue'
import DText from '../operation/DText.vue'
import DCode from '../operation/DCode.vue'
import panel from './common/panel'
import DGroup from '../operation/DGroup.vue'
export default {
  data () {
    return {
      showbleedingline: false
    }
  },
  props: {
    // cropImage:{
    //   type:Boolean,
    //   default:false
    // },
    anime: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DImage,
    ImageCrop,
    DText,
    DCode,
    DGroup
  },
  computed: {
    ...mapState('edit', ['scale', 'postInfo', 'editModuleList', 'cropImage', 'canvas', 'editSize', 'group', 'water']),
    canvasTop: panel.canvasTop,
    canvasLeft: panel.canvasLeft
  },
  created () {
    this.$bus.$on("showbleedingline", () => {
      this.showbleedingline = true
    })
  },
  mounted () {


  },
  beforeDestroy () {
    this.$bus.$off('showbleedingline')
  },
  methods: {
    ...mapMutations('edit', ['setBackEditModule', 'setCropImage', 'addImage', 'setEditModule', 'addCode', 'pushHistoryRecord']),
    setBack () {
      this.setBackEditModule()
      this.setCropImage(false)
    },
    drop (e) {
      let code = e.dataTransfer.getData('code')
      const imgUrl = e.dataTransfer.getData('imgUrl')
      if (code) {
        code = JSON.parse(code)
        code.left = e.offsetX - 50
        code.top = e.offsetY - 50
        const param = model.createCode(code)
        this.addCode(param)
        this.setEditModule(param)
        this.pushHistoryRecord()
      }
      if (imgUrl) {
        const img = new Image()
        img.src = e.dataTransfer.getData('imgUrl')
        img.onload = () => {
          let rate = 1
          if (this.canvas.width < img.naturalWidth) {
            rate = 0.25
          }
          if (this.canvas.height < img.naturalHeight) {
            rate = 0.25
          }
          const imgInfo = model.createImage({
            src: img.src,
            height: img.naturalHeight * rate,
            width: img.naturalWidth * rate,
            left: e.offsetX - img.naturalWidth * rate / 2,
            top: e.offsetY - img.naturalHeight * rate / 2
          })
          this.addImage(imgInfo)
          this.setEditModule(imgInfo)
          this.pushHistoryRecord()
        }
      }
    },
    allowDrop (e) {
      e.preventDefault()
    }
  }

}
</script>

<style lang="scss" scoped>
.canvas-area {
  position: absolute;
  transform-origin: left top;
  margin-bottom: 30px;
  box-sizing: content-box;
  border-style: solid;
  border-color: #f4f4fb;
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-image: url("~@/assets/watermark.svg");
    opacity: 0.5;
  }
}
.bleedingline {
  position: absolute;
  left: 0;
  top: 0;
  border: 40px solid rgba(255, 255, 255, 0.294);
  z-index: 0;
  .bleed-line {
    width: 100%;
    height: 100%;
    border: dashed rgba(0, 0, 0, 0.103);
  }
  .bleed-item {
    position: absolute;
  }
  .one {
    top: -90px;
    left: 0;
    width: 1px;
    height: 40px;
    border-left: 1px dashed black;
  }
  .two {
    top: 0px;
    left: -90px;
    width: 40px;
    height: 1px;
    border-top: 1px dashed black;
  }
  .three {
    top: -90px;
    right: 0px;
    width: 1px;
    height: 40px;
    border-left: 1px dashed black;
  }
  .four {
    top: 0px;
    right: -90px;
    width: 40px;
    height: 1px;
    border-top: 1px dashed black;
  }
  .five {
    bottom: -90px;
    left: 0;
    width: 1px;
    height: 40px;
    border-left: 1px dashed black;
  }
  .six {
    bottom: 0px;
    left: -90px;
    width: 40px;
    height: 1px;
    border-top: 1px dashed black;
  }
  .seven {
    bottom: 0;
    right: -90px;
    width: 40px;
    height: 1px;
    border-top: 1px dashed black;
  }
  .eight {
    bottom: -90px;
    right: 0;
    width: 1px;
    height: 40px;
    border-left: 1px dashed black;
  }
}
</style>
