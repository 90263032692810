<template>
  <div class="template-list">
    <!-- 搜索 -->
    <div class="search">
      <input
        @keydown.stop
        v-model="imageName"
        placeholder="搜索图片"
        v-on:keyup.enter="initSearch"
      />
      <i
        class="el-icon-close cancel-button"
        v-if="imageName"
        @click="resetSearch"
      ></i>
      <i class="el-icon-search hang" @click="initSearch"></i>
    </div>

    <div class="template-category">
      <div
        class="category"
        @click="current = 0"
        :class="{ active: current === 0 }"
      >
        素材库
      </div>
      <div
        class="category"
        v-if="!admin"
        @click="uploadPicture"
        :class="{ active: current === 1 }"
      >
        上传图片
      </div>
    </div>
    <!-- 图片内容 -->
    <div v-if="current == 0" class="template-centent">
      <div class="image-area">
        <template v-for="(imageClass, index) in imageclassList">
          <imageblock
            :classificationId="imageClass.id"
            :key="index"
            :name="imageClass.name"
            @selecteImage="selecteImage"
            @dragEnd="dragEnd"
            @drag="drag"
            @showMaterialList="showMaterialList"
          ></imageblock>
        </template>

        <!-- <div v-else class="noneModelInfo">没有找到您想要的搜索结果</div> -->
      </div>
    </div>
    <div v-else class="template-centent">
      <div class="image-area" v-infinite-scroll="loadImageUpload">
        <div
          class="my-item"
          v-for="(item, index) in imageUpload"
          :key="index"
          @click="selecteImage(item)"
          draggable="true"
          @dragend="dragEnd"
          @dragstart="drag(item)"
        >
          <div class="item-mask"></div>
          <img
            origin="http://192.168.1.55:8080/"
            :src="item + '?imageMogr2/thumbnail/!50p'"
          />
        </div>
      </div>

      <!-- <div class='more-button' v-if="showOwnImg" @click="searchOwnImg">更多</div> -->

      <div class="updataButton">
        <upload-file @addFile="addImgEvent" :img="true">
          <i class="el-icon-plus"></i>
        </upload-file>
      </div>

      <!-- <upload-com   @addImg="addImgEvent"></upload-com> -->
    </div>
    <material-list
      ref="material"
      @selecteImage="selecteImage"
      @dragEnd="dragEnd"
      @drag="drag"
    ></material-list>
  </div>
</template>

<script>
import model from '@/lib/model'
import config from '@/config'
import { mapMutations, mapState } from 'vuex'
// import { selectListPage } from '@/api/myDesign'
import { materialadd, materialpage, materialclassification, sysmaterialpage } from '@/api/cubeclient'

import uploadFile from '@/components/upload/uploadFile.vue'
import Imageblock from './imageblock.vue'
import materialList from './materialList.vue'
function getFormatImageSrc (src) {
  src = src.replace('https://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-test-1257418739.file.myqcloud.com')
  src = src.replace('http://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-test-1257418739.file.myqcloud.com')
  src = src.replace('https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-prod-1257418739.file.myqcloud.com')
  src = src.replace('http://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-prod-1257418739.file.myqcloud.com')
  return src
}
export default {
  name: 'imagesList',
  props: ['posterStyle'],
  data () {
    return {
      stencilList: ['推荐模板', '我的模板'],
      imgList: ['图片库', '上传图片'],
      current: 0,
      imageStore: [],
      imageUpload: [],
      pictureList: [],
      myImgPage: 1,
      imgPage: 1,
      limit: 20,
      showOwnImg: true,
      showImg: true,
      isLogin: false,
      imageName: '',
      searchName: '',
      admin: config.admin,
      imageclassList: []
    }
  },
  components: {
    uploadFile,
    Imageblock,
    materialList
  },
  mounted () {
    const input = document.createElement('input')
    input.type = 'file'
    // let imageUpload = this.imageUpload
    input.onchange = e => {
      this.imageUpload.push(this.getObjectURL(input.files[0]))
      // console.dir(imgs)
    }

    this.imputDom = input
    this.init()
  },
  computed: {
    ...mapState('edit', ['scale'])
  },
  methods: {
    ...mapMutations('edit', ['setDragEvent']),
    // loadPictureList () {
    //   this.searchImage()
    // },
    loadImageUpload () {
      this.searchOwnImg()
    },
    showMaterialList (param) {
      this.$refs.material.showMaterialList(param)
    },
    uploadPicture () {
      if (!this.isLogin) {
        this.$Login()
        return
      }
      this.current = 1
    },
    drag (item) {
      this.setDragEvent(true)
      let src = getFormatImageSrc(item)
      new Image().src = src
      event.dataTransfer.setData('imgUrl', src)
    },
    dragEnd () {
      this.setDragEvent(false)
    },
    initSearch () {
      this.$refs.material.showMaterialList({
        name: '全部',
        type: 'img',
        classificationId: "",
        imageName: this.imageName
      })

      // this.current = 0
      // this.searchName = this.imageName
      // this.imgPage = 1
      // this.pictureList = []
      // this.searchImage()
    },
    resetSearch () {
      this.current = 0
      this.imageName = ''
      this.searchName = this.imageName
      this.imgPage = 1
      this.pictureList = []
      this.searchImage()
    },
    async addImgEvent (data) {
      const res = await materialadd({
        ...data,
        type: 'img'
      })
      if (res.code == 200) {
        this.imageUpload.push(data.fileUrl)
        // console.log(this.imageUpload)
        this.$message({
          type: 'success',
          message: '上传成功'
        })

        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
    },

    async init () {
      if (localStorage.getItem('token') == null) {
        this.isLogin = false
      } else {
        this.isLogin = true
      }
      const res = await materialclassification({
        type: 'img'
      })
      this.imageclassList = res.data
      this.searchOwnImg()

    },
    async searchImage () {
      const res = await sysmaterialpage({
        type: 'img',
        pageNo: this.imgPage++,
        pageSize: this.limit,
        title: this.searchName
      })
      if (res.data.list == null) {
        this.showImg = false
        return
      }
      if (res.data.list.length < this.limit) {
        this.showImg = false
      }
      const imageList = res.data.list.map(ele => {
        return ele.imgUrl
      })
      this.pictureList = [...this.pictureList, ...imageList]
    },
    async searchOwnImg () {
      if (config.admin) {
        return
      }
      if (localStorage.getItem('token') == null) {
        this.imageUpload = []
        return
      }
      const res = await materialpage({
        type: 'img',
        pageNo: this.myImgPage++,
        pageSize: this.limit
      })
      if (res.data.list.length < this.limit) {
        this.showOwnImg = false
      }
      const imageList = res.data.list.map(ele => {
        return ele.fileUrl
      })
      this.imageUpload = [...this.imageUpload, ...imageList]
    },
    // 切换背景
    checkoutBgc (index) {
      this.$emit('input', this.bgcList[index])
    },
    // 上传图片
    uploadImage () {
      this.imputDom.click()
    },
    getObjectURL (file) {
      var url = null
      if (window.createObjectURL != undefined) { // basic
        url = window.createObjectURL(file)
      } else if (window.URL != undefined) { // mozilla(firefox)
        url = window.URL.createObjectURL(file)
      } else if (window.webkitURL != undefined) { // webkit or chrome
        url = window.webkitURL.createObjectURL(file)
      }
      return url
    },
    async selecteImage (src) {
      const size = await model.getAddImageSize(src)
      const picParam = model.createImage({
        src: getFormatImageSrc(src),
        height: size.height,
        width: size.width
      })
      this.$emit('pushImage', picParam)
    }
  }
}
</script>

<style lang="scss" scoped>
.noneModelInfo {
  position: absolute;
  color: white;
  top: 50%;
  left: 45%;
  width: 200px;
  transform: translateX(-50%) translateY(-50%);
}
.cancel-button {
  position: absolute;
  right: 0;
  top: 37%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  color: #ffffff;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.hang {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 37%;
  width: 18px;
  height: 18px;
  color: #9da2ad;
}
.image-area {
  width: 100%;
  position: absolute;
  bottom: 60px;
  top: 0px;
  overflow-y: scroll;
  .my-item {
    cursor: pointer;
    float: left;
    width: 136px;
    height: 136px;
    margin: 0 20px 20px 0;
    border-radius: 5px;
    background-color: #f6f6f6;
    position: relative;
    .item-mask {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      z-index: 1;
      &:hover {
        background-color: #0a40ad1c;
      }
    }
    img {
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto;
      left: 0px;
      right: 0px;
    }
  }
}

.more-button {
  height: 30px;
  text-align: center;
  color: white;
  line-height: 30px;
  font-size: 20px;
  width: 100%;
  background-color: gray;
  margin-bottom: 10px;
  font-weight: 900;
}
.template-list {
  //  height: 80%;
  height: 100%;
  overflow: hidden;
  position: relative;
  // 背景色
  .bgc {
    margin: 38px 0 0 28px;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      width: 30px;
      height: 30px;
      border: 1px solid #000000;
      border-radius: 4px;
      margin: 0 12px 12px 0;
      cursor: pointer;
      &:last-child {
        width: 72px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background: #404144;
        border: 1px solid #2b2c2f;
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #969ba5;
      }
    }
  }
  // 搜索
  .search {
    text-align: center;
    width: 290px;
    position: relative;
    margin: 0 28px;

    input {
      background-color: #1d1e23;
      width: 294px;
      height: 60px;
      border-radius: none;
      border: none;
      border-bottom: 1px solid #9da2ad;
      padding-left: 29px;
      color: #9da2ad;
      outline: none;
    }
    // i {
    //   position: absolute;
    //   left: 0;
    //   top: 37%;
    //   width: 18px;
    //   height: 18px;
    //   color: #9da2ad;
    // }
  }
  // 模板
  .template-category {
    width: 294px;
    margin: 0 28px;
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    border-bottom: 1px solid #2b2c2f;
    padding-top: 27px;
    .category {
      margin-right: 43px;
      cursor: pointer;
    }
    .active {
      border-bottom: 2px solid #fff;
      padding-bottom: 6px;
    }
  }
  .template-centent {
    position: absolute;
    top: 130px;
    left: 30px;
    bottom: 0;
    width: 322px;
    display: flex;
    flex-wrap: wrap;
    .recommend-item {
    }
  }
}
.updataButton {
  width: 250px;
  height: 50px;
  background: rgb(0, 204, 255);
  overflow: hidden;
  position: absolute;
  border-radius: 10px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 900;
  left: 30px;
  bottom: 10px;
}
</style>
