var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "codeContent",
        {
          lock:
            _vm.code.lock && _vm.code.id == _vm.editSelectedId && !_vm.display
        },
        {
          selected:
            _vm.code.id == _vm.editSelectedId && !_vm.code.lock && !_vm.display
        },
        { codehover: _vm.code.id != _vm.editSelectedId }
      ],
      style: {
        left: _vm.code.left + "px",
        top: _vm.code.top + "px",
        zIndex: _vm.code.ZIndex + _vm.editZindex,
        transform: "rotate(" + (_vm.code.rotate ? _vm.code.rotate : 0) + "deg)",
        width: _vm.code.width + "px",
        height: _vm.code.height + "px",
        borderWidth: _vm.moveScale + "px"
      },
      on: {
        mouseenter: function($event) {
          _vm.showTips = true
        },
        mouseout: function($event) {
          _vm.showTips = false
        },
        mousedown: _vm.mousedown
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "tips",
          style: {
            transform:
              "translateY(-" +
              (40 + 20 * (_vm.moveScale - 1)) +
              "px) translateX(-50%) scale(" +
              (_vm.showTips && _vm.code.id != _vm.editSelectedId
                ? _vm.moveScale
                : 0) +
              ")"
          }
        },
        [_vm._v(" 点击编辑二维码 ")]
      ),
      _vm.display
        ? _c("div", {
            ref: "qrcode",
            staticClass: "qrcode",
            attrs: { draggable: "false" }
          })
        : _vm._e(),
      !_vm.display
        ? _c("div", {
            ref: "qrcode",
            staticClass: "qrcode",
            style: { opacity: 0 },
            attrs: { draggable: "false" }
          })
        : _vm._e(),
      _c("div", { staticClass: "control", staticStyle: { border: "0px" } }),
      _c("regulator", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.code.id == _vm.editSelectedId &&
              !_vm.code.lock &&
              !_vm.display &&
              !_vm.code.groupId &&
              !_vm.group,
            expression:
              "\n      code.id == editSelectedId &&\n      !code.lock &&\n      !display &&\n      !code.groupId &&\n      !group\n    "
          }
        ],
        attrs: { module: _vm.code }
      }),
      _vm.code.id == _vm.editSelectedId &&
      !_vm.display &&
      !_vm.code.lock &&
      !_vm.code.groupId &&
      !_vm.group
        ? _c("rotate", { attrs: { module: _vm.code } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }