import { render, staticRenderFns } from "./templateList.vue?vue&type=template&id=612744e9&scoped=true&"
import script from "./templateList.vue?vue&type=script&lang=js&"
export * from "./templateList.vue?vue&type=script&lang=js&"
import style0 from "./templateList.vue?vue&type=style&index=0&id=612744e9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612744e9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data-jenkins/jenkins/workspace/html-cube-webapi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('612744e9')) {
      api.createRecord('612744e9', component.options)
    } else {
      api.reload('612744e9', component.options)
    }
    module.hot.accept("./templateList.vue?vue&type=template&id=612744e9&scoped=true&", function () {
      api.rerender('612744e9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/DesignEditor/component/modular/templateList.vue"
export default component.exports