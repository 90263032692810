<template>
  <div class="MarketingActivities" @keydown.stop>
    <div class="content">
      <!-- {{product}} -->
      <div class="pic-area">
        <div class="main-pic">
          <img :src="`https://${showImg}`" />
        </div>
        <div class="small-pic-list" v-if="product.decript">
          <div
            class="small-pic-item"
            v-for="(item, index) in product.decript.picture"
            :key="index"
            @mouseover="showImg = item"
          >
            <img :src="`https://${item}`" />
          </div>
        </div>
      </div>
      <div class="info-area">
        <div class="title">
          {{ product.spuName }}
        </div>
        <div class="tip">
          <!-- 该印品16：00前下单，预计12月00日出货 -->
        </div>
        <div class="total-price">￥{{ price }}</div>
        <div class="purchase-information">
          <!-- <div class='info-name'>单价：</div> -->
          <!-- <div class='single-price'>¥ 17.50/盒</div> -->
          <!-- <div class='info-name'>累计订购：</div>
                <div class='total-num'>56899</div> -->
        </div>
        <div class="ensure-list">
          <!-- <div class='ensure-item red'>满68元顺丰包邮</div> -->
          <div class="ensure-item black">正规发票</div>
          <div class="ensure-item blue">品质保证</div>
        </div>
        <div
          class="oper-list"
          v-for="(item, index) in product.saleAttrVos"
          :key="index"
        >
          <div class="oper-name">{{ item.attrName }}</div>
          <div class="oper-button-area">
            <div
              :class="['button', { active: item.selected == attrV }]"
              v-for="(attrV, addtVIndex) in item.attrValues"
              :key="addtVIndex"
              @click="selectAttr(item, attrV)"
            >
              {{ attrV.attrValue }}
              <!-- {{item.selected}} -->
            </div>
          </div>
        </div>

        <div class="oper-list">
          <div class="oper-name">文件</div>
          <div class="oper-button-area">
            {{ printInfo.fileName }}
            <!-- <upload-file @addFile='addFile'>
                    <div class='upload' v-if="fileUrl">{{fileUrl}}</div>
                    <div class='upload' v-if="!fileUrl">上传自带文件</div>
                  </upload-file> -->
          </div>
        </div>
        <div class="purchase-area" v-if="publishStatus != '0'">
          <div class="purchase-button" @click="toOrderPage">立即购买</div>
          <div class="shopping-cart" @click="addShopCar">加入购物车</div>
        </div>
        <div v-else align="center" class="warn">该商品已下架</div>
      </div>
      <div class="tab-list">
        <div
          :class="['tab-item', { active: showPart == 1 }]"
          @click="showPart = 1"
        >
          印品详情
        </div>
        <div
          :class="['tab-item', { active: showPart == 2 }]"
          @click="showPart = 2"
        >
          订购流程
        </div>
      </div>
      <div class="tab-content" v-if="product.decript">
        <div v-show="showPart == 1">
          <img
            class="product-info-img"
            :src="`http://${item}`"
            v-for="(item, index) in product.decript.details"
            :key="index"
          />
        </div>
        <div v-show="showPart == 2">
          <img
            class="product-info-img"
            :src="`http://${item}`"
            v-for="(item, index) in product.decript.process"
            :key="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadFile from '@/components/upload/uploadFile.vue'
import { printcartadd } from '@/api/cubeclient'
import { assemblyOrder } from '@/api/printonline'
import _ from 'lodash'

export default {
  props: {
    printInfo: {
      type: Object,
      // eslint-disable-next-line no-new-object
      default: new Object()
    },
    sourceWorkId: {
      type: String | Number,
      default: ''
    }
  },
  data () {
    return {
      activeName: 'second',
      spuId: undefined,
      product: {},
      showImg: '',
      showPart: 1,
      attrList: [],
      price: 0,
      skuId: 0,
      fileUrl: '',
      taxonomy: '',
      publishStatus: 0
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    uploadFile
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    /**
     * 添加文件操作
     */
    addFile (data) {
      this.fileUrl = data.fileUrl
    },
    /**
     * 添加购物车
     */
    async addShopCar () {
      const res = await printcartadd({
        designFile: this.printInfo.fileUrl,
        quantity: 1,
        skuId: this.skuId,
        sourceWorkId: this.sourceWorkId
      })
      // eslint-disable-next-line eqeqeq
      if (res.code == 200) {
        this.$router.push({ name: 'ShoppingCart' })
      }
    },
    /**
     * 前往订单状态
     */
    toOrderPage () {
      this.$emit('getOrderItem', { spuId: this.printInfo.spuId, skuId: this.skuId, fileUrl: this.printInfo.fileUrl })
    },
    /**
     * 初始化页面
     */
    async init () {
      this.product = this.printInfo
      this.product.saleAttrVos.forEach(ele => {
        ele.selected = ele.attrValues[0]
      })
      this.getPrice()
      if (typeof this.product.decript === 'string') {
        this.product.decript = JSON.parse(this.product.decript)
      }

      this.showImg = this.product.decript.picture[0]
    },
    /**
     * 选择属性
     */
    selectAttr (item, attrV) {
      // eslint-disable-next-line eqeqeq
      if (item.selected != attrV) {
        item.selected = attrV
        this.getPrice()
        this.$forceUpdate()
      }
    },
    /**
     * 获得商品价格
     */
    async getPrice () {
      try {
        const arr = []
        this.product.saleAttrVos.forEach(ele => {
          arr.push(ele.selected.skuIds.split(','))
        })
        const result = _.intersection(...arr)
        this.skuId = parseInt(result[0])
        const res = await assemblyOrder({ skuIds: [this.skuId], num: null, catalogId: this.printInfo.catalogId })
        this.price = res.data[0].totalAmount
        this.publishStatus = res.data[0].publishStatus
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// @import '../../../PoductDetail/scss/PoductDetail.scss';
.warn {
  color: red;
}
.product-info-img {
  width: 100%;
}
.MarketingActivities {
  width: 100%;
  background-color: white;
  display: inline-block;
  justify-content: center;
  .content {
    width: 100%;
    padding-top: 50px;
    .tab-list {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #dfdfdf;
      margin-top: 50px;
      .tab-item {
        cursor: pointer;
        float: left;
        width: 50%;
        line-height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        text-align: center;
      }
      .active {
        border-bottom: 2px solid black;
      }
    }
    .pic-area {
      width: 500px;
      float: left;
      .main-pic {
        width: 440px;
        height: 440px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .small-pic-list {
        margin-top: 15px;
        .small-pic-item {
          cursor: pointer;
          width: 60px;
          height: 60px;
          float: left;
          margin-right: 16px;
          &:hover {
            border: 1px solid rgb(0, 174, 255);
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .info-area {
      width: 700px;
      float: left;
      .title {
        font-size: 27px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .tip {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 15px;
      }
      .total-price {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #f22813;
        margin-top: 30px;
      }
      .purchase-information {
        display: inline-block;
        width: 100%;
        line-height: 30px;
        .info-name {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          float: left;
        }
        .single-price {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f22813;
          float: left;
          margin-right: 20px;
        }
        .total-num {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          float: left;
        }
      }

      .ensure-list {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        .ensure-item {
          float: left;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          padding: 5px 10px 5px 10px;
          margin-right: 10px;
        }
        .red {
          background-color: #f22813;
        }
        .black {
          background: #000000;
        }
        .blue {
          background: #2468f2;
        }
      }
      .oper-list {
        width: 100%;
        display: inline-block;
        .oper-name {
          width: 90px;
          line-height: 40px;
          float: left;
          text-align: center;
        }
        .oper-button-area {
          line-height: 40px;
          width: 576px;
          float: left;
          .button {
            cursor: pointer;
            float: left;
            min-width: 72px;
            height: 36px;
            line-height: 36px;
            border: 1px solid #bbbbbb;
            border-radius: 4px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-right: 12px;
            margin-bottom: 5px;
            text-align: center;
          }
          .active {
            border: 1px solid #000000;
            font-weight: 900;
            color: #000000;
          }
          .upload {
            float: left;
            width: 106px;
            height: 36px;
            background: #f5f5f5;
            border: 1px solid #cccccc;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            text-align: center;
            line-height: 36px;
            overflow: hidden;
          }
        }
      }
      .purchase-area {
        margin-top: 50px;
        .purchase-button {
          cursor: pointer;
          float: left;
          width: 168px;
          height: 50px;
          background: #2468f2;
          border-radius: 4px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 50px;
          text-align: center;
          margin-right: 40px;
        }
        .shopping-cart {
          cursor: pointer;
          float: left;
          width: 168px;
          height: 50px;
          background: #000000;
          border-radius: 4px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 50px;
          text-align: center;
        }
      }
    }
  }
}

.product-item {
  float: left;
  width: 226px;
  height: 400px;
  background: rgb(0, 160, 189);
  margin-left: 20px;
  margin-bottom: 40px;
}
</style>
