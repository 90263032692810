// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/QR_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".pay-content[data-v-2311b958] {\n  margin-top: 20px;\n}\n.title[data-v-2311b958] {\n  height: 15px;\n  font-size: 14px;\n  font-family: Microsoft YaHei;\n  font-weight: 400;\n  color: #666666;\n  text-align: center;\n}\n.code-area[data-v-2311b958] {\n  display: flex;\n  justify-content: center;\n  padding: 13px;\n}\n.code[data-v-2311b958] {\n  width: 160px;\n  height: 160px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.code img[data-v-2311b958] {\n    width: 100%;\n    height: 100%;\n}\n.button-area[data-v-2311b958] {\n  display: flex;\n  justify-content: center;\n}\n.radio-group[data-v-2311b958] {\n  width: 160px;\n  height: 30px;\n  background: #fafafa;\n  border: 1px solid #e2e7f2;\n  border-radius: 4px;\n  display: flex;\n}\n.radio-group .radio[data-v-2311b958] {\n    width: 160px;\n    height: 30px;\n    float: left;\n    display: inline-block;\n    line-height: 30px;\n    text-align: center;\n}\n.radio-group .radio[data-v-2311b958]:first-child {\n    border-top-left-radius: 4px;\n    border-bottom-left-radius: 4px;\n}\n.radio-group .radio[data-v-2311b958]:last-child {\n    border-top-right-radius: 4px;\n    border-bottom-right-radius: 4px;\n}\n.radio-group .active[data-v-2311b958] {\n    border: 1px solid #2468f2ff;\n    font-size: 14px;\n    background: #e9f0fe;\n    color: #2468f2ff;\n}\n", ""]);
// Exports
module.exports = exports;
