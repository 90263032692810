// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/watermark.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".post[data-v-edc2082c] {\n  position: relative;\n  display: inline-block;\n}\n.post .mask[data-v-edc2082c] {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 99;\n}\n.post .canvas[data-v-edc2082c] {\n    top: 0;\n    left: 0;\n    position: absolute;\n    overflow: hidden;\n}\n.post .canvas .background[data-v-edc2082c] {\n      width: 100%;\n      height: 100%;\n      position: relative;\n}\n.post .canvas .background img[data-v-edc2082c] {\n        position: absolute;\n}\n.watermark[data-v-edc2082c] {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 999;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
