var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "下载中",
            visible: _vm.dialogDownVisible,
            width: "50%",
            center: "",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogDownVisible = $event
            }
          }
        },
        [
          _c("el-progress", { attrs: { percentage: _vm.percentage } }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancelDown } }, [
                _vm._v("取 消")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }