var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "InputNumber",
      style: {
        border: "" + (_vm.border ? "2px solid #303235" : "")
      }
    },
    [
      _c("div", { staticClass: "InputNumber-input" }, [
        _c("input", {
          staticClass: "input",
          attrs: { type: "number" },
          domProps: { value: _vm.value },
          on: { change: _vm.changeEvent }
        })
      ]),
      _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tip))]),
      _c(
        "div",
        {
          staticClass: "button-area",
          style: {
            borderLeft: "" + (_vm.division ? "2px solid #2468F2" : "")
          }
        },
        [
          _c("div", { staticClass: "item" }, [
            _c("i", {
              staticClass: "el-icon-caret-top",
              on: { click: _vm.add }
            })
          ]),
          _c("div", { staticClass: "item" }, [
            _c("i", {
              staticClass: "el-icon-caret-bottom",
              on: { click: _vm.reduce }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }