// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/watermark.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".canvas-area[data-v-61c0a750] {\n  position: absolute;\n  transform-origin: left top;\n  margin-bottom: 30px;\n  box-sizing: content-box;\n  border-style: solid;\n  border-color: #f4f4fb;\n}\n.canvas-area .background[data-v-61c0a750] {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 0;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    opacity: 0.5;\n}\n.bleedingline[data-v-61c0a750] {\n  position: absolute;\n  left: 0;\n  top: 0;\n  border: 40px solid rgba(255, 255, 255, 0.294);\n  z-index: 0;\n}\n.bleedingline .bleed-line[data-v-61c0a750] {\n    width: 100%;\n    height: 100%;\n    border: dashed rgba(0, 0, 0, 0.103);\n}\n.bleedingline .bleed-item[data-v-61c0a750] {\n    position: absolute;\n}\n.bleedingline .one[data-v-61c0a750] {\n    top: -90px;\n    left: 0;\n    width: 1px;\n    height: 40px;\n    border-left: 1px dashed black;\n}\n.bleedingline .two[data-v-61c0a750] {\n    top: 0px;\n    left: -90px;\n    width: 40px;\n    height: 1px;\n    border-top: 1px dashed black;\n}\n.bleedingline .three[data-v-61c0a750] {\n    top: -90px;\n    right: 0px;\n    width: 1px;\n    height: 40px;\n    border-left: 1px dashed black;\n}\n.bleedingline .four[data-v-61c0a750] {\n    top: 0px;\n    right: -90px;\n    width: 40px;\n    height: 1px;\n    border-top: 1px dashed black;\n}\n.bleedingline .five[data-v-61c0a750] {\n    bottom: -90px;\n    left: 0;\n    width: 1px;\n    height: 40px;\n    border-left: 1px dashed black;\n}\n.bleedingline .six[data-v-61c0a750] {\n    bottom: 0px;\n    left: -90px;\n    width: 40px;\n    height: 1px;\n    border-top: 1px dashed black;\n}\n.bleedingline .seven[data-v-61c0a750] {\n    bottom: 0;\n    right: -90px;\n    width: 40px;\n    height: 1px;\n    border-top: 1px dashed black;\n}\n.bleedingline .eight[data-v-61c0a750] {\n    bottom: -90px;\n    right: 0;\n    width: 1px;\n    height: 40px;\n    border-left: 1px dashed black;\n}\n", ""]);
// Exports
module.exports = exports;
