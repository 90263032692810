var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-List-area" },
    [
      _c(
        "div",
        {
          staticClass: "class-item",
          on: {
            click: function($event) {
              return _vm.setTempleType(null)
            }
          }
        },
        [_vm._v("全部")]
      ),
      _vm._l(_vm.typeList, function(type, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "class-item",
            on: {
              click: function($event) {
                return _vm.setTempleType(index)
              }
            }
          },
          [_vm._v(" " + _vm._s(type) + " ")]
        )
      }),
      _c(
        "div",
        { staticClass: "activityList" },
        _vm._l(_vm.activityList, function(activity, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _c("img", {
              staticClass: "coverPic",
              attrs: { src: activity.cover }
            }),
            _c("div", { staticClass: "activity-name" }, [
              _vm._v(_vm._s(activity.templeName))
            ]),
            _c("div", { staticClass: "mask" }, [
              _c(
                "div",
                { staticClass: "code", attrs: { align: "center" } },
                [
                  _c("q-r-code", {
                    attrs: { url: _vm._f("barcodeImgUrl")(activity.page) }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "button-area" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.toEdit(activity.templeId)
                        }
                      }
                    },
                    [_c("i", { staticClass: "el-icon-edit" }), _vm._v("编辑")]
                  )
                ],
                1
              )
            ])
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "page-area", attrs: { align: "right" } },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": _vm.limit,
              layout: "total, prev, pager, next",
              total: _vm.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }