<template>
  <div class="receive-content" @click.stop="closeColorPicker">
    <!-- ,borderWidth:`${80/scale*100}px ${80/scale*100}px ${80/scale*100}px ${80/scale*100}px` -->
    <div
      ref="post"
      @click="selectColor"
      class="canvas-area"
      :style="{
        width: postInfo.canvas.width + 'px',
        height: postInfo.canvas.height + 'px',
        transition: `${anime ? 0.5 : 0}s`,
        borderWidth: `${(80 / scale) * 100}px ${(80 / scale) * 100}px ${
          (80 / scale) * 100
        }px ${(80 / scale) * 100}px`,
        transform: `scale(${scale / 100},${scale / 100}) `,
        left: `${canvasLeft}px`,
        top: `${canvasTop}px`,
      }"
    >
      <div
        v-show="colorItem"
        class="colorSelected"
        :style="{
          borderRadius: `${30 * moveScale}px`,
          width: `${60 * moveScale}px`,
          height: `${60 * moveScale}px`,
          top: `${top}px`,
          left: `${left}px`,
          backgroundColor: backgroundColor,
          border: `${1 * moveScale}px solid black`,
        }"
      >
        <!-- {{backgroundColor}} -->
      </div>
      <div
        class="mask"
        @mousemove="mousemove"
        @mouseenter="colorItem = true"
        @mouseout="colorItem = false"
      ></div>
    </div>
  </div>
</template>

<script>

import postToImage from '@/lib/postToImage'
import { mapState, mapMutations } from 'vuex'
import panel from './common/panel'
export default {
  data () {
    return {
      top: 0,
      left: 0,
      ctx: {},
      backgroundColor: 'rgba(0,0,0,0)',
      colorItem: false,
      rate: 1
    }
  },
  props: {
    anime: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  computed: {
    ...mapState('edit', ['scale', 'postInfo', 'editSize', 'colorPickerInfo', 'index']),
    moveScale () {
      return 100 / this.scale
    },
    canvasTop: panel.canvasTop,
    canvasLeft: panel.canvasLeft
  },
  mounted () {
    this.$bus.$on('loadPostSvg', this.loadPostSvg)
  },
  methods: {
    ...mapMutations('edit', ['setColorPicker']),
    selectColor () {
      this.colorPickerInfo.color = this.backgroundColor
      // this.$nextTick(()=>{this.loadPostSvg()});
    },
    closeColorPicker () {
      this.setColorPicker(false)
    },
    mousemove (event) {
      this.top = event.offsetY - 30 * this.moveScale
      this.left = event.offsetX + 30 * this.moveScale
      const imageDate = this.ctx.getImageData(event.offsetX * this.rate, event.offsetY * this.rate, 1, 1)
      this.backgroundColor = `rgba(${imageDate.data[0]},${imageDate.data[1]},${imageDate.data[2]},1)`
    },
    loadPostSvg () {
      console.log('开始')
      const oldTime = new Date().getTime()
      const svg = postToImage.getSvgByIndex(this.index)
      const image = new Image()

      image.src = `data:image/svg+xml;charset=utf-8,${svg}`
      image.onload = () => {
        const canvas = document.createElement('canvas')
        if (image.naturalWidth * image.naturalHeight > 25000000) {
          this.rate = 0.5
        }
        canvas.setAttribute('width', image.naturalWidth * this.rate)
        canvas.setAttribute('height', image.naturalHeight * this.rate)
        this.ctx = canvas.getContext('2d')
        this.ctx.drawImage(image, 0, 0, image.naturalWidth * this.rate, image.naturalHeight * this.rate)
        this.colorPickerInfo.loading = false
        console.log(new Date().getTime() - oldTime)
      }
    }
  },
  watch: {
    reload (newV, oldV) {
      if (newV) {
        this.loadPostSvg()
        //  domtoimage.getCanvas(document.getElementById('showCanvas'))
        //       .then( (canvas)=> {
        //           this.ctx = canvas.getContext("2d");
        //           console.log(new Date().getTime()-oldTime)
        //           this.colorPickerInfo.loading = false
        //       })
        //       .catch(function (error) {
        //           this.colorPickerInfo.loading = false
        //           console.error('oops, something went wrong!', error);
        //       });
        //   }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.canvas-area {
  position: absolute;
  transform-origin: left top;
  box-sizing: content-box;
  border-style: solid;
  border-color: #f4f4fb;
  z-index: 99;
  .background {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      position: absolute;
    }
  }
}
.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
.colorSelected {
  position: absolute;

  // border:1px solid rgba(0, 0, 0, 0.541)
}
.receive-content {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: url("~@assets/img/colorsucker.png") 0 28, default;
}
</style>
