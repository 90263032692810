var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "QRCodeEdit",
      on: {
        keydown: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c("div", { staticClass: "pre-header" }, [_vm._v("二维码")]),
      _c(
        "div",
        { staticClass: "pre-active" },
        [
          _c("div", { staticClass: "code-text" }, [_vm._v("二维码内容")]),
          _c(
            "el-select",
            {
              attrs: { size: "small", placeholder: "请选择" },
              model: {
                value: _vm.selectModel,
                callback: function($$v) {
                  _vm.selectModel = $$v
                },
                expression: "selectModel"
              }
            },
            _vm._l(_vm.options, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          ),
          _vm.selectModel == 2
            ? _c(
                "div",
                { staticClass: "link-area" },
                [
                  _c("el-input", {
                    staticStyle: { width: "70%", float: "left" },
                    attrs: { size: "small" },
                    on: {
                      keydown: function($event) {
                        $event.stopPropagation()
                      }
                    },
                    model: {
                      value: _vm.url,
                      callback: function($$v) {
                        _vm.url = $$v
                      },
                      expression: "url"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "left",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitLink }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.selectModel == 3
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "30px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              placeholder: "请输入微信公众号ID"
                            },
                            on: {
                              keydown: function($event) {
                                $event.stopPropagation()
                              }
                            },
                            model: {
                              value: _vm.WXcode,
                              callback: function($$v) {
                                _vm.WXcode = $$v
                              },
                              expression: "WXcode"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.commitCode }
                            },
                            [_vm._v("确定")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.selectModel == 1
            ? _c("div", [
                _c("div", { staticClass: "create-active" }, [
                  _c("div", { staticClass: "create-text" }, [
                    _vm._v("我创建的活动")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "create-all",
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "OwnActivity" })
                        }
                      }
                    },
                    [
                      _vm._v(" 全部 "),
                      _c("i", { staticClass: "el-icon-arrow-right" })
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "pre-img" },
                  _vm._l(_vm.activeList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "img-item",
                        class: { active: item.activityId == _vm.current },
                        on: {
                          click: function($event) {
                            return _vm.selecteActive(item)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "left-img" }, [
                          _c("img", {
                            attrs: {
                              src: item.templePage.activityBase.cover,
                              alt: ""
                            }
                          })
                        ]),
                        _c("div", { staticClass: "margin-left-5" }, [
                          _c("div", { staticClass: "activityName" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("div", { staticClass: "activeType" }, [
                            _vm._v(_vm._s(_vm.typeList[item.templeType]))
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { attrs: { align: "right" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-size": _vm.limit,
                        layout: "total, prev, pager, next",
                        total: _vm.total
                      },
                      on: { "current-change": _vm.handleCurrentChange }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { attrs: { align: "center" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.ensureActivity }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.selectModel == 1
        ? _c(
            "div",
            {
              staticClass: "create-act",
              on: {
                click: function($event) {
                  _vm.displayActivity = !_vm.displayActivity
                }
              }
            },
            [
              _vm._v(" 创建新活动 "),
              _c("i", {
                staticClass: "el-icon-arrow-right narrow",
                style: {
                  transform: "" + (_vm.displayActivity ? "rotate(90deg)" : "")
                }
              })
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.displayActivity,
              expression: "displayActivity"
            }
          ],
          staticClass: "activity-list"
        },
        [_vm.selectModel == 1 ? _c("activity-list") : _vm._e()],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }