var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "codeList" }, [
    _c("div", { staticClass: "item" }, [_c("code-demo")], 1),
    _c(
      "div",
      { staticClass: "item" },
      [_c("code-demo", { attrs: { colorDark: "rgba(255, 0, 0,1)" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "item" },
      [_c("code-demo", { attrs: { colorDark: "rgba(0, 98, 255,1)" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "item" },
      [_c("code-demo", { attrs: { colorDark: "rgba(217, 0, 255,1)" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "item" },
      [_c("code-demo", { attrs: { colorDark: "rgba(40, 202, 0,1)" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "item" },
      [_c("code-demo", { attrs: { colorDark: "rgba(155, 47, 218,1)" } })],
      1
    ),
    _vm.isLogin
      ? _c(
          "div",
          { staticClass: "updataButton" },
          [
            _c(
              "upload-file",
              { attrs: { img: true }, on: { addFile: _vm.addImgEvent } },
              [_c("i", { staticClass: "el-icon-plus", attrs: { img: true } })]
            )
          ],
          1
        )
      : _vm._e(),
    !_vm.isLogin
      ? _c("div", { staticClass: "updataButton", on: { click: _vm.$Login } }, [
          _c("i", { staticClass: "el-icon-plus", attrs: { img: true } })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }