var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "post",
    staticClass: "canvas-area",
    style: {
      width: _vm.postInfo.canvas.width + "px",
      height: _vm.postInfo.canvas.height + "px",
      borderWidth:
        (80 / _vm.scale) * 100 +
        "px " +
        (80 / _vm.scale) * 100 +
        "px " +
        (80 / _vm.scale) * 100 +
        "px " +
        (80 / _vm.scale) * 100 +
        "px",
      transform: "scale(" + _vm.scale / 100 + "," + _vm.scale / 100 + ") ",
      transition: (_vm.anime ? 0.5 : 0) + "s",
      left: _vm.canvasLeft + "px",
      top: _vm.canvasTop + "px"
    },
    on: {
      drop: _vm.drop,
      dragover: _vm.allowDrop,
      click: function($event) {
        $event.stopPropagation()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }