<template>
  <div class="template-list">
    <!-- 背景色 -->
    <div class="bgc">
      <ul>
        <li
          v-for="(item, index) in bgcList"
          :key="index"
          :style="{ background: item }"
          @click="checkoutBgc(index)"
        ></li>
        <li>
          <color-picker v-model="color" show-alpha size="mini"></color-picker>
        </li>
      </ul>
    </div>
    <!-- 搜索 -->
    <div class="search">
      <input
        @keydown.stop
        v-model="imageName"
        placeholder="搜索背景图片"
        v-on:keyup.enter="initSearch"
      />
      <i
        class="el-icon-close cancel-button"
        v-if="imageName"
        @click="resetSearch"
      ></i>
      <i class="el-icon-search hang" @click="initSearch"></i>
    </div>

    <div class="template-category">
      <div
        class="category"
        @click="current = 0"
        :class="{ active: current === 0 }"
      >
        背景库
      </div>
      <div
        class="category"
        v-if="!admin"
        @click="uploadPicture"
        :class="{ active: current === 1 }"
      >
        上传背景图
      </div>
    </div>
    <!-- 模板内容 -->
    <div v-if="current == 0" class="template-centent">
      <div class="image-area" v-infinite-scroll="searchImage">
        <div class="classItemList">
          <div
            :class="['class-item', { active: classificationId == '' }]"
            @click="seleteType('')"
          >
            全部
          </div>
          <template v-for="(imageClass, index) in imageclassList">
            <div
              :class="[
                'class-item',
                { active: classificationId == imageClass.id },
              ]"
              :key="index"
              v-if="(index < 8 && showMoreButton) || !showMoreButton"
              @click="seleteType(imageClass.id)"
            >
              {{ imageClass.name }}
            </div>
          </template>
          <div
            class="class-item"
            v-if="showMoreButton"
            @click="showMoreButton = false"
          >
            更多
          </div>
          <div class="backimagearea">
            <div
              class="my-item"
              v-for="(item, index) in pictureList"
              :key="index"
              @click="selecteImage(item)"
            >
              <div class="item-mask"></div>
              <img :src="item + '?imageMogr2/thumbnail/!50p'" />
            </div>
          </div>
        </div>

        <!-- <div v-else class="noneModelInfo">没有找到您想要的搜索结果</div> -->
      </div>
    </div>
    <div v-else class="template-centent">
      <div class="image-area" v-infinite-scroll="searchOwnImg">
        <div
          class="my-item"
          v-for="(item, index) in imageUpload"
          :key="index"
          @click="selecteImage(item)"
        >
          <div class="item-mask"></div>
          <img :src="item + '?imageMogr2/thumbnail/!50p'" />
        </div>
      </div>

      <div class="updataButton">
        <upload-file @addFile="addImgEvent" :img="true">
          <i class="el-icon-plus"></i>
        </upload-file>
      </div>
      <!-- <upload-com   @addImg="addImgEvent"></upload-com> -->
    </div>
    <material-list ref="material" @selecteImage="selecteImage"></material-list>
  </div>
</template>

<script>
import { selectListPage } from '@/api/myDesign'
import { materialadd, materialpage, materialclassification, sysmaterialpage } from '@/api/cubeclient'
import { mapMutations, mapState } from 'vuex'
import uploadFile from '@/components/upload/uploadFile.vue'
import config from '@/config'
import _ from 'lodash'
import Imageblock from './imageblock.vue'
import materialList from './materialList.vue'
export default {
  components: {
    uploadFile,
    Imageblock,
    materialList
  },
  name: 'backgroundList',
  props: ['posterStyle'],
  data () {
    return {
      color: '',
      stencilList: ['推荐模板', '我的模板'],
      imgList: ['图片库', '上传图片'],
      imageStore: [],
      current: 0,
      bgcList: ['rgba(255, 0, 0,1)', 'rgba(255, 196, 0,1)', 'rgba(102, 255, 0,1)', 'rgba(0, 255, 200,1)', 'rgba(0, 132, 255, 1)',
        'rgba(47, 0, 255, 1)', 'rgba(247, 0, 255,1)', 'rgba(255, 0, 43,1)', 'rgba(255, 174, 0, 0.616)', 'rgba(0, 255, 157, 0.616)', 'rgba(65, 153, 119)', 'rgba(255, 255, 255,1)'],
      imageUpload: [],
      pictureList: [],
      myImgPage: 1,
      imgPage: 1,
      limit: 20,
      showOwnImg: true,
      showImg: true,
      isLogin: false,
      imageName: '',
      searchName: '',
      admin: config.admin,
      imageclassList: [],
      showMoreButton: false,
      classificationId: ''
    }
  },
  mounted () {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = e => {
      this.imageUpload.push(this.getObjectURL(input.files[0]))
    }
    this.imputDom = input
    this.init()
  },
  created () {
    this.debouncePushHistoryRecord = _.debounce(() => {
      this.pushHistoryRecord()
    }, 1000)
  },
  computed: {
    ...mapState('edit', ['postInfo'])
  },
  methods: {
    seleteType (id) {

      this.classificationId = id
      // if (id == "") {
      //   this.classificationId = []
      // }
      this.imgPage = 1
      this.pictureList = []
      this.searchImage()
    },
    // loadPictureList () {
    //   this.searchImage()
    // },
    showMaterialList (param) {
      this.$refs.material.showMaterialList(param)
    },
    initSearch () {
      this.current = 0
      this.searchName = this.imageName
      this.imgPage = 1
      this.pictureList = []
      this.searchImage()
    },
    resetSearch () {
      this.current = 0
      this.imageName = ''
      this.searchName = this.imageName
      this.imgPage = 1
      this.pictureList = []
      this.searchImage()
    },
    uploadPicture () {
      if (!this.isLogin) {
        this.$Login()
        return
      }
      this.current = 1
    },
    async searchImage () {
      const res = await sysmaterialpage({
        type: 'gimg',
        pageNo: this.imgPage++,
        pageSize: this.limit,
        title: this.searchName,
        classificationId: this.classificationId
      })
      if (res.data.list == null) {
        this.showImg = false
        return
      }
      if (res.data.list.length < this.limit) {
        this.showImg = false
      }
      const imageList = res.data.list.map(ele => {
        return ele.fileUrl
      })
      this.pictureList = [...this.pictureList, ...imageList]

    },
    async searchOwnImg () {
      if (config.admin) {
        return
      }
      if (localStorage.getItem('token') == null) {
        this.imageUpload = []
        return
      }
      const res = await materialpage({
        type: 'gimg',
        pageNo: this.myImgPage++,
        pageSize: this.limit
      })
      if (res.data.list.length < this.limit) {
        this.showOwnImg = false
      }
      const imageList = res.data.list.map(ele => {
        return ele.fileUrl
      })
      this.imageUpload = [...this.imageUpload, ...imageList]
    },
    debouncePushHistoryRecord () {
    },
    ...mapMutations('edit', ['pushHistoryRecord', 'setBackImage', 'setEditModule']),
    async addImgEvent (data) {
      const res = await materialadd({
        ...data,
        type: 'gimg'
      })
      if (res.code === 200) {
        this.imageUpload.push(data.imgUrl)
        this.$message({
          type: 'success',
          message: '上传成功'
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
    },
    async init () {
      if (localStorage.getItem('token') == null) {
        this.isLogin = false
      } else {
        this.isLogin = true
      }
      const res = await materialclassification({
        type: 'gimg'
      })
      this.imageclassList = res.data

      if (this.imageclassList.length > 10) {
        this.showMoreButton = true
      }

      this.searchOwnImg()
      this.searchImage()
    },
    // 切换背景
    checkoutBgc (index) {
      this.color = this.bgcList[index]
      this.postInfo.background.backgroundColor = this.bgcList[index]
      this.postInfo.background.backgroundImage = ''
      this.setEditModule(this.postInfo.background)
    },
    // 上传图片
    uploadImage () {
      this.imputDom.click()
    },
    getObjectURL (file) {
      var url = null
      if (window.createObjectURL != undefined) { // basic
        url = window.createObjectURL(file)
      } else if (window.URL != undefined) { // mozilla(firefox)
        url = window.URL.createObjectURL(file)
      } else if (window.webkitURL != undefined) { // webkit or chrome
        url = window.webkitURL.createObjectURL(file)
      }
      return url
    },
    selecteImage (src) {
      this.setBackImage(src)
      this.postInfo.background.backgroundColor = ''
      // this.$emit('selectImage',src)
    }
  },
  watch: {
    color (newvalue, oldvalue) {
      this.postInfo.background.backgroundColor = newvalue
      this.postInfo.background.backgroundImage = ''
      this.setEditModule(this.postInfo.background)
      this.debouncePushHistoryRecord()
    },
    'postInfo.background.id' (newvalue, oldvalue) {
      if (this.postInfo.background.backgroundColor) {
        this.color = this.postInfo.background.backgroundColor
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.noneModelInfo {
  position: absolute;
  color: white;
  top: 50%;
  left: 45%;
  width: 200px;
  transform: translateX(-50%) translateY(-50%);
}
.cancel-button {
  position: absolute;
  right: 0;
  top: 37%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  color: #ffffff;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.hang {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 37%;
  width: 18px;
  height: 18px;
  color: #9da2ad;
}
.image-area {
  width: 100%;
  position: absolute;
  top: 250px;
  bottom: 60px;
  overflow-x: hidden;
  overflow-y: scroll;
  .my-item {
    cursor: pointer;
    float: left;
    width: 136px;
    height: 136px;
    margin: 0 20px 20px 0;
    border-radius: 5px;
    background-color: #f6f6f6;
    position: relative;
    .item-mask {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      z-index: 1;
      &:hover {
        background-color: #0a40ad1c;
      }
    }
    // background-color: yellowgreen;
    img {
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto;
      left: 0px;
      right: 0px;
    }
  }
}

.more-button {
  height: 30px;
  text-align: center;
  color: white;
  line-height: 30px;
  font-size: 20px;
  width: 100%;
  background-color: gray;
  margin-bottom: 10px;
  font-weight: 900;
}
.template-list {
  // 背景色
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 350px;

  overflow: hidden;
  .bgc {
    margin: 38px 0 0 28px;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      width: 30px;
      height: 30px;
      border: 1px solid #000000;
      border-radius: 4px;
      margin: 0 12px 12px 0;
      cursor: pointer;
    }
  }
  // 搜索
  .search {
    text-align: center;
    width: 300px;
    position: relative;
    margin: 0 28px;

    input {
      background-color: #1d1e23;
      width: 280px;
      height: 60px;
      border-radius: none;
      border: none;
      border-bottom: 1px solid #9da2ad;
      padding-left: 29px;
      color: #9da2ad;
      outline: none;
    }
  }
  // 模板
  .template-category {
    width: 294px;
    margin: 0 28px;
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    border-bottom: 1px solid #2b2c2f;
    padding-top: 27px;
    .category {
      margin-right: 43px;
      cursor: pointer;
    }
    .active {
      border-bottom: 2px solid #fff;
      padding-bottom: 6px;
    }
  }
  .template-centent {
    margin: 27px 0 0 28px;
    display: flex;
    flex-wrap: wrap;
  }
}
.updataButton {
  width: 250px;
  height: 50px;
  background: rgb(0, 204, 255);
  overflow: hidden;
  position: absolute;
  bottom: 10px;
  border-radius: 10px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 900;
  margin-left: 20px;
}

.class-item {
  width: 50px;
  background-color: #9da2ad4f;
  color: white;
  border-radius: 5px;
  text-align: center;
  float: left;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
}
.active {
  background-color: rgb(0, 140, 255);
}
.classItemList {
  display: block;
  float: left;
}

.backimagearea {
  width: 100%;
  display: block;
  float: left;
  margin-top: 20px;
}
</style>
