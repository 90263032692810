import { render, staticRenderFns } from "./PoductDetail.vue?vue&type=template&id=a18dbb00&scoped=true&"
import script from "./PoductDetail.vue?vue&type=script&lang=js&"
export * from "./PoductDetail.vue?vue&type=script&lang=js&"
import style0 from "./PoductDetail.vue?vue&type=style&index=0&id=a18dbb00&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a18dbb00",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data-jenkins/jenkins/workspace/html-cube-webapi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a18dbb00')) {
      api.createRecord('a18dbb00', component.options)
    } else {
      api.reload('a18dbb00', component.options)
    }
    module.hot.accept("./PoductDetail.vue?vue&type=template&id=a18dbb00&scoped=true&", function () {
      api.rerender('a18dbb00', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/DesignEditor/component/print/PoductDetail.vue"
export default component.exports