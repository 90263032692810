<template>
  <div class="QRCodeEdit" @keydown.stop>
    <!-- 二维码 -->
    <div class="pre-header">二维码</div>
    <div class="pre-active">
      <div class="code-text">二维码内容</div>
      <el-select size="small" v-model="selectModel" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div v-if="selectModel == 2" class="link-area">
        <el-input
          size="small"
          v-model="url"
          @keydown.stop
          style="width: 70%; float: left"
        ></el-input>
        <el-button type="primary" class="left" @click="submitLink"
          >确定</el-button
        >
      </div>

      <!-- <el-button v-show="selectModel==2" type="primary" class='left'>确定</el-button> -->
      <div v-if="selectModel == 3">
        <el-row style="margin-top: 30px">
          <el-col :span="16"
            ><el-input
              size="small"
              placeholder="请输入微信公众号ID"
              v-model="WXcode"
              @keydown.stop
            ></el-input
          ></el-col>
          <el-col :span="8"
            ><el-button
              @click="commitCode"
              type="primary"
              style="margin-left: 30px"
              >确定</el-button
            ></el-col
          >
        </el-row>
      </div>

      <div v-if="selectModel == 1">
        <div class="create-active">
          <div class="create-text">我创建的活动</div>
          <div
            class="create-all"
            @click="$router.push({ name: 'OwnActivity' })"
          >
            全部
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="pre-img">
          <div
            class="img-item"
            v-for="(item, index) in activeList"
            :key="index"
            @click="selecteActive(item)"
            :class="{ active: item.activityId == current }"
          >
            <div class="left-img">
              <img :src="item.templePage.activityBase.cover" alt="" />
            </div>
            <div class="margin-left-5">
              <div class="activityName">{{ item.name }}</div>
              <div class="activeType">{{ typeList[item.templeType] }}</div>
            </div>
          </div>
        </div>
        <div align="right">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="limit"
            layout="total, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
        <div align="center">
          <el-button @click="ensureActivity" type="primary">确定</el-button>
        </div>
      </div>
    </div>
    <!-- 玩法 -->
    <!-- 抽奖活动展示 -->
    <div
      @click="displayActivity = !displayActivity"
      v-if="selectModel == 1"
      class="create-act"
    >
      创建新活动
      <i
        class="el-icon-arrow-right narrow"
        :style="{
          transform: `${displayActivity ? 'rotate(90deg)' : ''}`,
        }"
      ></i>
    </div>
    <div class="activity-list" v-show="displayActivity">
      <activity-list v-if="selectModel == 1"></activity-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { queryAll } from '@/api/active.js'
import { parseQrCode } from '@/api/myDesign.js'
import { getRoleIds } from '@/lib/util'

import ActivityList from './ActivityList.vue'

export default {
  components: { ActivityList },
  data () {
    return {
      playShow: true,
      options: [{
        value: 1,
        label: '营销活动'
      }, {
        value: 2,
        label: '链接'
      }, {
        value: 3,
        label: '微信公众号'
      }],
      selectModel: 2,
      activeList: [],
      page: 1,
      limit: 4,
      total: 0,
      WXcode: '',
      h5Url: `${this.$url}/cube_web_api/auth/authWeChatUrl?userId=`, // 微信扫码进入H5页面
      current: null,
      url: '',
      selectActivity: {},
      typeList: ['开宝箱', '九宫格', '砸金蛋', '大转盘', '阅读有礼'],
      displayActivity: true
    }
  },
  computed: {
    ...mapState('edit', ['editModule'])
  },
  watch: {
    'editModule.id' (newV, oldV) {
      this.selectModel = Number(this.editModule.codeType)
      this.url = this.editModule.url
      this.WXcode = this.editModule.typeInfo.subscription
      this.current = this.editModule.typeInfo.activity.activityId
      this.selectActivity = { ...this.editModule.typeInfo.activity }
    },
    selectModel (nv, ov) {
      console.log(nv)
      if (localStorage.getItem('token') == null && nv === 1) {
        this.selectModel = ov
        this.$Login()
      }
    }
  },
  mounted () {
    this.init()
    this.selectModel = Number(this.editModule.codeType)
    this.url = this.editModule.url
    this.WXcode = this.editModule.typeInfo.subscription
    this.current = this.editModule.typeInfo.activity.activityId
    this.selectActivity = { ...this.editModule.typeInfo.activity }
  },
  methods: {
    submitLink () {
      if (this.url === '') {
        this.$message({
          message: '二维码内容不能为空',
          type: 'warn'
        })
        return
      }
      this.editModule.codeType = 2
      this.editModule.url = this.url
      this.editModule.typeInfo = {
        activity: {},
        subscription: ''
      }
      this.$message({
        message: '保存成功',
        type: 'success'
      })
      this.selectActivity = {}
    },
    async commitCode () {
      const res = await parseQrCode({
        url: `https://open.weixin.qq.com/qr/code?username=${this.WXcode}`
      })
      if (res.code === 0) {
        this.editModule.url = res.data
        this.editModule.codeType = 3
        this.editModule.typeInfo = {
          activity: {},
          subscription: this.WXcode
        }
        this.selectActivity = {}
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      } else {
        this.$message({
          message: '公众号有误',
          type: 'warning'
        })
      }
    },
    handleCurrentChange (val) {
      this.page = val
      this.search()
    },
    async init () {
      if (localStorage.getItem('token') == null) {
        return
      }
      this.search()
    },
    selecteActive (item) {
      this.current = item.activityId
      this.selectActivity.activityId = item.activityId
      this.selectActivity.activityImgUrl = item.templePage.activityBase.activityImgUrl
    },
    ensureActivity () {
      if (!this.selectActivity.activityId) {
        this.$message({
          message: '未选择活动',
          type: 'warning'
        })
        return
      }
      const redirectUrl = encodeURIComponent('https://cubewechat.51ylk.com/#/pages/shareIn/shareIn?activity=' + this.selectActivity.activityId)
      this.editModule.url = this.h5Url + getRoleIds().userId + '&redirectUrl=' + redirectUrl
      this.editModule.codeType = 1
      this.editModule.typeInfo = {
        activity: {
          activityId: this.selectActivity.activityId,
          activityImgUrl: this.selectActivity.activityImgUrl
        },
        subscription: ''
      }
      this.$message({
        message: '保存成功',
        type: 'success'
      })
    },
    async search () {
      const res = await queryAll({
        pageNO: this.page,
        pageSize: this.limit
      })
      this.activeList = res.data.list
      this.activeList.forEach(e => {
        e.templePage = JSON.parse(e.templePage)
      })
      this.total = res.data.total
    },
    toMarketingActivities () {
      const { href } = this.$router.resolve({ name: 'MarketingActivities' })
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.margin-left-5 {
  margin-left: 5px;
}

.link-area {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 40px;
  margin-top: 30px;
}
.left {
  float: left;
}
.activityName {
  display: inline-block;
  width: 100px;
  overflow: hidden; /*超出部分隐藏*/
  white-space: nowrap; /*不换行*/
  text-overflow: ellipsis; /*超出部分文字以...显示*/
}
.QRCodeEdit {
  width: 370px;
}
.el-pagination {
  /deep/ li {
    background-color: rgba(0, 0, 0, 0);
  }
  /deep/ .btn-next {
    background-color: rgba(0, 0, 0, 0);
  }
  /deep/ .btn-prev {
    background-color: rgba(0, 0, 0, 0);
  }
}
.create-act {
  font-size: 12px;
  color: #7e838e;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}
.narrow {
  float: right;
  transition: 0.2s;
}
/deep/ .el-input__inner {
  background-color: #1d1e23;
}
.pre-header {
  padding: 16px 0 15px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  border-bottom: 1px solid #2b2c2f;
}
// 二维码 活动
.pre-active {
  padding: 0 17px 29px 10px;
  border-bottom: 1px solid #2b2c2f;
  .code-text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7e838e;
    margin: 28px 0 15px 0;
  }
  .active-selection {
    position: relative;
    width: 144px;
    height: 34px;
    padding-left: 16px;
    background: #1d1e23;
    border: 2px solid #303235;
    border-radius: 4px;
    line-height: 30px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    i {
      position: absolute;
      top: 11px;
      right: 17px;
    }
  }
  .create-active {
    margin: 31px 0 16px 0;
    overflow: hidden;
    .create-text {
      float: left;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8b909a;
    }
    .create-all {
      cursor: pointer;
      float: right;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #4e5258;
      i {
        margin-left: 8px;
      }
    }
  }
  .pre-img {
    display: flex;
    flex-wrap: wrap;
    .img-item {
      cursor: pointer;
      margin-bottom: 10px;
      margin-right: 10px;
      border: 1px solid #ccc;
      display: flex;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      align-items: center;
      width: 160px;
      padding: 5px;
      img {
        width: 50px;
        height: 50px;
        vertical-align: middle;
      }
    }
    .active {
      border: 1px solid #f60;
      color: #f60;
    }
    .left-img {
      width: 50%;
    }
  }
}
// 玩法
.play-way {
  padding: 24px 17px 27px 8px;
  .play-text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7e838e;
  }
  .play-list {
    display: flex;
    margin: 12px 0 11px 0;
    li {
      padding: 6px 9px;
      background: #404144;
      border-radius: 4px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #969ba5;
      margin-right: 12px;
    }
  }
  .play-detail {
    .detail-list {
      background-color: #2b2c2f;
      padding: 31px 0 17px 15px;
      .industry {
        margin-top: 41px;
      }
    }
    .play-show {
      height: 46px;
      line-height: 46px;
      text-align: center;
      background: #232427;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #5d6269;
      cursor: pointer;
    }
  }
}
// 抽奖活动展示
.active-show {
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
  li {
    width: 164px;
    margin: 0 15px 9px 0;
    text-align: center;
    img {
      width: 164px;
      height: 164px;
    }
    p {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin: 14px 0 21px 0;
    }
  }
}
.activity-list {
  overflow: hidden;
  transition: 0.2s;
}
</style>
