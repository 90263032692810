<template>
  <div>
    <div class="title" @click="addTitle">
      <span draggable="true">添加正标题</span>
    </div>
    <div draggable="true" class="subtitle" @click="addSubtitle">
      <span draggable="true">添加副标题</span>
    </div>
    <div draggable="true" class="content" @click="addContent">
      <span draggable="true">添加正文</span>
    </div>
  </div>
</template>

<script>
import model from '@/lib/model'
export default {
  data () {
    return {

    }
  },
  methods: {
    // drag(item){
    //   // console.log(event)
    //   event.dataTransfer.setData("imgUrl",item);
    // },
    addTitle () {
      const textParam = model.createText({
        text: '添加正标题',
        width: 320,
        fontSize: 60,
        bold: true,
        italic: false,
        underline: false
      })
      this.$emit('pushText', textParam)
    },
    addSubtitle () {
      const textParam = model.createText({
        text: '添加正标题',
        width: 210,
        fontSize: 40,
        bold: true,
        italic: false,
        underline: false
      })

      this.$emit('pushText', textParam)
    },
    addContent () {
      const textParam = model.createText({
        text: '添加正标题',
        width: 160,
        fontSize: 28,
        bold: true,
        italic: false,
        underline: false
      })

      this.$emit('pushText', textParam)
    }
  }

}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 24px;
  font-weight: 400;
  color: white;
  cursor: pointer;
}
.subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
  font-weight: 400;
  color: white;
  cursor: pointer;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 18px;
  font-weight: 200;
  color: white;
  cursor: pointer;
}
</style>
