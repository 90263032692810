var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-list" },
    [
      _c("div", { staticClass: "bgc" }, [
        _c(
          "ul",
          [
            _vm._l(_vm.bgcList, function(item, index) {
              return _c("li", {
                key: index,
                style: { background: item },
                on: {
                  click: function($event) {
                    return _vm.checkoutBgc(index)
                  }
                }
              })
            }),
            _c(
              "li",
              [
                _c("color-picker", {
                  attrs: { "show-alpha": "", size: "mini" },
                  model: {
                    value: _vm.color,
                    callback: function($$v) {
                      _vm.color = $$v
                    },
                    expression: "color"
                  }
                })
              ],
              1
            )
          ],
          2
        )
      ]),
      _c("div", { staticClass: "search" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.imageName,
              expression: "imageName"
            }
          ],
          attrs: { placeholder: "搜索背景图片" },
          domProps: { value: _vm.imageName },
          on: {
            keydown: function($event) {
              $event.stopPropagation()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.initSearch($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.imageName = $event.target.value
            }
          }
        }),
        _vm.imageName
          ? _c("i", {
              staticClass: "el-icon-close cancel-button",
              on: { click: _vm.resetSearch }
            })
          : _vm._e(),
        _c("i", {
          staticClass: "el-icon-search hang",
          on: { click: _vm.initSearch }
        })
      ]),
      _c("div", { staticClass: "template-category" }, [
        _c(
          "div",
          {
            staticClass: "category",
            class: { active: _vm.current === 0 },
            on: {
              click: function($event) {
                _vm.current = 0
              }
            }
          },
          [_vm._v(" 背景库 ")]
        ),
        !_vm.admin
          ? _c(
              "div",
              {
                staticClass: "category",
                class: { active: _vm.current === 1 },
                on: { click: _vm.uploadPicture }
              },
              [_vm._v(" 上传背景图 ")]
            )
          : _vm._e()
      ]),
      _vm.current == 0
        ? _c("div", { staticClass: "template-centent" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.searchImage,
                    expression: "searchImage"
                  }
                ],
                staticClass: "image-area"
              },
              [
                _c(
                  "div",
                  { staticClass: "classItemList" },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "class-item",
                          { active: _vm.classificationId == "" }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.seleteType("")
                          }
                        }
                      },
                      [_vm._v(" 全部 ")]
                    ),
                    _vm._l(_vm.imageclassList, function(imageClass, index) {
                      return [
                        (index < 8 && _vm.showMoreButton) || !_vm.showMoreButton
                          ? _c(
                              "div",
                              {
                                key: index,
                                class: [
                                  "class-item",
                                  {
                                    active:
                                      _vm.classificationId == imageClass.id
                                  }
                                ],
                                on: {
                                  click: function($event) {
                                    return _vm.seleteType(imageClass.id)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(imageClass.name) + " ")]
                            )
                          : _vm._e()
                      ]
                    }),
                    _vm.showMoreButton
                      ? _c(
                          "div",
                          {
                            staticClass: "class-item",
                            on: {
                              click: function($event) {
                                _vm.showMoreButton = false
                              }
                            }
                          },
                          [_vm._v(" 更多 ")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "backimagearea" },
                      _vm._l(_vm.pictureList, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "my-item",
                            on: {
                              click: function($event) {
                                return _vm.selecteImage(item)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "item-mask" }),
                            _c("img", {
                              attrs: {
                                src: item + "?imageMogr2/thumbnail/!50p"
                              }
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  2
                )
              ]
            )
          ])
        : _c("div", { staticClass: "template-centent" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.searchOwnImg,
                    expression: "searchOwnImg"
                  }
                ],
                staticClass: "image-area"
              },
              _vm._l(_vm.imageUpload, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "my-item",
                    on: {
                      click: function($event) {
                        return _vm.selecteImage(item)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "item-mask" }),
                    _c("img", {
                      attrs: { src: item + "?imageMogr2/thumbnail/!50p" }
                    })
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "updataButton" },
              [
                _c(
                  "upload-file",
                  { attrs: { img: true }, on: { addFile: _vm.addImgEvent } },
                  [_c("i", { staticClass: "el-icon-plus" })]
                )
              ],
              1
            )
          ]),
      _c("material-list", {
        ref: "material",
        on: { selecteImage: _vm.selecteImage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }