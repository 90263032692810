<template>
  <div class="template-centent" v-infinite-scroll="loadOwnModel">
    <div class="ownModel" v-if="myModelList && myModelList.length == 0">
      <div class="box-icon"><i class="el-icon-box"></i></div>
      <div>没有内容</div>
      <div>去<span class="link" @click="toModelCener">模板中心</span>看看</div>
    </div>
    <div v-if="myModelList && myModelList.length != 0">
      <div class="my-item" v-for="(itemList, index) in imgShow" :key="index">
        <div
          class="recommend-item"
          v-for="item in itemList"
          :key="item.id"
          @click="$emit('selectTemplate', item)"
        >
          <img :src="item.coverImg" />
        </div>
      </div>
    </div>
    <!-- <div class='more' v-if="myModelListMore" @click="searchModel">更多</div> -->
  </div>
</template>

<script>
import config from '@/config/index'
import { followqueryPage } from '@/api/cubeclient'
export default {
  data () {
    return {
      myModelListPage: 1,
      myModelListMore: true,
      limit: 20,
      isLogin: false,
      imgShow: [
        [],
        []
      ],
      columnHeight: [0, 0],
      myModelList: []
    }
  },
  create () {
    if (localStorage.getItem('token') == null) {
      this.isLogin = false
    } else {
      this.isLogin = true
    }
  },
  methods: {
    toModelCener () {
      const { href } = this.$router.resolve({ name: 'Selection' })
      window.open(href, '_blank')
    },

    initWaterfall () {
      this.imgShow = [
        [],
        []
      ]
      this.columnHeight = [0, 0]
    },
    loadOwnModel () {
      if (this.myModelListMore) {
        this.searchModel()
      }
    },
    // 搜索我的模板
    async searchModel () {
      if (config.admin) {
        return
      }
      if (localStorage.getItem('token') == null) {
        this.initWaterfall()
        return
      }
      const res = await followqueryPage({
        pageNo: this.myModelListPage++,
        pageSize: this.limit,
      })
      if (res.code == 401) {
        this.$message({
          message: '登录失效请重新登录',
          type: 'warning'
        })
        localStorage.removeItem('token')
        location.reload()
        return
      }
      if (res.data.list == null || res.data.list.length < this.limit) {
        this.myModelListMore = false
      }
      if (res.data.list == null || res.data.list.length == 0) {
        return
      }
      this.myModelList = [...this.myModelList, res.data.list]
      const imgList = res.data.list
      /**
         * 瀑布流操作
         */
      const loadImage = () => {
        const imgItem = imgList.pop()
        const img = new Image()
        img.src = imgItem.coverImg
        const columnHeight = this.columnHeight
        const imgShow = this.imgShow
        img.onload = () => {
          let minIndex = 0
          let minValue = columnHeight[0]
          columnHeight.forEach((e, index) => {
            if (e < minValue) {
              minValue = e
              minIndex = index
            }
          })
          imgShow[minIndex].push(imgItem)
          columnHeight[minIndex] += img.naturalHeight / img.naturalWidth * 230

          if (imgList.length != 0) {
            loadImage()
          }
        }
      }
      loadImage()
    }
  }
}
</script>

<style lang="scss" scoped>
.box-icon {
  color: rgb(0, 174, 255);
  font-size: 30px;
}
.template-centent {
  width: 350px;
  padding: 28px 0 0 28px;
  padding-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  height: 750px;
  .ownModel {
    width: 100%;
    height: 60px;
    padding-right: 20px;
    text-align: center;
    color: white;
    position: absolute;
    top: 35%;
    .link {
      color: rgb(0, 174, 255);
      cursor: pointer;
    }
    // background-color: rgb(182, 182, 182);
  }
  .my-item {
    cursor: pointer;
    display: inline-block;
    float: left;
    width: 136px;
    // height: 242px;
    margin: 0 20px 20px 0;
  }
  img {
    width: 100%;
    height: 100%;
    margin-bottom: 5px;
  }
  .own-item {
    float: left;
    width: 136px;
    margin: 0 20px 20px 0;
    cursor: pointer;
    img {
      margin-bottom: 5px;
      width: 100%;
    }
  }
}
</style>
