import store from '@/store/index'
import vue from 'vue'
let canvasDom
/**
 * 所有的快捷键操作
 */
class Shortcutkey {
  constructor () {
    this.scrollDom = undefined
  }

  keyEvent (event) {
    const shiftKey = event.shiftKey // 是否按下shift
    const ctrlKey = event.ctrlKey// 是否按下ctrl
    const keyCode = event.keyCode// 键值
    if (keyCode === 83) {
      if (ctrlKey) {
        event.preventDefault()
        vue.prototype.$bus.$emit('save')
      }
    }
    if (keyCode === 76) {
      if (ctrlKey) {
        event.preventDefault()
        store.commit('edit/lock')
      }
    }
    if (keyCode === 37) {
      if (shiftKey) {
        store.commit('edit/moveLeft', -3)
      } else {
        store.commit('edit/moveLeft', -1)
      }
    }
    if (keyCode === 38) {
      if (shiftKey) {
        store.commit('edit/moveTop', -3)
      } else {
        store.commit('edit/moveTop', -1)
      }
    }
    if (keyCode === 39) {
      if (shiftKey) {
        store.commit('edit/moveLeft', 3)
      } else {
        store.commit('edit/moveLeft', 1)
      }
    }
    if (keyCode === 40) {
      if (shiftKey) {
        store.commit('edit/moveTop', 3)
      } else {
        store.commit('edit/moveTop', 1)
      }
    }

    if (keyCode === 67) {
      if (ctrlKey && store.getters['edit/canCopy']) {
        store.commit('edit/copy')
      }
    }
    if (keyCode === 86) {
      if (ctrlKey && store.getters['edit/canPaste']) {
        store.commit('edit/paste')
        store.commit('edit/pushHistoryRecord')
      }
    }
    if (keyCode === 88) {
      if (ctrlKey && store.getters['edit/canCopy']) {
        store.commit('edit/shear')
        store.commit('edit/pushHistoryRecord')
      }
    }
    if (keyCode === 90) {
      if (ctrlKey && store.getters['edit/revokedAbled'] && shiftKey == false) {
        store.commit('edit/back')
        store.commit('edit/setBackEditModule')
      }
      if (ctrlKey && store.getters['edit/redoAbled'] && shiftKey == true) {
        store.commit('edit/next')
        store.commit('edit/setBackEditModule')
      }
    }
    if (keyCode === 65) {
      if (ctrlKey) {
        store.commit('edit/selectAll')
      }
    }
    if (keyCode === 8) {
      if (store.getters['edit/canDelete']) {
        store.commit('edit/removeModule')
      }
    }
    if (keyCode === 46) {
      if (store.getters['edit/canDelete']) {
        store.commit('edit/removeModule')
      }
    }
    if (keyCode === 71) {
      if (ctrlKey && !shiftKey && store.state.edit.group && store.state.edit.group.id == undefined) {
        store.commit('edit/addGroup')
      }
      if (ctrlKey && shiftKey && store.state.edit.group && store.state.edit.group.id != undefined) {
        store.commit('edit/splitGroup')
      }
    }
    if (keyCode === 221) {
      if (store.getters['edit/canDelete'] && ctrlKey && !shiftKey) {
        store.commit('edit/adjustLayers', 'up')
        store.commit('edit/pushBack')
      }
      if (store.getters['edit/canDelete'] && ctrlKey && shiftKey) {
        store.commit('edit/adjustLayers', 'top')
        store.commit('edit/pushBack')
      }
    }
    if (keyCode === 219) {
      if (store.getters['edit/canDelete'] && ctrlKey && !shiftKey) {
        store.commit('edit/adjustLayers', 'down')
        store.commit('edit/pushBack')
      }
      if (store.getters['edit/canDelete'] && ctrlKey && shiftKey) {
        store.commit('edit/adjustLayers', 'bottom')
        store.commit('edit/pushBack')
      }
    }
    if (keyCode === 96 || keyCode === 48) {
      if (ctrlKey) {
        const canvasSize = {
          width: canvasDom.offsetHeight,
          height: canvasDom.offsetWidth
        }
        const canvas = store.state.edit.postInfo.canvas
        const rateW = (canvasSize.width - 40) / canvas.width
        const rateH = (canvasSize.height - 40) / canvas.height
        let rate = rateW > rateH ? rateH : rateW
        if (rate * 100 < 1) {
          rate = 0.01
        }
        if (rate * 100 > 400) {
          rate = 4
        }
        store.commit('edit/setScale', Math.floor(rate * 100))
      }
    }
    if (keyCode === 107) {
      event.returnValue = false
      if (ctrlKey) {
        const scale = store.state.edit.scale
        if (scale >= 400) {
          return
        }
        if (scale < 10) {
          store.commit('edit/setScale', scale + 1)
          return
        }
        store.commit('edit/setScale', scale + 10)
        return false
      }
    }
    if (keyCode === 109) {
      event.returnValue = false
      if (ctrlKey) {
        const scale = store.state.edit.scale
        if (scale <= 1) {
          return
        }
        if (scale < 20 && scale > 10) {
          store.commit('edit/setScale', 10)
        }
        if (scale <= 10) {
          store.commit('edit/setScale', scale - 1)
          return
        }
        store.commit('edit/setScale', scale - 10)
        return false
      }
    }
  }

  mousewheelEvent (event) {
    const ctrlKey = event.ctrlKey
    if (event.wheelDelta > 0) {
      if (ctrlKey) {
        event.preventDefault()
        const scale = store.state.edit.scale
        if (scale >= 400) {
          return
        }
        if (scale < 10) {
          store.commit('edit/setScale', scale + 1)
          return
        }
        store.commit('edit/setScale', scale + 10)

        return false
      }
    } else {
      if (ctrlKey) {
        event.preventDefault()
        const scale = store.state.edit.scale
        if (scale <= 1) {
          return
        }
        if (scale < 20 && scale > 10) {
          store.commit('edit/setScale', 10)
        }
        if (scale <= 10) {
          store.commit('edit/setScale', scale - 1)
          return
        }
        store.commit('edit/setScale', scale - 10)
        return false
      }
    }
  }

  initShortcutkey (dom) {
    this.scrollDom = dom
    canvasDom = dom
    window.addEventListener('keydown', this.keyEvent)
    dom.addEventListener('mousewheel', this.mousewheelEvent)
  }

  destoryShortcutkey () {
    window.removeEventListener('keydown', this.keyEvent)
    this.scrollDom.removeEventListener('keydown', this.mousewheelEvent)
  }
}

export default Shortcutkey
