/* eslint-disable eqeqeq */
<template>
  <div class="posters-editTwo">
    <header>
      <edit-head :model="saveInfo"></edit-head>
    </header>
    <div class="content">
      <!--左侧导航-->
      <div class="menus">
        <ul>
          <template v-for="(item, index) in sidebarMenus">
            <li
              v-if="!admin || (index != 0 && index != 4)"
              :key="index"
              class="menus-item"
              @click="menusChange(item.name)"
              :class="{ active: item.name === activeSideBar }"
            >
              <i
                v-if="index == 0"
                class="iconfont icon-mobanguanli icon-size"
              ></i>
              <i v-if="index == 1" class="iconfont icon-wenben icon-size"></i>
              <i v-if="index == 2" class="iconfont icon-tupian icon-size"></i>
              <i v-if="index == 3" class="iconfont icon-beijing icon-size"></i>
              <i v-if="index == 4" class="iconfont icon-erweima icon-size"></i>
              <p>{{ item.label }}</p>
            </li>
          </template>

          <li
            slot="reference"
            @click="showShortcutkeyList = true"
            class="menus-item shortcut-key"
          >
            <i class="iconfont icon-jianpan icon-size"></i>
            <p>快捷键</p>
          </li>
          <ShortcutkeyList
            v-if="showShortcutkeyList"
            @close="showShortcutkeyList = false"
          ></ShortcutkeyList>
        </ul>
      </div>
      <!--模板&文本&图片&背景&二维码-->
      <div class="editor-show" v-show="showEditItem">
        <!-- {{showEditItem}} -->
        <div class="hide-icon" @click="showEditItem = false">
          <i class="el-icon-caret-left"></i>
        </div>

        <templateList
          v-if="!admin"
          v-show="activeSideBar === 'templateList'"
          ref="templateList"
        />
        <textList v-show="activeSideBar === 'textList'" @pushText="pushText" />
        <imagesList
          v-show="activeSideBar === 'imagesList'"
          @pushImage="pushImage"
        />
        <backgroundList v-show="activeSideBar === 'backgroundList'" />
        <codeList
          v-if="!admin"
          @addImage="addImage"
          v-show="activeSideBar === 'codeList'"
        />
      </div>
      <!-- 编辑内容区域 -->
      <div
        class="editor-content"
        ref="editorContent"
        :style="{
          left: `${editPosition.left}px`,
        }"
      >
        <div class="InputNumber">
          <div class="pointer" @click="narrow">
            <i class="el-icon-minus"></i>
          </div>
          <div>{{ scale }}%</div>
          <div class="pointer" @click="enlarge">
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <div
          class="canvas-content"
          @click="setEditModule(postInfo.background)"
          ref="canvasContent"
          v-on:scroll="contentScroll"
        >
          <!-- <back-com :anime='editPanelAnime'></back-com> -->
          <div class="canvas-content-padding">
            <show-com :anime="editPanelAnime"></show-com>
            <edit-com :anime="editPanelAnime"></edit-com>
            <receive v-show="colorPicker" :reload="colorPicker"></receive>
            <empty-com v-if="dragEvent"></empty-com>
          </div>
        </div>
      </div>

      <post-list
        v-if="saveInfo.psdList && saveInfo.psdList.length != 1"
        :max="saveInfo.psdList.length"
      ></post-list>
      <navigation :scrollInfo="scrollInfo"></navigation>

      <!-- 右侧展示区域 -->
      <div class="editor-preview">
        <group-edit v-if="group && group.layerIds.length > 1"></group-edit>
        <div class="lock-mask" v-if="editModule.lock"></div>
        <back-edit v-if="editModule.type == 'background'"></back-edit>
        <text-edit v-if="editModule.type == 'text'"></text-edit>
        <image-edit v-if="editModule.type == 'image'"></image-edit>
        <qr-code-edit v-if="editModule.type == 'code'"></qr-code-edit>
      </div>
    </div>

    <el-dialog
      title="在线印刷"
      :visible.sync="printModelDialog"
      width="1400px"
      center
    >
      <div class="print-content">
        <div>
          <el-steps :active="printActive">
            <el-step title="自助下单"></el-step>
            <el-step title="确认订单"></el-step>
            <el-step title="支付"></el-step>
          </el-steps>
        </div>
        <poduct-detail
          v-if="printActive == 1 && printModelDialog"
          :printInfo="printInfo"
          @getOrderItem="getOrderItem"
          :sourceWorkId="saveInfo.id"
        ></poduct-detail>
        <order-item
          v-if="printActive == 2 && printModelDialog"
          @toPayment="toPayment"
          :orderItem="orderItem"
          :sourceWorkId="saveInfo.id"
          @toPrintInfo="printActive = 1"
        ></order-item>
        <payment
          v-if="printActive == 3 && printModelDialog"
          :paymentInfo="paymentInfo"
        ></payment>
      </div>
    </el-dialog>

    <div class="origin-pic">
      <origin-com></origin-com>
    </div>
  </div>
</template>

<script>
import OriginCom from './component/panel/OriginCom'
import config from '@/config'
import EditHead from './component/EditHead.vue'
import templateList from './component/modular/templateList.vue'
import textList from './component/modular/textList.vue'
import imagesList from './component/modular/imagesList.vue'
import backgroundList from './component/modular/backgroundList.vue'
import codeList from './component/modular/codeList.vue'
import QrCodeEdit from './component/edit/QRCodeEdit.vue'
import BackEdit from './component/edit/BackEdit.vue'
import TextEdit from './component/edit/TextEdit.vue'
import GroupEdit from './component/edit/groupEdit.vue'
import ImageEdit from './component/edit/ImageEdit.vue'
import PostList from './component/panel/PostList.vue'
import Navigation from './component/operation/Navigation.vue'
import { mapState, mapMutations } from 'vuex'
import { getSpuItemByCatalogId } from '@/api/myDesign'
import { updateBatch, planarjsonget, saveBatch, userplanargetone, userplanargetupdate, getPrice, checkUserHave } from '@/api/cubeclient'
import EditCom from './component/panel/EditCom.vue'
import ShowCom from './component/panel/ShowCom.vue'
import Receive from './component/panel/Receive.vue'
import OrderItem from './component/print/OrderItem.vue'
import model from '@/lib/model'
import PoductDetail from './component/print/PoductDetail'
import Payment from './component/print/Payment'
import { getRoleIds } from '@/lib/util'
import _ from 'lodash'
import tool from '@/lib/tool'
import EmptyCom from './component/panel/EmptyCom'
import materialList from './component/modular/materialList.vue'
import Shortcutkey from './lib/Shortcutkey'
import ShortcutkeyList from './component/common/shortcutKeyList.vue'
const cutkey = new Shortcutkey()
export default {
  components: {
    EditHead,
    templateList,
    textList,
    imagesList,
    backgroundList,
    codeList,
    QrCodeEdit,
    BackEdit,
    TextEdit,
    ImageEdit,
    PostList,
    Navigation,
    EditCom,
    ShowCom,
    ShortcutkeyList,
    PoductDetail,
    OrderItem,
    Payment,
    Receive,
    OriginCom,
    EmptyCom,
    GroupEdit,
    materialList
  },
  data () {
    return {
      showShortcutkeyList: false,
      admin: config.admin,
      activeSideBar: '',
      modelItem: {}, // 现模板信息
      sidebarMenus: [ // 左边选框信息
        {
          label: '模板',
          name: 'templateList',
          elementUiIcon: 'el-icon-s-operation'
        },
        {
          label: '文本',
          name: 'textList',
          elementUiIcon: 'el-icon-document'
        },
        {
          label: '素材',
          name: 'imagesList',
          elementUiIcon: 'el-icon-files'
        },
        {
          label: '背景',
          name: 'backgroundList',
          elementUiIcon: 'el-icon-files'
        },
        {
          label: '二维码',
          name: 'codeList',
          elementUiIcon: 'el-icon-files'
        }
      ],
      saveInfo: {}, // 保存所用信息
      showEditItem: false, // 展示编辑栏
      printModelDialog: false, // 在线印刷模态框
      printInfo: {}, // 印刷信息
      printActive: 1, // 印刷已经到了多少步
      paymentInfo: {}, // 付款信息,
      enterPage: true,
      editPanelAnime: false,
      scrollInfo: {
        left: 0,
        top: 0
      }
    }
  },
  created () {
    /**
     * 左边栏菜单更换
     */
    this.$bus.$on('menusChange', this.menusChange)
    this.$bus.$on('changeScroll', (top, left) => { // 编辑器可是画面滚动事件
      this.$refs.canvasContent.scrollLeft = left

      this.$refs.canvasContent.scrollTop = top
    })
    this.$bus.$on('save', () => { this.save() })// 保存
    this.$bus.$on('print', (file) => { this.print(file) })// 印刷
    this.init()// 初始化
  },
  mounted () {
    cutkey.initShortcutkey(this.$refs.canvasContent)
    this.resizeEdit()
  },
  beforeDestroy () {
    cutkey.destoryShortcutkey()
    this.$bus.$off('menusChange')
    this.$bus.$off('changeScroll')
    this.$bus.$off('save')
    this.$bus.$off('print')
  },
  computed: {
    ...mapState('edit', ['canvas', 'scale', 'editModule', 'postInfo',
      'editModuleList', 'postList', 'editSize', 'editPosition', 'colorPicker', 'dragEvent', 'group'])
  },
  methods: {

    ...mapMutations('edit', ['addText', 'addImage', 'initEdit', 'pushPost', 'setScale',
      'setEditModule', 'setCanvas', 'setPostInfo', 'pushHistoryRecord', 'pushLayers',
      'setEditSelectedId', 'createPost', 'setScroll', 'setPostInfoList', 'loadFont', 'setPostJsonId',
      'setEditSize', 'setWater']),
    /**
     * 窗口尺寸变化
     */
    resizeEdit () {
      this.$nextTick(() => {
        this.setEditSize({
          width: this.$refs.editorContent.clientWidth,
          height: this.$refs.editorContent.clientHeight
        })
      })
    },
    // 跳支付
    toPayment (paymentInfo) {
      this.paymentInfo = paymentInfo
      this.printActive = 3
    },
    // 跳订单详情
    getOrderItem (info) {
      this.orderItem = info
      this.orderItem.catalogId = this.modelItem.printCalalogId
      this.printActive = 2
    },
    /**
     * 是否展示水印
     */
    async waterInfo (model) {
      if (model.sourcePlanarId == '') {
        return false
      }

      const res = await getPrice({ id: model.id })
      if (res.code == 401) {
        return true
      }
      // this.salePrice = res.data.salePrice
      const free = res.data.free
      if (free) {
        return false
      }
      const limitedTimeFree = res.data.limitedTimeFree
      if (limitedTimeFree) {
        return false
      }
      if (localStorage.getItem('token') == null) {
        return true
      }

      const checkRes = await checkUserHave({ id: model.sourcePlanarId })
      if (checkRes.code == 401) {
        localStorage.removeItem('token')
        location.reload()
        return
      }

      const purchased = checkRes.data.purchased
      if (purchased) {
        return false
      }
      return true
    },
    // 印刷
    async print (file) {
      // 获取印刷信息
      if (this.modelItem.printCalalogId == null) {
        this.$message({
          type: 'error',
          message: '当前模板不可印刷'
        })
        return
      }
      const res = await getSpuItemByCatalogId({
        catalogId: this.modelItem.printCalalogId
      })

      file = file.map(item => {
        if (!item.startsWith('http')) {
          item = `https://${item}`
        }
        return item
      })
      this.printInfo = res.data
      this.printInfo.fileUrl = `${file}`
      this.printInfo.fileName = `${new Date().getTime()}.pdf`
      this.printInfo.catalogId = this.modelItem.printCalalogId
      this.printModelDialog = true
      this.printActive = 1
    },
    // 保存信息
    async save (login = true) {
      if (this.admin) {
        const param = []
        this.postList.forEach((e, index) => {
          param.push(
            {
              id: this.saveInfo.psdList[index].psdJsonId,
              psdJson: JSON.stringify(e)
            }
          )
        })
        const res = await updateBatch(param)
        if (res.code === 200 && !this.enterPage) {
          this.$message('保存成功')
        } else {
          this.enterPage = false
        }
      } else {
        const postList = _.cloneDeep(this.postList)
        await updateBatch([{
          id: this.saveInfo.psdJsonId,
          psdJson: JSON.stringify(postList)
        }])
        if (this.userInfo.userId) {
          this.saveInfo.updateUserId = this.userInfo.userId
        }
        const res = await userplanargetupdate(this.saveInfo)
        if (localStorage.getItem('token') == null && login) {
          this.$confirm('请登录', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$Login()
          })
        } else {
          this.setPostJsonId(this.saveInfo.psdJsonId)
          if (res.code === 200 && !this.enterPage) {
            this.$message('保存成功')
          } else {
            this.enterPage = false
          }
        }
      }
    },
    /**
     * 得到模板信息
     */
    async getModelItem () {
      const res = await userplanargetone({ id: this.$route.query.id })
      this.modelItem = res.data
    },
    /**
     * 面板滚动事件
     */
    contentScroll () {
      this.scrollInfo.left = this.$refs.canvasContent.scrollLeft
      this.scrollInfo.top = this.$refs.canvasContent.scrollTop
    },

    async init () {
      this.closeAnime = _.debounce(() => {
        this.editPanelAnime = false
      }, 500)
      this.initEdit()
      this.createPost()
      const token = localStorage.getItem('token')
      this.userInfo = token == null ? {} : getRoleIds()
      // 得到模板详情
      const modelRes = await userplanargetone({ id: this.$route.query.id })
      this.modelItem = modelRes.data
      modelRes.data.psdJsonId = modelRes.data.psdJsonId
      const jsonInfoList = modelRes.data.psdList
      const psdJsonId = modelRes.data.psdJsonId
      if (jsonInfoList == null && psdJsonId == null) {
        return
      }
      if (!tool.isEmpty(psdJsonId) && !this.admin) {
        const res = await planarjsonget({ id: psdJsonId })
        const postList = JSON.parse(res.data.psdJson)
        this.setPostInfoList(postList)
      } else {
        // 搜索模板json数据
        const postList = []
        for (let index = 0; index < jsonInfoList.length; index++) {
          const res = await planarjsonget({ id: jsonInfoList[index].psdJsonId })
          if (res.data.psdJson != null) {
            postList.push(JSON.parse(res.data.psdJson))
          }
        }
        modelRes.data.psdJsonId = String(modelRes.data.psdJsonId)
        this.setPostInfoList(postList)
        // 新建新json串
        const JsonRes = await saveBatch([{ psdJson: JSON.stringify(postList) }])
        this.modelItem.psdJsonId = JsonRes.data[0]
      }
      this.initSaveInfo()
      const water = await this.waterInfo(this.saveInfo)
      this.setWater(water)
      this.save(false)
      const scaleWidth = Math.floor(720 / this.postInfo.canvas.width * 10) * 10
      const scaleHeight = Math.floor(800 / this.postInfo.canvas.height * 10) * 10
      let scale = scaleWidth > scaleHeight ? scaleHeight : scaleWidth // 算初始化缩放，以致能够全部展示
      if (scale > 400) {
        scale = 400
      }
      if (scale < 5) {
        scale = 5
      }
      this.setScale(scale)
      this.setEditModule(this.postInfo.background)
      this.loadFont()
      this.pushHistoryRecord()
      this.$bus.$emit('showbleedingline')
      document.addEventListener('paste', (e) => {
        // var e = event || window.event;
        e.preventDefault()
        e = e.originalEvent || e
        var text = e.clipboardData ? e.clipboardData.getData('text/plain') : prompt('请在这里粘贴文本', '')
        if (e.clipboardData) { // 支持直接 clipboardData，则直接 OK
          document.execCommand('insertText', false, text)
        } else { // 不支持则取用户在 prompt 提示输入框粘贴的内容（自动去格式的）
          var sel, textRange
          try {
            document.execCommand('ms-beginUndoUnit', false, null) // 开始给 IE 增加撤回功能
          } catch (e) { }
          if (document.selection) { // IE9以下支持：document.selection
            textRange = document.selection.createRange()
            textRange.text = text
            textRange.collapse(false) // 取消选择
            textRange.select() // 重新选择
          } else if (window.getSelection) { // IE9以上、Firefox、Safari、Chrome和Opera支持：window.getSelection()
            sel = window.getSelection()
            var range = sel.getRangeAt(0)
            range.deleteContents() // 删除 range 的内容
            var textNode = document.createTextNode(text)
            range.insertNode(textNode) // 向 range 插入 text 节点
            range.selectNodeContents(textNode) // 选中 text 节点
            sel.removeAllRanges() // 删除选区中所有的 range
            sel.addRange(range) // 向选区增加刚才处理后的 range（只含 text 节点）
          }

          try {
            document.execCommand('ms-endUndoUnit', false, null) // 结束给 IE 增加撤回功能
          } catch (e) { }
        }
      })
    },
    /**
     * 初始化保存用的数据
     */
    async initSaveInfo () {
      const model = _.cloneDeep(this.modelItem)
      if (model.tags) {
        model.tags = model.tags.map(item => item.id)
      } else {
        model.tags = []
      }
      if (model.classifications) {
        model.classifications = model.classifications.map(item => item.id)
      } else {
        model.classifications = []
      }
      if (model.styleAttrs) {
        model.styleAttrs = model.styleAttrs.map(item => item.id)
      } else {
        model.styleAttrs = []
      }
      if (model.colorAttrs) {
        model.colorAttrs = model.colorAttrs.map(item => item.id)
      } else {
        model.colorAttrs = []
      }
      this.saveInfo = model
    },
    savePost () {

    },
    // 放大
    enlarge () {
      if (this.scale >= 400) {
        return
      }
      if (this.scale >= 5 && this.scale < 100) {
        this.setScale(this.scale + 5)
      } else if (this.scale >= 100 && this.scale < 200) {
        this.setScale(this.scale + 25)
      } else if (this.scale >= 200 && this.scale < 400) {
        this.setScale(this.scale + 50)
      }
      this.setScrollPosition()
    },
    // 设置滚动事件
    setScrollPosition () {
      this.$refs.canvasContent.scrollLeft = (this.canvas.width * this.scale / 100 - this.editSize.editWidth) / 2
      this.$refs.canvasContent.scrollTop = (this.canvas.height * this.scale / 100 - this.editSize.editHeight) / 2
    },
    // 缩小
    narrow () {
      if (this.scale <= 5) {
        return
      }
      if (this.scale >= 5 && this.scale <= 100) {
        this.setScale(this.scale - 5)
      } else if (this.scale > 100 && this.scale <= 200) {
        this.setScale(this.scale - 25)
      } else if (this.scale > 200 && this.scale <= 400) {
        this.setScale(this.scale - 50)
      }
      this.setScrollPosition()
    },
    // 左侧菜单切换
    menusChange (name) {
      this.activeSideBar = name
      if (name === 'backgroundList') {
        this.setEditModule(this.postInfo.background)
      }
      this.showEditItem = true
    },
    // 添加图片
    pushImage (imgInfo) {
      if (this.editModule.type === 'image') {
        const rateX = this.editModule.width / imgInfo.width
        const rateY = this.editModule.height / imgInfo.height
        const useRate = rateX > rateY ? rateX : rateY
        const useImgInfo = model.createImage({
          src: imgInfo.src,
          height: useRate * imgInfo.height,
          width: useRate * imgInfo.width
        })
        this.editModule.picRate = useImgInfo.showInfo.showWidth / useImgInfo.showInfo.showHeight
        this.editModule.showInfo = useImgInfo.showInfo
        this.editModule.src = imgInfo.src
      } else {
        this.addImage(imgInfo)
        this.setEditModule(imgInfo)
        // this.pushLayers()
      }
      this.pushHistoryRecord()
    },
    // 添加文本
    pushText (textInfo) {
      this.addText(textInfo)
      this.setEditModule(textInfo)
      this.pushHistoryRecord()
    }
  },
  watch: {
    showEditItem (newV, oldV) {
      this.editPanelAnime = true
      if (newV) {
        this.setEditSize({
          width: this.$refs.editorContent.clientWidth - 350,
          height: this.$refs.editorContent.clientHeight
        })
        this.editPosition.left = 420
      } else {
        this.setEditSize({
          width: this.$refs.editorContent.clientWidth + 350,
          height: this.$refs.editorContent.clientHeight
        })
        this.editPosition.left = 72
      }
      this.closeAnime()
    }
  }
}
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.origin-pic {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.print-content {
  max-height: 600px;
  overflow-y: scroll;
}
@media screen and (max-height: 800px) {
  .print-content {
    max-height: 500px;
    overflow-y: scroll;
  }
}

.posters-editTwo {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .content {
    width: 100%;
    top: 64px;
    bottom: 0;
    left: 0;
    position: absolute;
    overflow-y: scroll;
    // display: flex;
  }
  // 左侧导航
  .menus {
    background-color: #353944;
    width: 72px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    .menus-item {
      width: 72px;
      height: 72px;
      text-align: center;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7c818b;
      cursor: pointer;
    }
    .active {
      background-color: #1d1e23;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  .editor-show {
    width: 350px;
    background-color: #1d1e23;
    position: absolute;
    top: 0;
    left: 72px;
    bottom: 0;
    z-index: 99;
    overflow-y: scroll;
    overflow-x: hidden;
    .hide-icon {
      cursor: pointer;
      color: #ffffff;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 99;
      height: 50px;
      background-color: #ffffff3d;
      line-height: 50px;
      border-radius: 5px 0px 0px 5px;
    }
  }
  // 编辑内容
  .editor-content {
    // width: 1450px;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 390px;
    transition: 0.5s;
    background-color: #f4f4fb;
    .InputNumber {
      overflow: hidden;
      position: absolute;
      width: 158px;
      height: 38px;
      background: #2b2c2f;
      border-radius: 19px;
      left: 50%;
      bottom: 20px;
      transform: translateX(-50%);
      z-index: 999;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: white;
    }
    .canvas-content {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: scroll;
    }
  }
  // 右侧预览
  .editor-preview {
    width: 398px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    background: #1d1e23;
    padding-left: 30px;
    .lock-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(252, 252, 252, 0.24);
      z-index: 2000;
    }
  }
}

.shortcut-key {
  position: absolute;
  bottom: 0;
  .icon-size {
    font-size: 30px;
  }
}
</style>
