var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v("文本")]),
    _c("div", { staticClass: "font-adjust", attrs: { align: "left" } }, [
      _c("div", { staticClass: "font-size-input" }, [_c("text-select")], 1),
      _c(
        "div",
        { staticClass: "font-size-input" },
        [
          _c("input-number", {
            attrs: { border: true, min: 6, tip: "px" },
            on: { change: _vm.sizeChange },
            model: {
              value: _vm.editModule.fontSize,
              callback: function($$v) {
                _vm.$set(_vm.editModule, "fontSize", $$v)
              },
              expression: "editModule.fontSize"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "font-statu" }, [
        _c(
          "div",
          {
            class: [_vm.editModule.bold ? "active" : "", "item"],
            on: {
              click: function($event) {
                _vm.editModule.bold = !_vm.editModule.bold
              }
            }
          },
          [_c("i", { staticClass: "icon iconfont icon-bold" })]
        ),
        _c(
          "div",
          {
            class: [_vm.editModule.italic ? "active" : "", "item"],
            on: {
              click: function($event) {
                _vm.editModule.italic = !_vm.editModule.italic
              }
            }
          },
          [_c("i", { staticClass: "icon iconfont icon-zitixieti" })]
        ),
        _c(
          "div",
          {
            staticClass: "item",
            class: [_vm.editModule.underline ? "active" : "", "item"],
            on: {
              click: function($event) {
                _vm.editModule.underline = !_vm.editModule.underline
              }
            }
          },
          [_c("i", { staticClass: "icon iconfont icon-zitixiahuaxian" })]
        )
      ]),
      _c("div", { staticClass: "color-picker" }, [
        _c("div", {
          staticClass: "showColor",
          style: { background: _vm.editModule.color },
          on: {
            click: function($event) {
              return _vm.$refs.colorPicker.handleTrigger()
            }
          }
        }),
        _c(
          "div",
          { staticClass: "color-button" },
          [
            _c("color-picker", {
              ref: "colorPicker",
              attrs: {
                "show-alpha": "",
                size: "mini",
                predefine: _vm.predefineColors
              },
              on: { change: _vm.debouncePushHistoryRecord },
              model: {
                value: _vm.editModule.color,
                callback: function($$v) {
                  _vm.$set(_vm.editModule, "color", $$v)
                },
                expression: "editModule.color"
              }
            })
          ],
          1
        ),
        _vm._m(0)
      ])
    ]),
    _c("div", { staticClass: "transparency" }, [
      _c("div", { staticClass: "tips" }, [_vm._v("不透明度")]),
      _c(
        "div",
        { staticClass: "slider" },
        [
          _c("slider", {
            on: { change: _vm.debouncePushHistoryRecord },
            model: {
              value: _vm.editModule.opacity,
              callback: function($$v) {
                _vm.$set(_vm.editModule, "opacity", $$v)
              },
              expression: "editModule.opacity"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("input-number", {
            attrs: { min: 0, max: 100 },
            on: { change: _vm.debouncePushHistoryRecord },
            model: {
              value: _vm.editModule.opacity,
              callback: function($$v) {
                _vm.$set(_vm.editModule, "opacity", $$v)
              },
              expression: "editModule.opacity"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "alignment" }, [
      _c("div", { staticClass: "title" }, [_vm._v("对齐")]),
      _c("div", { staticClass: "contentlist" }, [
        _c(
          "div",
          {
            class: [_vm.editModule.align == "left" ? "active" : "", "item"],
            on: {
              click: function($event) {
                return _vm.changeAlign("left")
              }
            }
          },
          [_c("i", { staticClass: "icon iconfont icon-zuoduiqi" })]
        ),
        _c(
          "div",
          {
            class: [_vm.editModule.align == "center" ? "active" : "", "item"],
            on: {
              click: function($event) {
                return _vm.changeAlign("center")
              }
            }
          },
          [_c("i", { staticClass: "icon iconfont icon-juzhongduiqi" })]
        ),
        _c(
          "div",
          {
            class: [_vm.editModule.align == "right" ? "active" : "", "item"],
            on: {
              click: function($event) {
                return _vm.changeAlign("right")
              }
            }
          },
          [_c("i", { staticClass: "icon iconfont icon-youduiqi" })]
        )
      ])
    ]),
    _c("div", { staticClass: "spacing" }, [
      _c("div", { staticClass: "tips" }, [_vm._v("行间距")]),
      _c(
        "div",
        { staticClass: "slider" },
        [
          _c("slider", {
            attrs: { min: 10, "show-tooltip": false },
            on: { change: _vm.sizeChange },
            model: {
              value: _vm.lineHeight,
              callback: function($$v) {
                _vm.lineHeight = $$v
              },
              expression: "lineHeight"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm.editModule.lineHeight) + "倍")
      ])
    ]),
    _c("div", { staticClass: "transparency" }, [
      _c("div", { staticClass: "tips" }, [_vm._v("字间距")]),
      _c(
        "div",
        { staticClass: "slider" },
        [
          _c("slider", {
            attrs: { max: 1000, "show-tooltip": false },
            on: { change: _vm.sizeChange },
            model: {
              value: _vm.editModule.letterSpacing,
              callback: function($$v) {
                _vm.$set(_vm.editModule, "letterSpacing", $$v)
              },
              expression: "editModule.letterSpacing"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm._f("integer")(_vm.editModule.letterSpacing)) + "px")
      ])
    ]),
    _c("div", { staticClass: "position" }, [
      _c("div", { staticClass: "title" }, [_vm._v("位置尺寸")]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "tips" }, [_vm._v("位置")]),
        _c("div", { staticClass: "input" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("input-number", {
                attrs: { division: true, tip: "X" },
                on: { change: _vm.sizeChange },
                model: {
                  value: _vm.editModuleLeft,
                  callback: function($$v) {
                    _vm.editModuleLeft = $$v
                  },
                  expression: "editModuleLeft"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "connect" }),
        _c("div", { staticClass: "input" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("input-number", {
                attrs: { division: true, tip: "Y" },
                on: { change: _vm.sizeChange },
                model: {
                  value: _vm.editModuleTop,
                  callback: function($$v) {
                    _vm.editModuleTop = $$v
                  },
                  expression: "editModuleTop"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "tips" }, [_vm._v("尺寸")]),
        _c("div", { staticClass: "input" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("input-number", {
                attrs: {
                  min: _vm.editModule.fontSize,
                  division: true,
                  tip: "px"
                },
                on: { change: _vm.sizeChange },
                model: {
                  value: _vm.editModuleWidth,
                  callback: function($$v) {
                    _vm.editModuleWidth = $$v
                  },
                  expression: "editModuleWidth"
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tips" }, [
      _c("img", { attrs: { src: require("@/assets/img/colorslide.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }