<template>
  <div class="code" @dragstart="drag" @dragend="dragEnd" @click="selectCode">
    <div ref="qrcode"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { mapMutations, mapState } from 'vuex'
import model from '@/lib/model'
export default {
  props: {
    colorDark: {
      type: String,
      default: '#333333'
    }
  },
  data () {
    return {}
  },
  mounted () {
    // eslint-disable-next-line no-new
    new QRCode(this.$refs.qrcode, {
      text: `${this.$url}`,
      width: 130,
      height: 130,
      colorDark: this.colorDark, // 二维码颜色
      colorLight: '#ffffff', // 二维码背景色
      correctLevel: QRCode.CorrectLevel.L// 容错率，L/M/H
    })
    //  console.log(mapMutations(['addCode']))
  },
  computed: {
    ...mapState('edit', ['scale'])
  },
  methods: {
    drag () {
      this.setDragEvent(true)
      const size = model.getAddSize(100, 100)
      event.dataTransfer.setData('code', JSON.stringify({ width: size.width, height: size.height, url: `${this.$url}`, colorDark: this.colorDark }))
    },
    dragEnd () {
      this.setDragEvent(false)
    },
    selectCode () {
      const size = model.getAddSize(100, 100)
      const param = model.createCode({ width: size.width, height: size.height, url: `${this.$url}`, colorDark: this.colorDark })
      this.addCode(param)
      this.setEditModule(param)
      this.pushHistoryRecord()
      // this.pushLayers()
    },

    ...mapMutations('edit', ['addCode', 'setEditModule', 'pushHistoryRecord', 'pushLayers', 'setDragEvent'])
  }
}
</script>

<style lang="scss" scoped>
.code {
  width: 150px;
  height: 150px;
  float: left;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
}
</style>
